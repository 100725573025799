import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cn from 'classnames';
import { useMemo } from 'react';

import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './Dropdown.module.scss';
import type { DropdownProps } from './Dropdown.props';

export const Content = ({
  className,
  align = 'end',
  sideOffset = 8,
  ...props
}: DropdownMenu.MenuContentProps) => {
  const cssVars = useMemo(() => scopedCssVars({ align }), [align]);

  const classNames = cn(className, styles.Content, cssVars);

  const args = { ...props, align, className: classNames, sideOffset };

  return <DropdownMenu.Content {...args} />;
};

export const Dropdown = ({
  // Trigger props
  children,
  asChild,
  className,

  // Root props
  items,
  dir,
  open,
  defaultOpen,
  onOpenChange,
  modal,

  // Content props
  ...props
}: DropdownProps) => {
  if (!items) {
    return <>{children}</>;
  }

  return (
    <DropdownMenu.Root {...{ dir, open, modal, defaultOpen, onOpenChange }}>
      <DropdownMenu.Trigger asChild={asChild} className={className}>
        {children}
      </DropdownMenu.Trigger>

      <Content {...props}>
        {items.map(({ label, ...args }, idx) => (
          <DropdownMenu.Item key={idx} {...args} className={styles.Item}>
            {label}
          </DropdownMenu.Item>
        ))}
      </Content>
    </DropdownMenu.Root>
  );
};
