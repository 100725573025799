import { InputField, InputSelect } from '@Components/ui';
import type { ConfigurationOption } from '@Services/Solution';

interface SolutionConfiguratorSelectOptionProps {
  option: ConfigurationOption;
  onChange: (optionId: string) => void;
  isLoading?: boolean;
}

export const SolutionConfiguratorSelectOption = ({
  option,
  onChange,
  isLoading
}: SolutionConfiguratorSelectOptionProps) => {
  const value = option.options?.find((_option) => _option.selected)?.id;

  return (
    <InputField label={option.name} key={option.id}>
      <InputSelect
        value={value}
        isLoading={isLoading}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        defaultValue={value}
        hasPlaceholder={false}
        options={
          option.options?.map((_option) => {
            return {
              value: _option.id,
              label: _option.text
            };
          }) ?? []
        }
      />
    </InputField>
  );
};
