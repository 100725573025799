import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import * as Checkbox from '@radix-ui/react-checkbox';
import cn from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef, useCallback } from 'react';

import { Icon, Text } from '@Components/ui';

import styles from './InputSingleCheckbox.module.scss';
import type { InputSingleCheckboxProps } from './InputSingleCheckbox.props';

const InputSingleCheckboxComponent = (
  {
    className,
    isConfigurator,
    isError,
    isLoading,
    label,
    id,
    value = false,
    onChange,
    option = { value: true, label: 'Yes' },
    name,
    ...props
  }: InputSingleCheckboxProps,
  ref: ForwardedRef<HTMLButtonElement>
) => {
  const classNames = cn(styles.InputSingleCheckbox, className, {
    [styles.isError]: isError,
    [styles.isLoading]: isLoading
  });

  const handleChange = useCallback(
    (checked: boolean) => (isChecked: boolean) => {
      if (onChange) {
        onChange(checked && isChecked);
      }

      return;
    },
    [onChange]
  );

  return (
    <div className={classNames} {...props}>
      <div className={styles.Option} key={name}>
        <Checkbox.Root
          id={`${id}-${name}`}
          data-testid={props['data-testid'] && `${props['data-testid']}-${option.value}`}
          className={cn(styles.Checkbox, isConfigurator && styles.isConfigurator)}
          ref={ref}
          checked={value}
          onCheckedChange={handleChange(option.value)}
        >
          <Checkbox.Indicator>
            <Icon icon={faCheck} className={styles.isConfigurator} />
          </Checkbox.Indicator>
        </Checkbox.Root>

        <Text.Normal as="label" htmlFor={`${id}-${name}`} className={styles.Label}>
          {option.label}
        </Text.Normal>
      </div>
    </div>
  );
};

export const InputSingleCheckbox = forwardRef(InputSingleCheckboxComponent);
