import { InputField, InputRadio } from '@Components/ui';
import type { ConfigurationOption } from '@Services/Solution';

interface SolutionConfiguratorRadioOptionProps {
  option: ConfigurationOption;
  onChange: (optionId: string) => void;
  isLoading?: boolean;
}

export const SolutionConfiguratorRadioOption = ({
  option,
  onChange,
  isLoading
}: SolutionConfiguratorRadioOptionProps) => {
  return (
    <InputField label={option.name} key={option.id}>
      <InputRadio
        isLoading={isLoading}
        disabled={isLoading}
        value={option.options?.find((_option) => _option.selected)?.id}
        onValueChange={(value) => {
          onChange(value);
        }}
        options={
          option.options?.map((_option) => {
            return {
              value: _option.id,
              label: _option.text
            };
          }) ?? []
        }
      />
    </InputField>
  );
};
