import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as AccessibleIcon from '@radix-ui/react-accessible-icon';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './Icon.module.scss';
import type { IconProps } from './Icon.props';
import { labelFromIconProp } from './internal';

export const Icon = ({
  className,
  accessibilityLabel,
  color,
  backgroundColor,
  size,
  hasSquare,
  hasCircle,
  isCentered,
  icon,
  ...props
}: IconProps) => {
  const { t } = useTranslation();

  const iconVars = scopedCssVars({
    iconSize: !!size && `${size}px`,
    iconColor: !!color && `var(--color-${color})`,
    iconBackgroundColor: !!backgroundColor && `var(--color-${backgroundColor})`
  });

  const classNames = cn(
    styles.Icon,
    iconVars,
    {
      [styles.isCentered]: isCentered,
      [styles.hasBackground]: hasCircle || hasSquare,
      [styles.hasCircle]: hasCircle,
      [styles.hasSquare]: hasSquare
    },
    className
  );

  const suffix = t('COMMON.GLOBALS.ACCESSIBILITY_LABEL_SUFFIX');

  const label = labelFromIconProp(accessibilityLabel, icon, suffix);

  return (
    <AccessibleIcon.Root label={label}>
      <div className={classNames}>
        <FontAwesomeIcon icon={icon} {...props} />
      </div>
    </AccessibleIcon.Root>
  );
};
