import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { SolutionConfigurator } from '@Components/container';
import { Modal } from '@Components/layout';
import { HtmlRenderer } from '@Components/layout/HtmlRenderer/HtmlRenderer';
import { Button } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';
import { useRoomDeleteSolution } from '@Hooks/Rooms/useRoomDeleteSolution';
import { useSolutionChangeQuantity } from '@Hooks/Solution/useSolutionChangeQuantity';
import { useSolutionConfigure } from '@Hooks/Solution/useSolutionConfigure';
import { useSolutionOverrideQuantity } from '@Hooks/Solution/useSolutionOverrideQuantity';
import type { SolutionChangeParams } from '@Services/Solution';

import styles from './AdvisorAddEditSolution.module.scss';
import type { AdvisorAddEditSolutionProps } from './AdvisorAddEditSolution.props';

export const AdvisorAddEditSolution = ({
  projectId,
  roomId,
  solution,
  onBack,
  onSolutionChange,
  isEdit
}: AdvisorAddEditSolutionProps) => {
  const deleteSolutionMutation = useRoomDeleteSolution();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ROOM.SOLUTIONS.ADD_MODAL'
  });
  const appRoutes = useAppRoutes();

  const [quantityError, setQuantityError] = useState(false);

  const configureSolutionMutation = useSolutionConfigure();
  const solutionChangeQuantityMutation = useSolutionChangeQuantity();
  const solutionOverrideQuantityMutation = useSolutionOverrideQuantity();

  const handleChange = async ({
    imageId,
    optionId,
    type,
    value,
    selected
  }: SolutionChangeParams) => {
    const updatedSolution = await configureSolutionMutation.mutateAsync({
      id: solution.configurationId,
      solutionId: solution.id,
      option: {
        id: optionId,
        value,
        imageId,
        selected
      },
      type
    });

    if (!updatedSolution) {
      return;
    }

    onSolutionChange?.(updatedSolution);
  };

  const handleQuantityChange = async (quantity: number) => {
    const data = await solutionChangeQuantityMutation.mutateAsync({
      solutionId: solution.id,
      quantity
    });

    onSolutionChange?.(data);
  };

  const handleCustomQuantityChange = async (override: string) => {
    const data = await solutionOverrideQuantityMutation.mutateAsync({
      solutionId: solution.id,
      override: !!override
    });

    onSolutionChange?.(data);
  };

  const handleDone = () => {
    navigate(appRoutes.getAdvisorProjectDetail(projectId).to);
  };

  const handleDelete = () => {
    deleteSolutionMutation.mutate(
      { solutionId: solution.id, id: roomId },
      {
        onSuccess: () => {
          onBack();
        }
      }
    );
  };

  const handleClose = () => {
    if (isEdit) {
      handleDone();
    } else {
      handleDelete();
    }
  };

  const isLoading =
    configureSolutionMutation.isLoading ||
    deleteSolutionMutation.isLoading ||
    solutionChangeQuantityMutation.isLoading;

  return (
    <Modal
      title={solution.product?.name}
      open
      onOpenChange={handleClose}
      contentClassName={cn(styles.ModalContent, {
        [styles.isLoading]: deleteSolutionMutation.isLoading
      })}
      className={styles.AdvisorAddEditSolution}
    >
      <HtmlRenderer className={styles.Introduction} content={solution.product.description} />

      <SolutionConfigurator
        className={styles.Configurator}
        onQuantityChange={handleQuantityChange}
        onOverrideQuantityChange={handleCustomQuantityChange}
        solution={solution}
        onChange={handleChange}
        isLoading={isLoading}
        setQuantityError={setQuantityError}
      />

      <div className={styles.Actions}>
        {!isEdit && (
          <Button isLoading={isLoading} onClick={handleDelete} color="red">
            {t('DELETE')}
          </Button>
        )}

        <Button isLoading={isLoading} color="primary" onClick={handleDone} disabled={quantityError}>
          {t('DONE')}
        </Button>
      </div>
    </Modal>
  );
};
