import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Modal } from '@Components/layout';
import { Button, SpacingGroup } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';

export const AuthUpdatePasswordComplete = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.RECOVERY.RESET.SUCCESS'
  });
  const appRoutes = useAppRoutes();

  const navigate = useNavigate();

  const handleSubmit = () => {
    navigate(appRoutes.getLogin().to);
  };

  return (
    <Modal isClosable={false} onSubmit={handleSubmit} title={t('TITLE')}>
      <SpacingGroup spacing={2}>
        <Button to={appRoutes.getLogin().to}>{t('BUTTON')}</Button>
      </SpacingGroup>
    </Modal>
  );
};
