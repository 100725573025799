import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import * as AspectRatio from '@radix-ui/react-aspect-ratio';
import cn from 'classnames';

import { Icon, Link, Rounded } from '@Components/ui';

import styles from './Video.module.scss';
import type { VideoProps } from './Video.props';

export const Video = ({
  className,
  children,
  thumbnailSrc,
  ratio = 16 / 9,
  ...props
}: VideoProps) => {
  const classNames = cn(styles.Video, className);

  return (
    <Link className={classNames} {...props}>
      <AspectRatio.Root ratio={ratio}>
        <Rounded className={styles.Wrapper}>
          <div className={styles.Overlay}>
            <Icon icon={faPlay} hasCircle backgroundColor="gray-light" color="secondary" />
          </div>

          <img src={thumbnailSrc} className={styles.Thumbnail} />
        </Rounded>
      </AspectRatio.Root>
    </Link>
  );
};
