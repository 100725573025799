import type { ForwardedRef } from 'react';
import { forwardRef, useCallback, useMemo, useState } from 'react';
import ReactSelect from 'react-select';

import type { InputOption } from '@Constants/interfaces';

import type { InputMultiSelectProps } from './InputMultiSelect.props';

export const InputMultiSelectComponent = (
  { isMulti, onChange, value, placeholder, className, ...props }: InputMultiSelectProps,
  ref: ForwardedRef<any>
) => {
  const [options, setOptions] = useState<InputOption[]>(props.options || []);

  const handleChange = useCallback(
    (val: InputOption | InputOption[]) => {
      if (Array.isArray(val)) {
        setOptions(val);
      }

      if (onChange) {
        if (Array.isArray(val)) {
          if (isMulti) {
            onChange(val.map((a) => a?.value));
          }
        } else {
          if (!isMulti) {
            onChange(val?.value ?? undefined);
          } else {
            onChange([]);
          }
        }
      }
    },
    [isMulti, onChange]
  );

  const formattedValue = useMemo(() => {
    if (Array.isArray(value)) {
      return value.map((v) => options.find((x) => x.value === v));
    } else {
      return options.find((x) => x.value === value);
    }
  }, [value, options]);

  return (
    <label className={className}>
      <ReactSelect
        isMulti={isMulti}
        value={formattedValue}
        isClearable
        menuShouldScrollIntoView
        onChange={handleChange as any}
        ref={ref}
        {...props}
      />
    </label>
  );
};

export const InputMultiSelect = forwardRef(InputMultiSelectComponent);
