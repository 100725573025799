import { Root, TabsList } from '@radix-ui/react-tabs';
import cn from 'classnames';
import type { JSXElementConstructor, ReactElement } from 'react';
import { Children, isValidElement } from 'react';

import type { ItemProps } from './internal/components';
import { Item } from './internal/components';
import { Button } from './internal/components/Button/Button';
import styles from './Tabs.module.scss';
import type { TabsProps } from './Tabs.props';

const TabsComponent = ({ className, children, ...props }: TabsProps) => {
  const classNames = cn(styles.Tabs, className);

  const tabList = Children.toArray(children).filter((child) =>
    isValidElement(child)
  ) as ReactElement<ItemProps, string | JSXElementConstructor<any>>[];

  return (
    <Root className={classNames} defaultValue={tabList?.[0].props.value} {...props}>
      <TabsList className={styles.List}>
        {tabList.map(({ props: properties, key }) => (
          <Button key={key} value={properties.value}>
            {properties.label}
          </Button>
        ))}
      </TabsList>

      {children}
    </Root>
  );
};

export const Tabs = Object.assign(TabsComponent, { Item });
