import type { FocusEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField, InputTextArea, Rounded, Text } from '@Components/ui';
import { useAuthGetMe } from '@Hooks/Auth';
import { useProjectUpdateNotes } from '@Hooks/Project';
import { isAdvisor } from '@Utils/AuthUtils';

import styles from './Notes.module.scss';
import type { NotesProps } from './Notes.props';

export const Notes = ({ project }: NotesProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'COMPONENTS.NOTES' });
  const { mutateAsync, isLoading } = useProjectUpdateNotes();
  const { data: currentAdvisor } = useAuthGetMe();

  const isEditable = isAdvisor() && currentAdvisor && currentAdvisor?.id === project.advisor.id;

  const handleChange = async ({ target }: FocusEvent<HTMLTextAreaElement>) => {
    if (target.value === project.notes) return;

    await mutateAsync(target.value);
  };

  if (!isEditable && !project.notes) return null;

  return (
    <Rounded className={styles.Notes}>
      <InputField label={t('LABEL')} name="notes">
        {isEditable ? (
          <InputTextArea
            defaultValue={project.notes ?? ''}
            disabled={isLoading}
            onBlur={handleChange}
          />
        ) : (
          <Text.Paragraph>{project.notes}</Text.Paragraph>
        )}
      </InputField>
    </Rounded>
  );
};
