import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { UpdateProjectQuotationFormValues } from '@Components/form';
import { UpdateProjectQuotationForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { useProjectGetOne, useProjectUpdate } from '@Hooks/Project';

export const AdvisorProjectQuotationScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.QUOTATION'
  });
  const appRoutes = useAppRoutes();
  const { projectId } = useParams();
  const { data: project } = useProjectGetOne(projectId);

  const navigate = useNavigate();
  const { mutateAsync: updateQuotation } = useProjectUpdate();

  const handleClose = () => {
    navigate(appRoutes.getAdvisorProjectDetail(projectId).to);
  };

  if (!project) return null;

  const handleSubmit = async (data: UpdateProjectQuotationFormValues) => {
    try {
      const { name } = await updateQuotation(data);

      toast.success(t('SUCCESS_MESSAGE', { name }));

      handleClose();
    } catch (error) {
      toast.error(t('ERROR_MESSAGE'));
    }
  };

  return (
    <Modal
      title={appRoutes.getAdvisorProjectQuotation().label}
      open
      onOpenChange={(open) => !open && handleClose()}
    >
      <UpdateProjectQuotationForm initialValues={project} onSubmit={handleSubmit} />
    </Modal>
  );
};
