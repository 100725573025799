import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UpdateCustomerForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { useCustomerGetCurrent, useCustomerUpdate } from '@Hooks/Customer';
import type { CustomerUpdateRequest } from '@Services/Customer';

export const AdvisorUpdateCustomerScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.CUSTOMER.FORM.CREATE_UPDATE_CUSTOMER'
  });
  const appRoutes = useAppRoutes();
  const handleClose = useNavigate();
  const { mutateAsync: editCustomer } = useCustomerUpdate();
  const { customerId } = useParams();
  const { data: currentCustomer } = useCustomerGetCurrent();

  const handleSubmit = useCallback(
    async (data: CustomerUpdateRequest) => {
      try {
        const { name } = await editCustomer({ ...data, id: currentCustomer?.id ?? '' });

        toast.success(t('SUCCESS_UPDATE_MESSAGE', { name }), {
          onClick: () =>
            handleClose(appRoutes.getAdvisorCustomerDetail(currentCustomer?.publicId).to)
        });

        handleClose(appRoutes.getAdvisorCustomerDetail(currentCustomer?.publicId).to);
      } catch (error) {
        toast.error(t('ERROR_MESSAGE'));
      }
    },
    [appRoutes, t, currentCustomer, editCustomer, handleClose]
  );

  return (
    <Modal
      title={appRoutes.getAdvisorCustomerUpdate().label}
      open
      onOpenChange={(open) =>
        !open && handleClose(appRoutes.getAdvisorCustomerDetail(customerId).to)
      }
    >
      <UpdateCustomerForm initialValues={currentCustomer} onSubmit={handleSubmit} />
    </Modal>
  );
};
