import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { USPList } from '@Components/container';
import { Button, Rounded, SpacingGroup, Text } from '@Components/ui';

import styles from './ProjectUspsWhitepaperCard.module.scss';
import type { ProjectUspsWhitepaperCardProps } from './ProjectUspsWhitepaperCard.props';

export const ProjectUspsWhitepaperCard = ({
  project,
  className
}: ProjectUspsWhitepaperCardProps) => {
  const classNames = cn(styles.ProjectUspsWhitepaperCard, className);
  const { t } = useTranslation();

  if (!(project.uniqueSellingPoints?.length > 0) && !project.whitePaper) {
    return <></>;
  }

  return (
    <Rounded className={classNames}>
      <SpacingGroup isVertical>
        <Text.H3 color="primary">{t('COMPONENTS.USPWHITEPAPERCARD.TITLE')}</Text.H3>

        {project.uniqueSellingPoints && <USPList usps={project.uniqueSellingPoints} />}

        {project.whitePaper && (
          <Button
            download
            target="_blank"
            href={project.whitePaper.path}
            color="secondary"
            isOutline
          >
            {t('COMPONENTS.USPWHITEPAPERCARD.DOWNLOAD_WHITEPAPER')}
          </Button>
        )}
      </SpacingGroup>
    </Rounded>
  );
};
