export enum StorageKey {
  ACCESS_TOKEN = 'REACT_APP_ACCESS_TOKEN'
}

export enum SortDirection {
  ASCENDING = 'ASC',
  DESCENDING = 'DESC',
  INTERMEDIATE = ''
}

export enum ApiErrorCode {}

export enum AppError {
  NO_CUSTOMER_ID_PARAM = 'There is no customer ID present in the URL.',
  NO_AUTH_CONTEXT_SET = 'Set a context provider first.',
  NO_ACCESS_TOKENS_FOUND = 'No access tokens were found in localStorage.'
}

export enum Operator {
  LOWER_THAN = '<',
  GREATER_THAN = '>',
  LOWER_THAN_OR_EQUAL = '<=',
  GREATER_THAN_OR_EQUAL = '>=',
  IS_NOT = '!=',
  IS = '=',
  CONTAINS_NOT = '!in',
  CONTAINS = 'in'
}

export enum Role {
  ADMIN = 'ADMIN',
  ADVISOR = 'ADVISOR',
  DEALER = 'DEALER'
}

export enum Locale {
  NL = 'nl-NL',
  EN = 'en-GB',
  DE = 'de-DE',
  FR = 'fr-FR'
}

export enum LocaleLanguageMap {
  'nl-NL' = 'NL',
  'en-GB' = 'EN',
  'fr-FR' = 'FR',
  'de-DE' = 'DE'
}

export enum SolutionConfigType {
  CHECKBOX = 'Checkbox',
  COLORPICKER = 'ColorPicker',
  DROPDOWN = 'DropDown',
  FILE = 'File',
  NUMBER = 'Number',
  RADIO = 'Radio',
  RADIOCOLOR = 'RadioColor',
  RADIOWITHIMAGE = 'RadioWithImage',
  RADIOWITHIMAGESMALL = 'RadioWithImageSmall',
  TEXTAREA = 'TextArea'
}
