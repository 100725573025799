import cn from 'classnames';

import type { TextElement, TextProps } from '@Components/ui';
import { BaseText } from '@Components/ui';

import styles from './H5.module.scss';

const defaultElement = 'h5' as TextElement;
export const H5 = <T extends TextElement = typeof defaultElement>({
  className,
  as = defaultElement as T,
  ...props
}: TextProps<T>) =>
  BaseText<T>({ className: cn(className, styles.H5), as, ...props } as TextProps<T>);
