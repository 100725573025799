import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { JsonParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { ProductCardRow } from '@Components/container';
import { LazyList, Modal } from '@Components/layout';
import { Form, Text } from '@Components/ui';
import { FacetKey, useAlgoliaGetProducts } from '@Hooks/Algolia/useAlgoliaGetProducts';
import { useAppRoutes, useDebounce } from '@Hooks/Common';
import { useRoomGetOne } from '@Hooks/Rooms';

import styles from './AddProductOverview.module.scss';
import type { AddProductOverviewProps } from './AddProductOverview.props';

export const AddProductOverview = ({ onSelect, isLoading }: AddProductOverviewProps) => {
  const [query, setQuery] = useQueryParams(
    {
      page: withDefault(NumberParam, 0),
      search: StringParam,
      filter: JsonParam
    },
    {
      updateType: 'pushIn',
      removeDefaultsFromUrl: true
    }
  );

  const { t } = useTranslation('translation', { keyPrefix: 'DOMAIN.PRODUCT.OVERVIEW.FILTERS' });
  const appRoutes = useAppRoutes();

  const { projectId, roomId } = useParams();
  const { data: room } = useRoomGetOne(roomId);
  const { debounce } = useDebounce(800);

  const navigate = useNavigate();

  const { data: productsData, ...infiniteQuery } = useAlgoliaGetProducts({
    perPage: 8,
    filters: { roomTypes: room?.roomType.id, ...query.filter },
    search: query.search ?? '',
    facets: [FacetKey.BRAND, FacetKey.TYPE, FacetKey.ROOMTYPES]
  });

  const { data: filtersData } = useAlgoliaGetProducts({
    perPage: 1,
    facets: [FacetKey.BRAND, FacetKey.TYPE]
  });

  const allResults = productsData?.pages
    .map((pageData) => {
      return pageData.hits;
    })
    .flat(1);

  const handleClose = () => {
    navigate(appRoutes.getAdvisorProjectDetail(projectId).to);
  };

  if (!room) return null;

  return (
    <Modal
      title={appRoutes.getAdvisorProjectRoomAddProduct().label}
      open
      isLarge
      onOpenChange={handleClose}
      contentClassName={styles.ModalContent}
      className={styles.AddProductOverview}
    >
      <div className={styles.UpperWrapper}>
        <div className={styles.Filters}>
          <div className={styles.FilterWrapper}>
            <Form
              className={styles.FiltersSelect}
              onChange={(filters) => setQuery((prev) => ({ ...prev, filter: filters }))}
            >
              {Object.entries(filtersData?.pages[0]?.facets ?? {}).map(([groupLabel, filters]) => {
                return (
                  <div className={styles.FilterGroup} key={groupLabel}>
                    <Text.Normal className={styles.Label}>{t(groupLabel as any)}</Text.Normal>

                    <Form.Input.MultiSelect
                      name={groupLabel.split('.')[0]}
                      options={Object.keys(filters).map((optionLabel) => {
                        return {
                          label: optionLabel,
                          value: optionLabel
                        };
                      })}
                    />
                  </div>
                );
              })}
            </Form>

            <Form
              className={styles.FiltersSearch}
              onChange={(data) => {
                debounce(() => setQuery((prev) => ({ ...prev, search: data.search })));
              }}
            >
              <div className={styles.FilterGroup}>
                <Text.Normal className={styles.Label}>{t('SEARCH')}</Text.Normal>

                <Form.Input.Text name="search" />
              </div>
            </Form>
          </div>
        </div>
      </div>

      <div
        className={cn(styles.Products, {
          [styles.isLoading]: isLoading
        })}
      >
        <LazyList {...infiniteQuery}>
          {allResults?.length !== 0 ? (
            <>
              {allResults?.map((product) => {
                return (
                  <ProductCardRow
                    onClick={isLoading ? undefined : () => onSelect(product)}
                    className={styles.Product}
                    product={product}
                    key={product.objectID}
                  />
                );
              })}
            </>
          ) : (
            <Text.H3>{t('NO_RESULTS')}</Text.H3>
          )}
        </LazyList>
      </div>
    </Modal>
  );
};
