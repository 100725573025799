import { Controller } from 'react-hook-form';

import { InputSingleCheckbox } from '@Components/ui';
import { getFieldStateValidation } from '@Utils/FormUtils';

import { FormField } from '../FormField/FormField';
import type { FormSingleCheckboxProps } from './FormSingleCheckbox.props';

export const FormSingleCheckbox = ({ inputProps, className, ...form }: FormSingleCheckboxProps) => {
  return (
    <FormField {...form} className={className} hasController>
      <Controller
        name={form.name}
        render={({ field, fieldState }) => {
          const { isError } = getFieldStateValidation(fieldState);

          return <InputSingleCheckbox isError={isError} {...inputProps} {...field} />;
        }}
      />
    </FormField>
  );
};
