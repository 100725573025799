import cn from 'classnames';

import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './GridList.module.scss';
import type { GridListProps } from './GridList.props';

export const GridList = ({
  className,
  children,
  columnsMobile,
  columnsTablet,
  columnsDesktop,
  ...props
}: GridListProps) => {
  const gridListVars = scopedCssVars({
    columnsMobile,
    columnsTablet,
    columnsDesktop
  });

  const classNames = cn(styles.GridList, gridListVars, className);

  return (
    <section className={classNames} {...props}>
      {children}
    </section>
  );
};
