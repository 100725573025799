import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { CreateProjectRoomFormValues } from '@Components/form';
import { CreateProjectRoomForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { useProjectGetOne } from '@Hooks/Project';
import { useRoomCreate } from '@Hooks/Rooms';

export const AdvisorProjectCreateRoomScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.CREATE_ROOM'
  });

  const { projectId } = useParams();
  const { data: project } = useProjectGetOne(projectId);
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();
  const { mutateAsync: createRoom, isLoading } = useRoomCreate();

  const handleClose = () => {
    navigate(appRoutes.getAdvisorProjectDetail(projectId).to);
  };

  if (!project) return null;

  const handleSubmit = async (data: CreateProjectRoomFormValues) => {
    try {
      const { name } = await createRoom(data);

      toast.success(t('SUCCESS_MESSAGE', { name }));

      handleClose();
    } catch (error) {
      toast.error(t('ERROR_MESSAGE'));
    }
  };

  return (
    <Modal
      title={appRoutes.getAdvisorProjectRoomCreate().label}
      open
      isClosable={!isLoading}
      onOpenChange={(open) => !open && !isLoading && handleClose()}
    >
      <CreateProjectRoomForm
        building={project.building}
        initialValues={{ project }}
        disabled={isLoading}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
