import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { AdvisorAddEditSolution } from '@Components/network';
import { useSolutionGetOne } from '@Hooks/Solution/useSolutionGetOne';
import type { ISolutionConfiguration } from '@Services/Solution';

export const AdvisorProjectRoomEditProductScreen = () => {
  const { roomId, projectId, solutionId } = useParams();
  const [activeSolution, setActiveSolution] = useState<ISolutionConfiguration | null>(null);

  const { data: solution } = useSolutionGetOne(solutionId);

  useEffect(() => {
    if (solution) {
      setActiveSolution(solution);
    }
  }, [solution]);

  if (!activeSolution) return null;

  return (
    <AdvisorAddEditSolution
      onSolutionChange={setActiveSolution}
      solution={activeSolution}
      roomId={roomId as string}
      projectId={projectId as string}
      onBack={() => setActiveSolution(null)}
      isEdit
    />
  );
};
