import { useMutation, useQueryClient } from 'react-query';

import { roomService } from '@Services/Room';
import { type SolutionChangeQuantityRequest, solutionService } from '@Services/Solution';
import { handleUnknownError } from '@Utils/ErrorUtils';

export const useSolutionChangeQuantity = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: SolutionChangeQuantityRequest) => {
      return solutionService.changeQuantity(params);
    },
    {
      onError: handleUnknownError,
      onSuccess: () => {
        queryClient.invalidateQueries([roomService.getEndpoint()]);
      }
    }
  );
};
