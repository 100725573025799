import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

import { Anchor, ButtonIcon, Rounded, SpacingGroup, Text } from '@Components/ui';

import styles from './ContactCardRow.module.scss';
import type { ContactCardRowProps } from './ContactCardRow.props';

export const ContactCardRow = ({
  image,
  name,
  email,
  phone,
  linkedInUrl,
  className,
  children
}: ContactCardRowProps) => {
  const classNames = cn(styles.ContactCardRow, className);

  return (
    <Rounded className={classNames} hasShadow>
      {!!image && <img src={image} className={styles.Image} />}

      <div className={cn(styles.Data, { [styles.DataNoImage]: !image })}>
        {!!name && <Text.H4 className={styles.Title}>{name}</Text.H4>}

        {!!email && (
          <Anchor href={`mailto:${email}`} className={styles.Email}>
            {email}
          </Anchor>
        )}

        {!!phone && (
          <Anchor href={`tel:${phone}`} className={cn(styles.NoUnderline, styles.PhoneNumber)}>
            {phone}
          </Anchor>
        )}

        {children}

        <SpacingGroup className={styles.Icons}>
          {!!linkedInUrl && (
            <ButtonIcon
              icon={faLinkedin}
              href={linkedInUrl}
              color="primary"
              backgroundColor="gray-light"
              size={14}
            />
          )}

          {!!email && (
            <ButtonIcon
              icon={faEnvelope}
              href={`mailto:${email}`}
              color="primary"
              backgroundColor="gray-light"
              size={14}
            />
          )}

          {!!phone && (
            <ButtonIcon
              icon={faPhone}
              href={`tel:${phone}`}
              color="primary"
              backgroundColor="gray-light"
              size={14}
            />
          )}
        </SpacingGroup>
      </div>
    </Rounded>
  );
};
