import { ContactCard } from '@Components/ui';
import { getReadableAddress } from '@Utils/StringUtils';

import type { ProjectContactProps } from './ProjectContact.props';

export const ProjectContact = ({ project }: ProjectContactProps) => {
  const contactDetails = project.customerDetails ?? project.customer;

  return (
    <ContactCard
      title={project.name}
      address={getReadableAddress(contactDetails)}
      contactPerson={
        contactDetails.contactPerson ?? contactDetails.contactPerson ?? contactDetails.name
      }
      phone={contactDetails.phone}
      email={contactDetails.email}
      advisorId={project.advisor.id}
      projectId={project.id}
    />
  );
};
