import { useMutation, useQueryClient } from 'react-query';

import { authService } from '@Services/AuthService';

export const useAuthLogout = () => {
  const queryClient = useQueryClient();

  return useMutation(
    () => {
      return authService.logout();
    },
    {
      onSettled: () => {
        queryClient.invalidateQueries();
        queryClient.clear();
      }
    }
  );
};
