import { decodeJwt } from 'jose';

import { Role } from '@Constants/enums';

import { getAccessTokens } from './StorageUtils';

export const getJwt = () => {
  const tokens = getAccessTokens();

  return tokens && decodeJwt(tokens.accessToken);
};

export const hasRoles = (...roles: Array<keyof typeof Role>) => {
  const jwtRole = getJwt()?.role;

  const jwtRoles = Array.isArray(jwtRole) ? jwtRole : [jwtRole];

  return roles.every((role) => jwtRoles.includes(role));
};

export const isAdvisor = () => {
  return hasRoles(Role.ADVISOR);
};
