import { useQuery } from 'react-query';

import { roomService } from '@Services/Room';

export const useRoomGetOne = (id = '') => {
  return useQuery(
    [roomService.getEndpoint(), id],
    () => {
      return roomService.getOne({ id });
    },
    { enabled: !!id }
  );
};
