import { useMutation, useQueryClient } from 'react-query';

import { projectService } from '@Services/Project';
import type { RoomDeleteRequest } from '@Services/Room';
import { roomService } from '@Services/Room';

export const useRoomDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: RoomDeleteRequest) => {
      return roomService.deleteRoom(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([roomService.getEndpoint()]);
        queryClient.invalidateQueries([projectService.getEndpoint()]);
      }
    }
  );
};
