import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CustomerProjectsArchive } from '@Components/network';
import { Box, Text } from '@Components/ui';

export const CustomerProjectsScreen = () => {
  const { t } = useTranslation();

  return (
    <Box pt={13}>
      <Row>
        <Col xs={12}>
          <Box mb={1.5}>
            <Text.H1>{t('DOMAIN.PROJECT.PLURAL')}</Text.H1>
          </Box>
        </Col>

        <CustomerProjectsArchive />
      </Row>
    </Box>
  );
};
