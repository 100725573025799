import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { RoomCard } from '@Components/container';
import { ButtonIcon, RoomSkeleton, Text } from '@Components/ui';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import { useRoomGetMultiple } from '@Hooks/Rooms';
import { isAdvisor } from '@Utils/AuthUtils';

import styles from './ProjectRooms.module.scss';
import type { ProjectRoomsProps } from './ProjectRooms.props';

export const ProjectRooms = ({ project }: ProjectRoomsProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'DOMAIN.PROJECT' });
  const { data: currentAdvisor } = useAuthGetMe();
  const appRoutes = useAppRoutes();
  const rooms = useRoomGetMultiple(project.rooms?.map(({ id }) => id));

  return (
    <div className={styles.ProjectRooms}>
      <div className={styles.Header}>
        <Text.H2 className={styles.Title}>{t('ROOMS')}</Text.H2>

        {isAdvisor() && currentAdvisor && project.advisor.id === currentAdvisor?.id && (
          <ButtonIcon
            className={styles.Button}
            icon={faPlus}
            size={14}
            backgroundColor="secondary"
            color="white"
            accessibilityLabel={t('ROOMS')}
            to={appRoutes.getAdvisorProjectRoomCreate(project.id).to}
          />
        )}
      </div>

      {rooms.length === 0 ? (
        <Text.Normal>{t('NO_ROOMS')}</Text.Normal>
      ) : (
        rooms.map(({ data: room }, idx) => {
          if (!room) return <RoomSkeleton key={project.rooms?.at(idx)?.id ?? idx} />;

          return (
            <RoomCard
              room={room}
              advisorId={project.advisor.id}
              isDefaultOpened={idx === 0}
              key={room.id}
            />
          );
        })
      )}
    </div>
  );
};
