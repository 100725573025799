import { useMutation, useQueryClient } from 'react-query';

import type { RoomUpdateRequest } from '@Services/Room';
import { roomService } from '@Services/Room';

export const useRoomUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: RoomUpdateRequest) => {
      return roomService.updateRoom(params);
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([roomService.getEndpoint(), id]);
      }
    }
  );
};
