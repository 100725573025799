import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Chart, SpacingGroup, StatisticScore } from '@Components/ui';
import { getTheme } from '@Utils/ThemeUtils';
import { i18n } from '@Utils/TranslationUtils';

import styles from './FrequencyChart.module.scss';
import type { FrequencyChartProps } from './FrequencyChart.props';
import { Scales } from './internal/constants';

export const FrequencyChart = ({ className, room }: FrequencyChartProps) => {
  const classNames = cn(styles.FrequencyChart, className);
  const { t } = useTranslation();
  const { color } = getTheme();

  const percentageFormatter = new Intl.NumberFormat(i18n.language, { style: 'percent' });

  return (
    <section className={classNames}>
      <SpacingGroup isVertical spacing={1.5}>
        <StatisticScore color={color.primary.default} label={t('DOMAIN.ROOM.LIST_ITEMS.CURRENT')}>
          {percentageFormatter.format(room.score.current / 100)}
        </StatisticScore>

        <StatisticScore
          color={color.secondary.default}
          label={t('DOMAIN.ROOM.LIST_ITEMS.EXPECTED')}
        >
          {percentageFormatter.format(room.score.expected / 100)}
        </StatisticScore>
      </SpacingGroup>

      <Chart
        data={{
          datasets: [
            {
              data: room.measurements.current,
              label: t('COMPONENTS.FREQUENCY_CHART.LABELS.CURRENT'),
              borderColor: color.primary.default,
              borderDash: [5, 5],
              borderCapStyle: 'round'
            },
            {
              data: room.measurements.expected,
              label: t('COMPONENTS.FREQUENCY_CHART.LABELS.EXPECTED'),
              borderColor: color.secondary.default,
              borderDash: [5, 5],
              borderCapStyle: 'round'
            },
            {
              data: room.measurements.ideal,
              label: t('COMPONENTS.FREQUENCY_CHART.LABELS.IDEAL'),
              borderColor: color.progress.default
            }
          ]
        }}
        options={{
          scales: Scales,
          plugins: {
            tooltip: {
              intersect: false,
              mode: 'index',
              usePointStyle: true
            }
          }
        }}
      />
    </section>
  );
};
