import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Form, Text } from '@Components/ui';

import { ResetFiltersButton } from './internal/components';
import styles from './ProductFilters.module.scss';
import type { ProductFilterFormValues, ProductFilterProps } from './ProductFilters.props';

export const ProductFilters = ({
  filterGroups,
  onChange,
  initialFilters,
  isOpen,
  children,
  isInline
}: ProductFilterProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PRODUCT.OVERVIEW'
  });

  return (
    <div
      className={cn(styles.Filter, {
        [styles.isOpen]: isOpen,
        [styles.isInline]: isInline
      })}
    >
      <Form<ProductFilterFormValues> onChange={onChange} initialValues={initialFilters}>
        <ResetFiltersButton />

        {children}

        <div className={styles.FilterGroups}>
          {Object.entries(filterGroups).map(([label, filters]) => {
            return (
              <div key={label}>
                <Text.Label className={styles.Label}>{t(`FILTERS.${label}` as any)}</Text.Label>

                <Form.Input.Checkbox
                  name={label.split('.')[0]}
                  inputProps={{
                    options: Object.entries(filters)
                      .filter(([, count]) => Number(count) > 0)
                      .map(([filter, count]) => {
                        return {
                          label: `${filter} (${count})`,
                          value: filter
                        };
                      })
                  }}
                />
              </div>
            );
          })}
        </div>
      </Form>
    </div>
  );
};
