import type { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Anchor, Button, Form, SpacingGroup, Text } from '@Components/ui';
import { ERROR_EMAIL_NOT_CONFIRMED } from '@Constants/configs';
import { useAuthRecovery } from '@Hooks/Auth';
import { useAuthResend } from '@Hooks/Auth/useAuthResend';
import { useAppRoutes } from '@Hooks/Common';

import styles from './AuthRecoveryForm.module.scss';
import type { AuthRecoveryFormProps, AuthRecoveryFormValues } from './AuthRecoveryForm.props';

export const AuthRecoveryForm = ({ className }: AuthRecoveryFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.RECOVERY.CREDENTIALS'
  });
  const authRecovery = useAuthRecovery();
  const authResend = useAuthResend();
  const [error, setError] = useState('');
  const [notVerified, setNotVerified] = useState(false);
  const appRoutes = useAppRoutes();
  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async ({ email }: AuthRecoveryFormValues) => {
      if (notVerified) {
        try {
          await authResend.mutateAsync({ email });

          toast.info(t('FORM.LABELS.SUCCESS_RESEND'));

          navigate(appRoutes.getLogin().to);
        } catch (e) {
          setError(t('FORM.LABELS.ERROR'));
        }
      } else {
        try {
          await authRecovery.mutateAsync({ email });

          toast.info(t('FORM.LABELS.SUCCESS'));

          navigate(appRoutes.getLogin().to);
        } catch (e) {
          if ((e as AxiosError).response?.data.code === ERROR_EMAIL_NOT_CONFIRMED) {
            toast.warning(t('ERROR.DESCRIPTION'));

            setNotVerified(true);
          } else {
            setError(t('FORM.LABELS.ERROR'));
          }
        }
      }
    },
    [appRoutes, authRecovery, authResend, navigate, notVerified, t]
  );

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email().required()
  });

  return (
    <Form onSubmit={handleSubmit} validationSchema={loginValidationSchema} className={className}>
      <Form.Input.Text
        name="email"
        label={t('FORM.LABELS.EMAIL')}
        inputProps={{ placeholder: t('FORM.LABELS.EMAIL') }}
      />

      {!!error && <Text.Error className="error">{error}</Text.Error>}

      <SpacingGroup spacing={2} className={styles.Buttons}>
        <Button isSubmit type="submit">
          {notVerified ? t('FORM.LABELS.SUBMIT_RESEND') : t('FORM.LABELS.SUBMIT')}
        </Button>

        <Anchor to={appRoutes.getLogin().to} className={styles.Anchor}>
          {t('FORM.LABELS.LOGIN')}
        </Anchor>
      </SpacingGroup>
    </Form>
  );
};
