import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import * as Accordion from '@radix-ui/react-accordion';
import cn from 'classnames';
import { Fragment } from 'react';

import { Icon, ProgressBar, Text } from '@Components/ui';

import styles from './RoomAdviceDetails.module.scss';
import type { RoomAdviceDetailsProps } from './RoomAdviceDetails.props';

export const RoomAdviceDetails = ({ className, advice }: RoomAdviceDetailsProps) => {
  const classNames = cn(styles.RoomAdviceDetails, className);

  return (
    <Accordion.Root type="multiple" className={classNames}>
      {advice?.map(({ label, value, title }, idx) => {
        return (
          <Fragment key={idx}>
            {!!idx && <div className={styles.Line} />}

            <Text.Small className={styles.Title}>{title}:</Text.Small>

            <Accordion.Item key={label} value={label} className={styles.Item}>
              <ProgressBar className={styles.ProgressBar} value={value} max={100} />

              <Accordion.Trigger className={styles.Button}>
                <Icon icon={faChevronDown} color="primary" />
              </Accordion.Trigger>

              <Accordion.Content className={styles.Content}>
                <Text.Small>{label}</Text.Small>
              </Accordion.Content>
            </Accordion.Item>
          </Fragment>
        );
      })}
    </Accordion.Root>
  );
};
