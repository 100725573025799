import { createElement, Fragment } from 'react';
import rehypeParse from 'rehype-parse';
import rehypeReact from 'rehype-react';
import { unified } from 'unified';

import { Anchor, ListChecked, Text } from '@Components/ui';

import type { HtmlRendererProps } from './HtmlRenderer.props';

const defaultComponents: HtmlRendererProps['components'] = {
  a: ({ href, children }) => <Anchor href={href || ''}>{children}</Anchor>,
  h1: ({ children }) => <Text.H1>{children}</Text.H1>,
  h2: ({ children }) => <Text.H2>{children}</Text.H2>,
  h3: ({ children }) => <Text.H3>{children}</Text.H3>,
  h4: ({ children }) => <Text.H4>{children}</Text.H4>,
  h5: ({ children }) => <Text.H5>{children}</Text.H5>,
  h6: ({ children }) => <Text.Normal>{children}</Text.Normal>,
  p: ({ children }) => <Text.Paragraph>{children}</Text.Paragraph>,
  ul: ({ children }) => <ListChecked css={{ marginBottom: '$2' }}>{children}</ListChecked>,
  ol: ({ children }) => <ListChecked css={{ marginBottom: '$2' }}>{children}</ListChecked>,
  li: (props) => <Text.Normal>{props.children}</Text.Normal>
};

export const HtmlRenderer = ({ content, components, ...props }: HtmlRendererProps) => {
  const html = unified()
    .use(rehypeParse, { fragment: true })
    .use(rehypeReact, {
      createElement,
      Fragment,
      components: {
        ...defaultComponents,
        ...components
      }
    })
    .processSync(content ?? undefined).result;

  return <div {...props}>{html}</div>;
};
