import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { AdvisorAddEditSolution } from '@Components/network';
import { useRoomAddSolution } from '@Hooks/Rooms/useRoomAddSolution';
import type { IAlgoliaProduct } from '@Services/AlgoliaService/AlgoliaService.props';
import type { ISolutionConfiguration } from '@Services/Solution';

import { AddProductOverview } from './internal/components';

export const AdvisorProjectRoomAddProductScreen = () => {
  const [activeSolution, setActiveSolution] = useState<ISolutionConfiguration | null>(null);
  const { roomId, projectId } = useParams();

  const addSolutionMutation = useRoomAddSolution();

  const handleProductCongfigure = async (product: IAlgoliaProduct) => {
    addSolutionMutation.mutate(
      {
        id: roomId as string,
        productId: product.objectID,
        name: product.title
      },
      {
        onSuccess: (solution) => {
          setActiveSolution(solution);
        }
      }
    );
  };

  return !!activeSolution ? (
    <AdvisorAddEditSolution
      onSolutionChange={setActiveSolution}
      solution={activeSolution}
      roomId={roomId as string}
      projectId={projectId as string}
      onBack={() => setActiveSolution(null)}
    />
  ) : (
    <AddProductOverview
      onSelect={handleProductCongfigure}
      isLoading={addSolutionMutation.isLoading}
    />
  );
};
