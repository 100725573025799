import ADD_QUOTATION_BUTTON from './ADDQUOTATIONBUTTON.json';
import FREQUENCY_CHART from './FREQUENCYCHART.json';
import INPUT_FILE from './INPUT_FILE.json';
import INPUT_SELECT from './INPUT_SELECT.json';
import LAZY_LIST from './LAZYLIST.json';
import NOTES from './NOTES.json';
import PRODUCT_CARD from './PRODUCTCARD.json';
import PRODUCT_CONFIGURATOR from './PRODUCTCONFIGURATOR.json';
import PROJECT_BLOG from './PROJECTBLOG.json';
import PROJECT_CARD from './PROJECTCARD.json';
import PROJECT_HERO from './PROJECTHERO.json';
import USPWHITEPAPERCARD from './USPWHITEPAPERCARD.json';

export default {
  ADD_QUOTATION_BUTTON,
  FREQUENCY_CHART,
  PROJECT_CARD,
  LAZY_LIST,
  INPUT_FILE,
  INPUT_SELECT,
  NOTES,
  PROJECT_BLOG,
  PRODUCT_CARD,
  PRODUCT_CONFIGURATOR,
  PROJECT_HERO,
  USPWHITEPAPERCARD
};
