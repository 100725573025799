import { Controller } from 'react-hook-form';

import { InputSelect } from '@Components/ui';

import { FormField } from '../FormField/FormField';
import type { FormSelectProps } from './FormSelect.props';

export const FormSelect = ({ inputProps, className, options, ...form }: FormSelectProps) => {
  return (
    <FormField {...form} hasController className={className}>
      <Controller
        name={form.name}
        render={({ field }) => {
          return <InputSelect {...inputProps} {...field} options={options} />;
        }}
      />
    </FormField>
  );
};
