import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { IProject } from '@Services/Project';
import { projectService } from '@Services/Project';

export const useProjectGetOne = (
  id?: string,
  opts?: UseQueryOptions<IProject, unknown, IProject, Array<string | undefined>>
) => {
  return useQuery(
    [projectService.getEndpoint(), id],
    () => {
      return projectService.getOne({ id: id as string });
    },
    {
      enabled: !!id && (opts?.enabled ?? true)
    }
  );
};
