import cn from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import styles from './InputTextArea.module.scss';
import type { InputTextAreaProps } from './InputTextArea.props';

export const InputTextArea = forwardRef(function InputTextArea(
  { className, children, label, name, description, isError, ...props }: InputTextAreaProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const classNames = cn(styles.InputTextArea, className, {
    [styles.isError]: isError
  });

  return <textarea className={classNames} name={name} id={name} {...props} ref={ref} />;
});
