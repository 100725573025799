import { useQueries } from 'react-query';

import { roomService } from '@Services/Room';

export const useRoomGetMultiple = (roomIds?: string[]) => {
  return useQueries(
    (roomIds ?? []).map((id) => ({
      queryKey: [roomService.getEndpoint(), id],
      queryFn: () => {
        return roomService.getOne({ id });
      }
    }))
  );
};
