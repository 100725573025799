import { Footer } from '@Components/layout';
import { Text } from '@Components/ui';
import { useCustomerGetCurrent } from '@Hooks/Customer';

import type { CustomerFooterProps } from './CustomerFooter.props';

export const CustomerFooter = ({ className }: CustomerFooterProps) => {
  const { data } = useCustomerGetCurrent();

  return (
    <Footer className={className} title={data?.company.name}>
      <Text.Small>{`${data?.company.phoneNumber} | ${data?.company.street} ${data?.company.houseNumber}, ${data?.company.postalCode} ${data?.company.city}`}</Text.Small>
    </Footer>
  );
};
