import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { AdvisorCustomers } from '@Components/network';
import { ButtonIcon, Text } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';

import styles from './AdvisorHomeScreen.module.scss';

export const AdvisorHomeScreen = () => {
  const { t } = useTranslation();
  const appRoutes = useAppRoutes();

  return (
    <>
      <section className={styles.AdvisorHomeScreen}>
        <header className={styles.Header}>
          <Text.H1 className={styles.Title} color="primary">
            {t('PAGE.ADVISOR_HOME.TITLE')}
          </Text.H1>

          <ButtonIcon
            className={styles.Button}
            icon={faPlus}
            size={22}
            backgroundColor="secondary"
            color="white"
            accessibilityLabel={t('PAGE.ADVISOR_HOME.ADD_CUSTOMER')}
            to={appRoutes.getAdvisorCustomersCreate().to}
          />
        </header>

        <AdvisorCustomers />
      </section>

      <Outlet />
    </>
  );
};
