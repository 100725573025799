import { useTranslation } from 'react-i18next';

import { InputField, InputRadio } from '@Components/ui';
import { type ISolutionQuantity } from '@Services/Solution';

interface SolutionConfiguratorOverrideQuantityProps {
  isLoading?: boolean;
  quantity: ISolutionQuantity;
  onOverrideQuantityChange: (override: string) => Promise<void>;
}

export const SolutionConfiguratorOverrideQuantity = ({
  isLoading,
  quantity,
  onOverrideQuantityChange
}: SolutionConfiguratorOverrideQuantityProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ROOM.SOLUTIONS.ADD_MODAL'
  });

  const overrideQuantityOptions = [
    {
      value: 'true',
      label: t('CUSTOM_QUANTITY_YES')
    },
    {
      value: '',
      label: t('CUSTOM_QUANTITY_NO')
    }
  ];

  const handleValueChange = async (value: string) => {
    try {
      await onOverrideQuantityChange(value);
    } catch (err: any) {}
  };

  return (
    <InputField label={t('CUSTOM_QUANTITY')}>
      <InputRadio
        isLoading={isLoading}
        disabled={isLoading}
        value={
          overrideQuantityOptions.find(
            (option) => option.value === `${quantity.customQuantitySelected}`
          )?.value ?? ''
        }
        onValueChange={handleValueChange}
        options={overrideQuantityOptions}
      />
    </InputField>
  );
};
