import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField, InputText } from '@Components/ui';
import { useDebounce } from '@Hooks/Common';
import type { ISolutionQuantity } from '@Services/Solution';

import { SolutionConfiguratorOverrideQuantity } from './internal/SolutionConfiguratorOverrideQuantity';

interface SolutionConfiguratorQuantityProps {
  onChange: (value: number) => Promise<void>;
  isLoading?: boolean;
  min?: number;
  max?: number;
  setQuantityError: Dispatch<SetStateAction<boolean>>;
  onOverrideQuantityChange: (override: string) => Promise<void>;
  value?: number;
  quantity: ISolutionQuantity;
}

export const SolutionConfiguratorQuantity = ({
  isLoading,
  onChange,
  setQuantityError,
  quantity,
  value,
  onOverrideQuantityChange
}: SolutionConfiguratorQuantityProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ROOM.SOLUTIONS.ADD_MODAL'
  });
  const [error, setError] = useState('');
  const { debounce } = useDebounce(500);

  const [internalValue, setInternalValue] = useState<string | number | undefined>(value);

  const handleValueChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const newValue = parseInt(inputValue);

    setInternalValue(isNaN(newValue) ? '' : newValue);

    if (isNaN(newValue)) {
      setQuantityError(true);
      return;
    } else {
      setError('');
      setQuantityError(false);
    }

    if (quantity.min && newValue < quantity.min) {
      setError(t('ERROR_MIN', { min: quantity.min }));
      setQuantityError(true);
      return;
    } else {
      setError('');
      setQuantityError(false);
    }

    if (newValue % quantity.incrementation !== 0) {
      setError(t('ERROR_STEP', { quantity: quantity.incrementation }));
      setQuantityError(true);
      return;
    } else {
      setError('');
      setQuantityError(false);
    }

    debounce(async () => {
      try {
        if (!isNaN(newValue)) {
          await onChange(newValue);
        }
      } catch (err: any) {
        setError(err.message);
        setInternalValue(value);
      }
    });
  };

  useEffect(() => {
    setInternalValue(value);
  }, [value, setInternalValue]);

  return (
    <>
      {quantity.hasCustomQuantity && (
        <SolutionConfiguratorOverrideQuantity
          onOverrideQuantityChange={onOverrideQuantityChange}
          quantity={quantity}
          isLoading={isLoading}
        />
      )}

      <InputField label={t('QUANTITY')} error={error}>
        <InputText
          disabled={isLoading}
          value={internalValue}
          onChange={handleValueChange}
          isError={!!error}
          step={quantity.incrementation}
          min={quantity.min}
          max={quantity.max}
          type="number"
        />
      </InputField>
    </>
  );
};
