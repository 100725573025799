import { faEdit, faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { Modal } from '@Components/layout';
import { Button, ButtonIcon, Text } from '@Components/ui';
import { CardRow } from '@Components/ui/CardRow/CardRow';
import { useAppRoutes } from '@Hooks/Common';
import { useRoomGetOne } from '@Hooks/Rooms';
import { useRoomDeleteSolution } from '@Hooks/Rooms/useRoomDeleteSolution';

import { Options } from './internal/Options/Options';
import { Quantity } from './internal/Quantity/Quantity';
import styles from './SolutionCardRow.module.scss';
import type { SolutionCardRowProps } from './SolutionCardRow.props';

export const SolutionCardRow = ({
  className,
  children,
  solution,
  roomId,
  projectId,
  isCurrentAdvisor,
  ...props
}: SolutionCardRowProps) => {
  const classNames = cn(styles.SolutionCardRow, className);
  const deleteSolutionMutation = useRoomDeleteSolution();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const roomQuery = useRoomGetOne();
  const appRoutes = useAppRoutes();

  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ROOM.SOLUTIONS'
  });

  const handleDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = () => {
    deleteSolutionMutation.mutate(
      { id: roomId, solutionId: solution.id },
      {
        onSuccess: async () => {
          await roomQuery.refetch();
          toast.success(t('DELETE_MODAL.SUCCESS'));

          setIsDeleteModalOpen(false);
        }
      }
    );
  };

  return (
    <CardRow variant="blue" imageSrc={solution.product?.image} className={classNames} {...props}>
      <div className={styles.Content}>
        <div className={styles.TitleActions}>
          <Text.H3 className={styles.Title}>{solution.name}</Text.H3>

          {isCurrentAdvisor && (
            <div className={styles.ButtonsWrapper}>
              <ButtonIcon
                to={appRoutes.getAdvisorProjectRoomEditProduct(projectId, roomId, solution.id).to}
                color="primary"
                backgroundColor="gray-light"
                icon={faEdit}
              />

              <ButtonIcon
                onClick={handleDeleteClick}
                color="primary"
                backgroundColor="gray-light"
                icon={faTrashCan}
              />
            </div>
          )}
        </div>

        <Options solution={solution} />

        <Quantity solution={solution} isCurrentAdvisor={isCurrentAdvisor} />
      </div>

      {isCurrentAdvisor && (
        <Modal
          onOpenChange={setIsDeleteModalOpen}
          open={isDeleteModalOpen}
          title={t('DELETE_MODAL.TITLE')}
        >
          <div className={styles.Modal}>
            <div>{t('DELETE_MODAL.BODY', { name: solution.name })}</div>

            <div className={styles.Footer}>
              <Button
                isLoading={deleteSolutionMutation.isLoading}
                className={styles.Confirm}
                onClick={handleDeleteConfirm}
              >
                {t('DELETE_MODAL.CONFIRM')}
              </Button>

              <Modal.Close>{t('DELETE_MODAL.CANCEL')}</Modal.Close>
            </div>
          </div>
        </Modal>
      )}
    </CardRow>
  );
};
