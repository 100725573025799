import { useQuery } from 'react-query';

import { solutionService } from '@Services/Solution';

export const useSolutionGetOne = (solutionId = '') => {
  return useQuery(
    [solutionService.getEndpoint(), solutionId],
    () => {
      return solutionService.getOne({ solutionId });
    },
    { enabled: !!solutionId }
  );
};
