import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import type { Ref } from 'react';
import { forwardRef } from 'react';

import { Icon } from '../Icon/Icon';
import { InputText } from '../InputText/InputText';
import styles from './InputSearch.module.scss';
import type { InputSearchProps } from './InputSearch.props';

export const InputSearchComponent = (
  { className, children, ...props }: InputSearchProps,
  ref: Ref<HTMLInputElement>
) => {
  const classNames = cn(styles.InputSearch, className);

  return (
    <div className={classNames}>
      <Icon icon={faSearch} className={styles.Icon} />

      <InputText {...props} ref={ref} className={styles.InputText} />
    </div>
  );
};

export const InputSearch = forwardRef(InputSearchComponent);
