import { faBars } from '@fortawesome/pro-solid-svg-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import cn from 'classnames';
import { Container } from 'react-bootstrap';

import Logo from '@Assets/logo.png';
import { Menu } from '@Components/layout';
import { Icon, Link } from '@Components/ui';

import styles from './Navbar.module.scss';
import type { NavbarProps } from './Navbar.props';

export const Navbar = ({
  icons,
  links,
  className,
  homeUrl,
  logoSrc = Logo,
  logoAlt,
  ...props
}: NavbarProps) => {
  const classNames = cn(styles.Navbar, className);

  return (
    <header className={classNames} {...props}>
      <Container>
        <div className={styles.Container}>
          {!!logoSrc && (
            <Link to={homeUrl}>
              <img className={styles.Logo} alt={logoAlt} src={logoSrc} />
            </Link>
          )}

          <Menu items={links} className={styles.isDesktop} />

          <div className={styles.IconContainer}>
            {!!icons &&
              icons.map(({ icon, className: iconClassName, ...link }, idx) => {
                return (
                  <Link
                    key={icon.iconName}
                    className={cn(styles.Icon, iconClassName)}
                    data-testid={`icon-${idx}`}
                    {...link}
                  >
                    <Icon icon={icon} size={20} />
                  </Link>
                );
              })}

            <div className={styles.isMobile}>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger asChild>
                  <button className={styles.Icon}>
                    <Icon icon={faBars} size={20} />
                  </button>
                </DropdownMenu.Trigger>

                <DropdownMenu.Content className={styles.MobileDropdown} align="end">
                  <Menu isMobile items={links} />
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};
