import { useMutation, useQueryClient } from 'react-query';

import type { AccountUpdateRequest } from '@Services/Advisor';
import { advisorService } from '@Services/Advisor';
import { authService } from '@Services/AuthService';

export const useAdvisorAccountUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: AccountUpdateRequest) => {
      return advisorService.update(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([authService.getEndpoint()]);
        queryClient.invalidateQueries([advisorService.getEndpoint()]);
      }
    }
  );
};
