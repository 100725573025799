import { faPen, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { AdvisorCustomerProjectsList } from '@Components/network';
import { ButtonIcon, Text } from '@Components/ui';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import { useCustomerGetCurrent } from '@Hooks/Customer';
import { isAdvisor } from '@Utils/AuthUtils';

import styles from './AdvisorCustomerDetailScreen.module.scss';

export const AdvisorCustomerDetailScreen = () => {
  const { data } = useCustomerGetCurrent();
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROJECTS' });
  const { data: currentAdvisor } = useAuthGetMe();
  const appRoutes = useAppRoutes();

  const isCurrentAdvisor =
    isAdvisor() && !!currentAdvisor && data?.accountManager?.id === currentAdvisor?.id;

  return (
    <>
      <section className={styles.AdvisorCustomerDetailScreen}>
        <header className={styles.Header}>
          <Text.H1 color="primary">{data?.name}</Text.H1>

          {isCurrentAdvisor && (
            <ButtonIcon
              className={styles.Button}
              icon={faPen}
              size={22}
              backgroundColor="secondary"
              color="white"
              accessibilityLabel={t('ADD_PROJECT')}
              to={appRoutes.getAdvisorCustomerUpdate(data.publicId).to}
            />
          )}

          <ButtonIcon
            icon={faPlus}
            size={22}
            backgroundColor="secondary"
            color="white"
            accessibilityLabel={t('ADD_PROJECT')}
            to={appRoutes.getAdvisorProjectCreate(data?.publicId).to}
          />
        </header>

        <AdvisorCustomerProjectsList />
      </section>

      <Outlet />
    </>
  );
};
