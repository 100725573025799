import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { CustomerProjectsPreview } from '@Components/network';
import { Box, Text } from '@Components/ui';
import { useCustomerContext } from '@Hooks/Customer';

import styles from './CustomerHomeScreen.module.scss';

export const CustomerHomeScreen = () => {
  const { t } = useTranslation();

  const customer = useCustomerContext();

  return (
    <Box pt={13} className={styles.CustomerHomeScreen}>
      <Row>
        <Col md={9} lg={8}>
          <Text.H1 className={styles.Title}>
            {t('DOMAIN.CUSTOMER.HOME_PAGE.TITLE', { name: customer.name })}
          </Text.H1>
        </Col>
      </Row>

      <CustomerProjectsPreview className={styles.Projects} />
    </Box>
  );
};
