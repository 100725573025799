import cn from 'classnames';
import { useCallback } from 'react';

import { SpacingGroup } from '@Components/ui';

import styles from './Pagination.module.scss';
import type { PaginationProps } from './Pagination.props';

export const Pagination = ({
  className,
  activeIndex,
  totalLength,
  onClick,
  ...props
}: PaginationProps) => {
  const classNames = cn(styles.Pagination, className);

  const handleClick = useCallback(
    (clickedIndex: number) => () => {
      if (onClick) {
        onClick(clickedIndex);
      }
    },
    [onClick]
  );

  return (
    <SpacingGroup spacing={1} className={classNames} {...props}>
      {[...Array(totalLength)].map((_, idx) => {
        const bulletClassNames = cn(styles.Bullet, { [styles.isActive]: idx === activeIndex });

        return <div key={idx} className={bulletClassNames} onClick={handleClick(idx)} />;
      })}
    </SpacingGroup>
  );
};
