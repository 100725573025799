import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import type { CustomerCreateRequest } from '@Services/Customer';
import Yup from '@Utils/YupUtils';

import type { CreateCustomerFormProps, CreateCustomerFormValues } from './CreateCustomerForm.props';

const createCustomerSchema = Yup.object({
  name: Yup.string().required(),
  number: Yup.string(),
  email: Yup.string().required().email(),
  phone: Yup.string().required().phone(),
  street: Yup.string(),
  houseNumber: Yup.string(),
  postalCode: Yup.string(),
  city: Yup.string(),
  language: Yup.string(),
  contactPerson: Yup.string(),
  country: Yup.string()
});

export const CreateCustomerForm = ({ onSubmit, ...props }: CreateCustomerFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.CUSTOMER.FORM.CREATE_UPDATE_CUSTOMER'
  });

  const formRef = useRef<FormMethods<CreateCustomerFormValues>>(null);

  const submitHandler = useCallback(
    (data: CustomerCreateRequest) => {
      onSubmit?.(data);

      formRef.current?.reset();
    },
    [onSubmit]
  );

  return (
    <Form<CreateCustomerFormValues>
      {...props}
      validationSchema={createCustomerSchema}
      ref={formRef}
      onSubmit={submitHandler}
    >
      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.GENERAL.NAME.PLACEHOLDER') }}
        name="name"
        isRequired
        label={t('SECTIONS.GENERAL.NAME.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.GENERAL.NUMBER.PLACEHOLDER') }}
        name="number"
        label={t('SECTIONS.GENERAL.NUMBER.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.STREET.PLACEHOLDER') }}
        name="street"
        label={t('SECTIONS.CONTACT.STREET.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.HOUSE_NUMBER.PLACEHOLDER') }}
        name="houseNumber"
        label={t('SECTIONS.CONTACT.HOUSE_NUMBER.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.POSTAL_CODE.PLACEHOLDER') }}
        name="postalCode"
        label={t('SECTIONS.CONTACT.POSTAL_CODE.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.CITY.PLACEHOLDER') }}
        name="city"
        label={t('SECTIONS.CONTACT.CITY.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.CONTACT_PERSON.PLACEHOLDER') }}
        name="contactPerson"
        label={t('SECTIONS.CONTACT.CONTACT_PERSON.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.EMAIL.PLACEHOLDER') }}
        name="email"
        isRequired
        label={t('SECTIONS.CONTACT.EMAIL.LABEL')}
      />

      <Form.Input.Text
        inputProps={{ placeholder: t('SECTIONS.CONTACT.PHONE.PLACEHOLDER') }}
        name="phone"
        isRequired
        label={t('SECTIONS.CONTACT.PHONE.LABEL')}
      />

      <Form.Input.Text
        name="country"
        label={t('SECTIONS.CONTACT.COUNTRY.LABEL')}
        inputProps={{ placeholder: t('SECTIONS.CONTACT.COUNTRY.PLACEHOLDER') }}
      />

      <Form.Input.Language
        name="language"
        label={t('SECTIONS.CONTACT.LANGUAGE.LABEL')}
        inputProps={{ placeholder: t('SECTIONS.CONTACT.LANGUAGE.PLACEHOLDER') }}
      />

      <Button isSubmit>{t('CREATE')}</Button>
    </Form>
  );
};
