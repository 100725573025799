import cn from 'classnames';
import { get } from 'lodash';

import type { IBaseModel } from '@Constants/interfaces';

import styles from './Row.module.scss';
import type { RowProps } from './Row.props';

export const Row = <T extends IBaseModel>({ columns, row, className, ...props }: RowProps<T>) => {
  const classNames = cn(styles.Row, className);

  return (
    <tr className={classNames} {...props}>
      {columns.map(({ source, render = get }) => (
        <td className={styles.Cell} key={source}>
          {render(row, source)}
        </td>
      ))}
    </tr>
  );
};
