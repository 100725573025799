import { Controller } from 'react-hook-form';

import { InputMultiSelect } from '@Components/ui';

import { FormField } from '../FormField/FormField';
import type { FormMultiSelectProps } from './FormMultiSelect.props';

export const FormMultiSelect = ({
  inputProps,
  className,
  options,
  ...form
}: FormMultiSelectProps) => {
  return (
    <FormField {...form} hasController className={className}>
      <Controller
        name={form.name}
        render={({ field }) => {
          return <InputMultiSelect {...inputProps} {...field} options={options} />;
        }}
      />
    </FormField>
  );
};
