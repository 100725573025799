import cn from 'classnames';

import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './Spinner.module.scss';
import type { SpinnerProps } from './Spinner.props';

export const Spinner = ({ color = 'primary', size = 40, className, ...props }: SpinnerProps) => {
  const spinnerVars = scopedCssVars({
    size: `${size}px`,
    borderWidth: `${size / 5}px`,
    color: color && `var(--color-${color})`,
    colorLight: color && `var(--color-${color}-light)`
  });
  const classNames = cn(styles.Spinner, className, spinnerVars);

  const loaderClassNames = cn(styles.Loader);

  return (
    <div className={classNames} {...props}>
      <div className={loaderClassNames} />
    </div>
  );
};
