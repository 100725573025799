import { faSignOut, faUser } from '@fortawesome/pro-solid-svg-icons';
import { useCallback } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import { useAuthGetMe, useAuthLogout } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';

import { Page } from '../Page/Page';
import type { AdvisorPageProps } from './AdvisorPage.props';

export const AdvisorPage = ({ children, ...props }: AdvisorPageProps) => {
  const authLogout = useAuthLogout();
  const navigate = useNavigate();
  const { data } = useAuthGetMe();
  const appRoutes = useAppRoutes();

  const handleSignoutClick = useCallback(async () => {
    await authLogout.mutateAsync();
    navigate(appRoutes.getLogin().to);
  }, [authLogout, navigate, appRoutes]);

  const homeUrl = appRoutes.getAdvisorHome().to;

  const links = [
    appRoutes.getAdvisorHome(),
    appRoutes.getAdvisorProjects(),
    appRoutes.getAdvisorProducts()
  ];

  const icons = [
    {
      icon: faUser,
      onClick: () => {
        navigate(appRoutes.getAdvisorAccount().to);
      }
    },
    {
      icon: faSignOut,
      onClick: handleSignoutClick
    }
  ];

  return (
    <Page
      icons={icons}
      homeUrl={homeUrl}
      links={links}
      logoSrc={!!data && data?.company?.image?.path}
      {...props}
    >
      <Outlet context={data} />

      {children}
    </Page>
  );
};
