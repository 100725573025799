import { useMutation, useQueryClient } from 'react-query';

import type { ProjectUpdateRequest } from '@Services/Project';
import { projectService } from '@Services/Project';

export const useProjectUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ProjectUpdateRequest) => {
      return projectService.updateProject(params);
    },
    {
      onSuccess: (_, { id }) => {
        queryClient.invalidateQueries([projectService.getEndpoint(), id]);
      }
    }
  );
};
