import cn from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import styles from './Link.module.scss';
import type { BaseAnchorProps, BaseButtonProps, BaseLinkProps, LinkProps } from './Link.props';

export const Link = ({
  to,
  isSubmit,
  onClick,
  target = '_blank',
  href,
  className,
  ...props
}: LinkProps) => {
  const classNames = cn(styles.Link, className);

  if (!!to) {
    return <RouterLink {...({ to, className: classNames, ...props } as BaseLinkProps)} />;
  }

  if (!!href) {
    return <a {...({ href, target, className: classNames, ...props } as BaseAnchorProps)} />;
  }

  if (!!onClick || isSubmit) {
    return (
      <button
        {...({
          onClick,
          type: isSubmit ? 'submit' : 'button',
          className: classNames,
          ...props
        } as BaseButtonProps)}
      />
    );
  }

  return <span {...({ className: classNames, ...props } as BaseButtonProps)} />;
};
