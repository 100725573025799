import { InputCheckbox, InputField } from '@Components/ui';
import type { ConfigurationOption } from '@Services/Solution';

interface SolutionConfiguratorCheckboxOptionProps {
  option: ConfigurationOption;
  onChange: (option: string, selected?: boolean) => void;
  isLoading?: boolean;
}

export const SolutionConfiguratorCheckboxOption = ({
  option,
  onChange,
  isLoading
}: SolutionConfiguratorCheckboxOptionProps) => {
  const selectedValues =
    option.options?.flatMap(({ selected, id }) => (selected && id ? [id] : [])) ?? [];

  const handleChange = (newValues: string[]) => {
    const addedValue = newValues.find((v) => !selectedValues.includes(v));
    const removedValue = selectedValues.find((v) => !newValues.includes(v));

    if (addedValue) {
      onChange(addedValue, true);
    }

    if (removedValue) {
      onChange(removedValue, false);
    }
  };

  return (
    <InputField label={option.name} key={option.id}>
      <InputCheckbox
        isLoading={isLoading}
        value={option.options?.filter((_option) => _option.selected)?.map((_opt) => _opt.id)}
        onChange={(_values) => {
          const values = _values.filter((value) => isNaN(+value)) as string[];
          handleChange(values);
        }}
        isConfigurator
        options={
          option.options?.map((_option) => {
            return {
              value: _option.id,
              label: _option.text
            };
          }) ?? []
        }
      />
    </InputField>
  );
};
