import type { OneRequest } from '@Constants/interfaces';
import { acousticClient } from '@Services/Clients';
import { ResourceService } from '@Services/Common';

import type {
  CustomerCreateRequest,
  CustomerCreateResponse,
  CustomerGetOnePublicRequest,
  CustomerGetOnePublicResponse,
  CustomerGetOneResponse,
  CustomerUpdateRequest,
  CustomerUpdateResponse,
  ICustomer
} from './CustomerService.props';

export class CustomerService extends ResourceService<ICustomer> {
  getOnePublic = async ({ publicId }: CustomerGetOnePublicRequest) => {
    const { data } = await this.get<CustomerGetOnePublicResponse>(`/${publicId}/public`);

    return data;
  };

  createCustomer = async (params: CustomerCreateRequest) => {
    const response = await this.post<CustomerCreateResponse>(`/`, params);

    return response.data;
  };

  updateCustomer = async (params: CustomerUpdateRequest) => {
    const response = await this.put<CustomerUpdateResponse>(`/${params.id}`, params);

    return response.data;
  };

  getOne!: ({ id }: OneRequest) => Promise<CustomerGetOneResponse>;
}

export const customerService = new CustomerService(acousticClient.getAxiosInstance(), '/customers');
