import themes from '@Constants/themes';

import type { SpacingSize } from '../constants/interfaces';

export const getSpacing = (spacing: SpacingSize, properties: string[]) => {
  const style = properties.map((property) => {
    const value = Number(spacing) ? `${Number(spacing) * themes.default.spacing}px` : spacing;

    return [property, value];
  });

  return Object.fromEntries(style);
};
