import { acousticClient } from '@Services/Clients';
import { ResourceService } from '@Services/Common';
import type { ISolutionConfiguration } from '@Services/Solution/SolutionService.types';

import type {
  IRoom,
  RoomAddMeasurementRequest,
  RoomAddMeasurementResponse,
  RoomAddSolutionRequest,
  RoomCreateRequest,
  RoomCreateResponse,
  RoomDeleteRequest,
  RoomDeleteSolutionRequest,
  RoomDuplicateRequest,
  RoomDuplicateResponse,
  RoomUpdateRequest,
  RoomUpdateResponse
} from './RoomService.props';

export class RoomService extends ResourceService<IRoom> {
  createRoom = async (params: RoomCreateRequest) => {
    const { data } = await this.post<RoomCreateResponse>(`/`, params);

    return data;
  };

  updateRoom = async (params: RoomUpdateRequest) => {
    const { data } = await this.put<RoomUpdateResponse>(`/${params.id}`, params);

    return data;
  };

  deleteRoom = async ({ id }: RoomDeleteRequest) => {
    const { data } = await this.delete(`/${id}`);

    return data;
  };

  duplicateRoom = async ({ id }: RoomDuplicateRequest) => {
    const { data } = await this.post<RoomDuplicateResponse>(`/${id}/duplicate`);

    return data;
  };

  addMeasurement = async ({ id, ...measurements }: RoomAddMeasurementRequest) => {
    const { data } = await this.put<RoomAddMeasurementResponse>(
      `/${id}/measurements`,
      measurements
    );

    return data;
  };

  deleteSolution = async ({ id, solutionId }: RoomDeleteSolutionRequest) => {
    const { data } = await this.delete(`/${id}/solutions/${solutionId}`);

    return data;
  };

  addSolution = async ({ id, productId, name }: RoomAddSolutionRequest) => {
    const params = {
      product: {
        id: productId
      },
      name
    };

    const { data } = await this.post<ISolutionConfiguration>(`/${id}/solutions`, params);

    return data;
  };
}

export const roomService = new RoomService(acousticClient.getAxiosInstance(), '/rooms');
