import axios from 'axios';

import { AppError } from '@Constants/enums';
import { BaseService } from '@Services/Common';
import { getAccessTokens, removeAccessTokens, setAccessTokens } from '@Utils/StorageUtils';
import { i18n } from '@Utils/TranslationUtils';

import type {
  AuthLoginRequest,
  AuthLoginResponse,
  AuthRecoveryRequest,
  AuthRefreshTokenResponse,
  AuthRegisterRequest,
  AuthResendRequest,
  AuthUpdatePasswordRequest,
  AuthUserResponse,
  IUser
} from './AuthService.props';

export class AuthService extends BaseService {
  login = async (params: AuthLoginRequest) => {
    const { data } = await this.post<AuthLoginResponse>(`/auth/login`, params);

    if (data.role !== 'ADVISOR') {
      return false;
    }

    setAccessTokens(data);

    return data;
  };

  logout = async () => {
    await this.post(`/auth/logout`);

    removeAccessTokens();
  };

  recover = async (params: AuthRecoveryRequest) => {
    await this.post('/auth/forgot', params);
  };

  updatePassword = async (params: AuthUpdatePasswordRequest) => {
    const { status } = await this.post('/auth/reset', params);

    return status;
  };

  getMe = async (): Promise<AuthUserResponse> => {
    const { data } = await this.getAxiosInstance().get<IUser>('/me');

    return data;
  };

  refreshTokens = async () => {
    const tokens = getAccessTokens();

    if (!tokens) {
      throw new Error(i18n.t(AppError.NO_ACCESS_TOKENS_FOUND));
    }

    const params = {
      refreshToken: tokens.refreshToken
    };

    const { data } = await this.post<AuthRefreshTokenResponse>(`/auth/refresh`, params);

    setAccessTokens(data);
  };

  register = async (params: AuthRegisterRequest) => {
    await this.post('/users/register', params);
  };

  resend = async (params: AuthResendRequest) => {
    await this.post('/users/register/resend', params);
  };
}

const authInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

authInstance.interceptors.request.use(({ headers, ...config }) => {
  const accessToken = getAccessTokens()?.accessToken;
  headers.Authorization ??= accessToken ? `Bearer ${accessToken}` : '';

  return { headers, ...config };
});

export const authService = new AuthService(authInstance, '');
