import { acousticClient } from '@Services/Clients';
import { BaseService } from '@Services/Common';

import type {
  ISolutionConfiguration,
  SolutionChangeQuantityRequest,
  SolutionConfigureRequest,
  SolutionGetRequest,
  SolutionOverrideQuantityRequest
} from './SolutionService.types';
class SolutionService extends BaseService {
  getOne = async (request: SolutionGetRequest) => {
    const { data } = await this.get<ISolutionConfiguration>(`/${request.solutionId}`);

    return data;
  };

  configure = async (request: SolutionConfigureRequest) => {
    const { data } = await this.post<ISolutionConfiguration>(
      `/${request.solutionId}/configure`,
      request
    );

    return data;
  };

  changeQuantity = async (request: SolutionChangeQuantityRequest) => {
    const { data } = await this.put<ISolutionConfiguration>(`/${request.solutionId}/quantity`, {
      quantity: request.quantity
    });

    return data;
  };

  overrideQuantity = async (request: SolutionOverrideQuantityRequest) => {
    const { data } = await this.put<ISolutionConfiguration>(
      `/${request.solutionId}/quantity/override`,
      {
        override: request.override
      }
    );

    return data;
  };
}

export const solutionService = new SolutionService(acousticClient.getAxiosInstance(), 'solutions');
