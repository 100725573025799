import cn from 'classnames';

import { Text, Tooltip } from '@Components/ui';

import styles from './Statistic.module.scss';
import type { StatisticProps } from './Statistic.props';

export const Statistic = ({ tooltip, label, className, children, ...props }: StatisticProps) => {
  const classNames = cn(styles.Statistic, className);

  return (
    <div className={classNames} {...props}>
      {!!label && (
        <Text.Small color="black-light">
          <strong>{label}</strong>
        </Text.Small>
      )}

      <div className={styles.TooltipContainer}>
        <Text.H3 color="primary">{children}</Text.H3>

        {!!tooltip && <Tooltip description={tooltip} />}
      </div>
    </div>
  );
};
