import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

import { ButtonIcon, Text } from '@Components/ui';
import { CardRow } from '@Components/ui/CardRow/CardRow';

import styles from './ProductCardRow.module.scss';
import type { ProductCardRowProps } from './ProductCardRow.props';

export const ProductCardRow = ({ className, product, onClick, ...props }: ProductCardRowProps) => {
  const classNames = cn(styles.ProductCardRow, className, {
    [styles.isClickable]: !!onClick
  });

  return (
    <CardRow
      variant="blue"
      imageSrc={product?.thumbnailUrl ?? ''}
      className={classNames}
      isImageClickable
      {...props}
    >
      <div className={styles.Content} onClick={onClick}>
        <Text.H3 className={styles.Title}>{product.title}</Text.H3>

        <ButtonIcon color="primary" backgroundColor="gray-light" icon={faPlus} />
      </div>
    </CardRow>
  );
};
