import { useMutation } from 'react-query';

import type { AuthRegisterRequest } from '@Services/AuthService';
import { authService } from '@Services/AuthService';

export const useAuthRegister = () => {
  return useMutation((request: AuthRegisterRequest) => {
    return authService.register(request);
  });
};
