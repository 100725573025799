import { acousticClient } from '@Services/Clients';
import { ResourceService } from '@Services/Common';

import type { IStorage } from './StorageService.props';

export class StorageService extends ResourceService<IStorage> {
  upload = async (files: File | File[]) => {
    const data = new FormData();

    if (Array.isArray(files)) {
      files.map((file) => data.append('files', file));
    } else {
      data.append('files', files);
    }

    const response = await this.post<IStorage[]>('', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    return response.data;
  };
}

export const storageService = new StorageService(
  acousticClient.getAxiosInstance(),
  '/admin/storage'
);
