import { useTranslation } from 'react-i18next';

import { AuthLoginForm } from '@Components/form';
import { Modal } from '@Components/layout';

export const AuthLoginScreen = () => {
  const { t } = useTranslation();

  return (
    <Modal isClosable={false} title={t('DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.FORM.TITLE')}>
      <AuthLoginForm />
    </Modal>
  );
};
