import { useMutation, useQueryClient } from 'react-query';

import type { AuthLoginRequest } from '@Services/AuthService';
import { authService } from '@Services/AuthService';

export const useAuthLogin = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (request: AuthLoginRequest) => {
      return authService.login(request);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries();
      }
    }
  );
};
