import { faChevronDown, faEllipsis } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';

import { Icon, Rounded, Text } from '..';
import { SpacingGroup } from '../SpacingGroup/SpacingGroup';
import styles from './RoomSkeleton.module.scss';
import type { RoomSkeletonProps } from './RoomSkeleton.props';

const iconProps = {
  size: 15,
  hasCircle: true,
  backgroundColor: 'gray-light',
  color: 'primary'
} as const;

export const RoomSkeleton = ({ className, ...props }: RoomSkeletonProps) => {
  const classNames = cn(styles.RoomSkeleton, className);

  return (
    <Rounded className={classNames} hasShadow {...props}>
      <div className={styles.Image} />

      <div className={styles.Layout}>
        <Text.H3 className={styles.Title}>
          <span className={styles.Loader} style={{ width: '61%' }} />
        </Text.H3>

        <SpacingGroup isVertical>
          <span className={styles.Loader} style={{ width: '70%' }} />

          <span className={styles.Loader} style={{ width: '53%' }} />
        </SpacingGroup>
      </div>

      <div className={styles.Buttons}>
        <button>
          <Icon icon={faEllipsis} {...iconProps} />
        </button>

        <button className={styles.Toggle}>
          <Icon icon={faChevronDown} {...iconProps} />
        </button>
      </div>
    </Rounded>
  );
};
