import { intlFormat, isValid, parseJSON } from 'date-fns';

import { i18n } from './TranslationUtils';

type DateParam = Parameters<typeof parseJSON>[0];
type FormatParams = Parameters<typeof intlFormat>[1];

export const formatDateTimeIntl = (
  date: DateParam,
  formatOptions: FormatParams = {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  }
) => {
  const parsedDate = parseJSON(date);

  if (!isValid(parsedDate)) {
    return null;
  }

  return intlFormat(parsedDate, formatOptions, {
    locale: i18n.language
  });
};

export const formatDateIntl = (date: DateParam) =>
  formatDateTimeIntl(date, {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
