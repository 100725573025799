import { useMutation, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import type { IProject, ProjectUpdateRequest } from '@Services/Project';
import { projectService } from '@Services/Project';

import { useProjectGetOne } from './useProjectGetOne';

export const useProjectUpdateNotes = () => {
  const { projectId, customerId } = useParams();
  const { data: project } = useProjectGetOne(projectId, {
    enabled: !customerId
  });

  const queryClient = useQueryClient();

  return useMutation(
    (notes: ProjectUpdateRequest['notes']) => {
      return projectService.updateProject({
        ...(project as IProject),
        notes
      });
    },
    {
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries([projectService.getEndpoint(), id]);
      }
    }
  );
};
