import { InputTextArea } from '@Components/ui';

import { FormField } from '../FormField/FormField';
import type { FormTextAreaProps } from './FormTextArea.props';

export const FormTextArea = ({ inputProps, className, ...form }: FormTextAreaProps) => {
  return (
    <FormField {...form} className={className}>
      <InputTextArea {...inputProps} />
    </FormField>
  );
};
