import { useMutation } from 'react-query';

import type { AuthResendRequest } from '@Services/AuthService';
import { authService } from '@Services/AuthService';

export const useAuthResend = () => {
  return useMutation((request: AuthResendRequest) => {
    return authService.resend(request);
  });
};
