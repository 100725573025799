import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import { DEFAULT_LANGUAGE } from '@Constants/configs';
import { Locale } from '@Constants/enums';
import locales from '@Locales/index';

export function getLocaleKeyByValue(value: string): string | undefined {
  const entry = Object.entries(Locale).find((localeObj) => localeObj[1] === value);
  return entry ? entry[0] : undefined;
}

i18next.use(initReactI18next).init({
  resources: locales,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false
  }
});

export const setupTranslation = () => {
  return i18next;
};

export const i18n = i18next;
