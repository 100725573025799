import { faChevronDown, faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import cn from 'classnames';

import type { RoomCardRowProps } from '@Components/ui';
import { Dropdown, Icon, ProgressList, Rounded, Text } from '@Components/ui';

import { CardRow } from '../CardRow/CardRow';
import { SpacingGroup } from '../SpacingGroup/SpacingGroup';
import styles from './RoomCardRow.module.scss';

const iconProps = {
  size: 15,
  hasCircle: true,
  backgroundColor: 'gray-light',
  color: 'primary'
} as const;

export const RoomCardRow = ({
  className,
  children,
  listItems,
  imageSrc,
  isDefaultOpened,
  title,
  dropdownItems,
  specList,
  ...props
}: RoomCardRowProps) => {
  const classNames = cn(styles.RoomCardRow, className);

  return (
    <Collapsible.Root defaultOpen={isDefaultOpened}>
      <CardRow isLarge imageSrc={imageSrc} className={classNames} {...props}>
        <div className={styles.Layout}>
          <Text.H3 className={styles.Title}>{title}</Text.H3>

          <SpacingGroup className={styles.SpecList}>
            {specList?.map(({ label, value }, idx) => (
              <Text.Small key={idx}>
                <b>{label}: </b>

                {value}
              </Text.Small>
            ))}
          </SpacingGroup>

          <ProgressList isLarge items={listItems} />
        </div>

        {(!!children || !!dropdownItems?.length) && (
          <div className={styles.Buttons}>
            {!!dropdownItems?.length && (
              <Dropdown items={dropdownItems} className={styles.Dropdown}>
                <Icon icon={faEllipsis} {...iconProps} />
              </Dropdown>
            )}

            {!!children && (
              <Collapsible.Trigger className={styles.Toggle}>
                <Icon icon={faChevronDown} className={styles.ToggleIcon} {...iconProps} />
              </Collapsible.Trigger>
            )}
          </div>
        )}
      </CardRow>

      <Collapsible.Content className={styles.Content}>
        <Rounded hasShadow className={styles.Layout}>
          {children}
        </Rounded>
      </Collapsible.Content>
    </Collapsible.Root>
  );
};
