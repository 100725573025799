import cn from 'classnames';

import type { TextElement, TextProps } from '@Components/ui';
import { BaseText } from '@Components/ui';

import styles from './Label.module.scss';

const defaultElement = 'label' as TextElement;
export const Label = <T extends TextElement = typeof defaultElement>({
  className,
  as = defaultElement as T,
  ...props
}: TextProps<T>) =>
  BaseText<T>({
    className: cn(className, styles.Label),
    as,
    ...props
  } as TextProps<T>);
