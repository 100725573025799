import cn from 'classnames';

import { Icon, Link } from '@Components/ui';

import styles from './ButtonArea.module.scss';
import type { ButtonAreaProps } from './ButtonArea.props';

export const ButtonArea = ({ icon, className, children, ...props }: ButtonAreaProps) => {
  const classNames = cn(styles.ButtonArea, className);

  return (
    <Link className={classNames} {...props}>
      {!!icon && (
        <Icon
          icon={icon}
          size={14}
          color="primary"
          backgroundColor="gray-light"
          hasCircle
          className={styles.Icon}
        />
      )}

      {children}
    </Link>
  );
};
