import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import Yup from '@Utils/YupUtils';

import type {
  UpdateProjectContactDetailFormProps,
  UpdateProjectContactDetailFormValues
} from './UpdateProjectContactDetailForm.props';

const updateProjectContactDetailSchema = Yup.object({
  name: Yup.string().required(),
  contactPerson: Yup.string(),
  phone: Yup.string().required(),
  email: Yup.string().required(),
  city: Yup.string(),
  street: Yup.string(),
  houseNumber: Yup.string(),
  postalCode: Yup.string(),
  language: Yup.string().required()
});

export const UpdateProjectContactDetailForm = ({
  onSubmit,
  ...props
}: UpdateProjectContactDetailFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.CONTACT_DETAILS'
  });

  const formRef = useRef<FormMethods<UpdateProjectContactDetailFormValues>>(null);

  const submitHandler = useCallback(
    async (data: UpdateProjectContactDetailFormValues) => {
      await onSubmit?.(data);

      formRef.current?.reset();
    },
    [onSubmit]
  );

  return (
    <Form<UpdateProjectContactDetailFormValues>
      {...props}
      validationSchema={updateProjectContactDetailSchema}
      ref={formRef}
      onSubmit={submitHandler}
    >
      <Form.Input.Text
        name="name"
        label={t('FIELDS.TITLE.LABEL')}
        inputProps={{ placeholder: t('FIELDS.TITLE.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="contactPerson"
        label={t('FIELDS.CONTACT_PERSON.LABEL')}
        inputProps={{ placeholder: t('FIELDS.CONTACT_PERSON.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="phone"
        label={t('FIELDS.PHONE.LABEL')}
        inputProps={{ placeholder: t('FIELDS.PHONE.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="email"
        label={t('FIELDS.EMAIL.LABEL')}
        inputProps={{ placeholder: t('FIELDS.EMAIL.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="city"
        label={t('FIELDS.CITY.LABEL')}
        inputProps={{ placeholder: t('FIELDS.CITY.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="street"
        label={t('FIELDS.STREET.LABEL')}
        inputProps={{ placeholder: t('FIELDS.STREET.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="houseNumber"
        label={t('FIELDS.HOUSE_NUMBER.LABEL')}
        inputProps={{ placeholder: t('FIELDS.HOUSE_NUMBER.PLACEHOLDER') }}
      />

      <Form.Input.Text
        name="postalCode"
        label={t('FIELDS.POSTAL_CODE.LABEL')}
        inputProps={{ placeholder: t('FIELDS.POSTAL_CODE.PLACEHOLDER') }}
      />

      <Form.Input.Language
        name="language"
        label={t('FIELDS.LANGUAGE.LABEL')}
        inputProps={{
          placeholder: t('FIELDS.LANGUAGE.PLACEHOLDER'),
          defaultValue: props.initialValues?.language
        }}
      />

      <Button isSubmit type="submit">
        {t('SUBMIT')}
      </Button>
    </Form>
  );
};
