import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import { IS_LOCAL, SENTRY_DSN } from '@Constants/configs';

interface BaseUser {
  id: string;
  email: string;
}

if (IS_LOCAL === false) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: process.env.VERCEL_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

export const setSentryUser = (user: BaseUser) => {
  Sentry.setUser({
    id: user.id,
    email: user.email
  });
};

export const setupSentry = () => {
  return !IS_LOCAL;
};
