import { faExpand } from '@fortawesome/pro-solid-svg-icons';
import {
  CategoryScale,
  Chart as ChartJS,
  defaults,
  Legend,
  LinearScale,
  LineElement,
  LogarithmicScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import cn from 'classnames';
import { useState } from 'react';
import { Line } from 'react-chartjs-2';

import { Modal } from '@Components/layout';
import { Icon } from '@Components/ui';
import { getTheme } from '@Utils/ThemeUtils';

import styles from './Chart.module.scss';
import type { ChartProps } from './Chart.props';

const { color, font } = getTheme();

ChartJS.register(
  CategoryScale,
  LogarithmicScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  {
    id: 'mouseLine',
    afterEvent: (_, { inChartArea }, opts) => {
      opts.inChartArea = inChartArea;
    },
    afterDraw: ({ ctx, chartArea, tooltip }, _, { inChartArea }) => {
      if (tooltip && inChartArea) {
        ctx.save();
        ctx.lineWidth = 1;
        ctx.strokeStyle = color.primary.default;
        ctx.moveTo(tooltip.caretX, chartArea.bottom);
        ctx.lineTo(tooltip.caretX, chartArea.top);
        ctx.stroke();
        ctx.setLineDash([5, 5]);
        ctx.restore();
      }
    }
  }
);

defaults.font.family = font.default.fontFamily;
defaults.font.weight = font.bold.fontWeight;
defaults.elements.point.radius = 0;
defaults.color = color.gray.dark;
defaults.plugins.legend.display = false;

export const Chart = ({ children, data, options, className, ...props }: ChartProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const classNames = cn(className, styles.Chart);

  const handleToggle = () => {
    setIsOpen((wasOpen) => !wasOpen);
  };

  return (
    <>
      <div {...props} className={classNames}>
        <Icon icon={faExpand} className={styles.Icon} onClick={handleToggle} />

        <div className={styles.Wrapper}>
          <Line data={data} options={options} />
        </div>
      </div>

      <Modal open={isOpen} onOpenChange={setIsOpen} className={styles.Modal}>
        {isOpen && <Line data={data} options={options} />}
      </Modal>
    </>
  );
};
