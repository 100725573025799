import { useTranslation } from 'react-i18next';

import { AuthRecoveryForm } from '@Components/form';
import { Modal } from '@Components/layout';

export const AuthForgotPasswordScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.RECOVERY.CREDENTIALS.FORM'
  });

  return (
    <Modal isClosable={false} title={t('TITLE')}>
      <AuthRecoveryForm />
    </Modal>
  );
};
