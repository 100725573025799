import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { type ForwardedRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@Components/ui/Icon/Icon';

import styles from './InputSelect.module.scss';
import type { InputSelectProps } from './InputSelect.props';

const InputSelectComponent = (
  {
    className,
    children,
    options,
    hasPlaceholder = true,
    placeholderText,
    defaultValue = '',
    isLoading,
    value,
    ...props
  }: InputSelectProps,
  ref: ForwardedRef<any>
) => {
  const classNames = cn(styles.InputSelect, className, {
    [styles.isLoading]: isLoading
  });

  const { t } = useTranslation();

  return (
    <label className={classNames}>
      <select
        disabled={isLoading}
        {...props}
        defaultValue={defaultValue}
        className={styles.Select}
        ref={ref}
      >
        {hasPlaceholder && (
          <option value="" disabled hidden>
            {placeholderText ?? t('COMPONENTS.INPUT_SELECT.PLACEHOLDER_TEXT')}
          </option>
        )}

        {options.map((option) => {
          return (
            <option key={option.value} value={option.value} disabled={option.isDisabled}>
              {option.label}
            </option>
          );
        })}
      </select>

      <Icon className={styles.Icon} icon={faChevronDown} color="black" />
    </label>
  );
};

export const InputSelect = forwardRef(InputSelectComponent);
