import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Anchor, InputSearch, QueryIndicator } from '@Components/ui';
import { useAppRoutes, useInfiniteData, useTable } from '@Hooks/Common';
import { useCustomerGetList } from '@Hooks/Customer';
import type { ICustomer } from '@Services/Customer';
import { formatDateTimeIntl } from '@Utils/DateUtils';

import styles from './AdvisorCustomers.module.scss';
import type { AdvisorCustomersProps } from './AdvisorCustomers.props';

export const AdvisorCustomers = (props: AdvisorCustomersProps) => {
  const { t } = useTranslation();
  const { register, watch } = useForm<{ searchPhrase: string }>();
  const appRoutes = useAppRoutes();
  const config = watch();

  const { Table, sortBy, sortDirection } = useTable<ICustomer>();

  const { data, isLoading, isError } = useCustomerGetList({
    sortBy,
    sortDirection,
    perPage: 9999,
    ...config
  });

  const customers = useInfiniteData(data);

  return (
    <div className={styles.AdvisorCustomers}>
      <InputSearch
        {...register('searchPhrase')}
        className={styles.Search}
        placeholder={t('PAGE.ADVISOR_HOME.SEARCH_PLACEHOLDER')}
      />

      <div className={styles.TableWrapper}>
        <Table data={customers} {...props}>
          <Table.Column
            source="name"
            render={({ name, publicId }) => (
              <Anchor to={appRoutes.getAdvisorCustomerDetail(publicId).to}>{name}</Anchor>
            )}
          >
            {t('PAGE.ADVISOR_HOME.COLUMNS.CUSTOMER')}
          </Table.Column>

          <Table.Column source="number">{t('PAGE.ADVISOR_HOME.COLUMNS.NUMBER')}</Table.Column>

          <Table.Column
            source="accountManager.firstName"
            render={({ accountManager }) =>
              `${accountManager?.firstName} ${accountManager?.lastName}`
            }
          >
            {t('PAGE.ADVISOR_HOME.COLUMNS.ACCOUNT_MANAGER')}
          </Table.Column>

          <Table.Column
            source="createdAt"
            render={({ createdAt }) => formatDateTimeIntl(createdAt)}
          >
            {t('PAGE.ADVISOR_HOME.COLUMNS.CREATED_AT')}
          </Table.Column>
        </Table>
      </div>

      <QueryIndicator
        isLoading={isLoading}
        isError={isError}
        modelName={t('DOMAIN.CUSTOMER.PLURAL')}
      />
    </div>
  );
};
