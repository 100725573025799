import { cx } from '@emotion/css';
import { Fragment } from 'react';

import { ProgressBar, Text } from '@Components/ui';

import type { ProgressListProps } from '..';
import styles from './ProgressList.module.scss';

export const ProgressList = ({ className, items, isLarge }: ProgressListProps) => {
  const classNames = cx(styles.ProgressList, className);

  return (
    <div className={classNames}>
      {items?.map(({ label, value }, idx) => (
        <Fragment key={idx}>
          <Text.Small>
            <b>{label}:</b>
          </Text.Small>

          <ProgressBar value={value} max={100} isLarge={isLarge} />
        </Fragment>
      ))}
    </div>
  );
};
