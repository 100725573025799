import { captureException } from '@sentry/react';
import { toast } from 'react-toastify';

import { i18n } from './TranslationUtils';

export const handleUnknownError = (error: unknown) => {
  toast.error(i18n.t('COMMON.GLOBALS.UNKNOWN_ERROR'));

  captureException(error);
};
