import cn from 'classnames';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, SpacingGroup, Statistic } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import type { Dimensions } from '@Services/Room';
import Yup from '@Utils/YupUtils';

import styles from './UpdateRoomForm.module.scss';
import type { UpdateRoomFormProps, UpdateRoomFormValues } from './UpdateRoomForm.props';

export const UpdateRoomForm = ({
  onSubmit,
  className,
  disabled,
  ...props
}: UpdateRoomFormProps) => {
  const classNames = cn(className, styles.UpdateRoomForm);
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ROOM.DETAILS'
  });

  const updateRoomSchema = Yup.object({
    dimensions: Yup.object({
      surface: Yup.number().min(1).required(),
      maxHeight: Yup.number().min(1).required(),
      minHeight: Yup.number().min(1).required()
    }),
    elementsPresent: Yup.object({
      wallAndCeiling: Yup.bool().optional(),
      screens: Yup.bool().optional()
    }).optional()
  });
  const formRef = useRef<FormMethods<UpdateRoomFormValues>>(null);

  const submitHandler = useCallback(
    async (data: UpdateRoomFormValues) => {
      await onSubmit?.(data);
    },
    [onSubmit]
  );

  return (
    <Form<UpdateRoomFormValues>
      {...props}
      className={classNames}
      validationSchema={updateRoomSchema}
      ref={formRef}
      onSubmit={submitHandler}
      onKeyDown={({ key }) => key === 'Enter' && formRef.current?.submit?.()}
    >
      <SpacingGroup className={styles.FormFieldsWrapper}>
        {Object.keys(updateRoomSchema.fields.dimensions.fields).map((field) => (
          <Statistic
            className={styles.StatisticWrapper}
            key={field}
            label={t(field as keyof Dimensions)}
          >
            <Form.Input.Text
              name={`dimensions.${field}`}
              inputProps={{ disabled }}
              isRequired
              className={styles.Input}
            />
          </Statistic>
        ))}
      </SpacingGroup>

      <SpacingGroup className={styles.FormCheckboxWrapper}>
        <Statistic
          className={styles.StatisticWrapper}
          key="wallAndCeiling"
          label={t('wallAndCeiling')}
        >
          <Form.Input.SingleCheckbox
            name="elementsPresent.wallAndCeiling"
            inputProps={{
              option: { value: true, label: t('yes') },
              name: 'elementsPresent.wallAndCeiling'
            }}
            isRequired={false}
            className={styles.Input}
          />
        </Statistic>

        <Statistic className={styles.StatisticWrapper} key="screens" label={t('screens')}>
          <Form.Input.SingleCheckbox
            name="elementsPresent.screens"
            inputProps={{
              option: { value: true, label: t('yes') },
              name: 'elementsPresent.screens'
            }}
            isRequired={false}
            className={styles.Input}
          />
        </Statistic>
      </SpacingGroup>

      <div className={styles.SubmitWrapper}>
        <Button isSubmit type="submit">
          {t('submit')}
        </Button>
      </div>
    </Form>
  );
};
