import { useMutation, useQueryClient } from 'react-query';

import { projectService } from '@Services/Project';
import type { RoomCreateRequest } from '@Services/Room';
import { roomService } from '@Services/Room';

export const useRoomCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: RoomCreateRequest) => {
      return roomService.createRoom(params);
    },
    {
      onSuccess: (_, { project }) => {
        queryClient.invalidateQueries([roomService.getEndpoint()]);
        queryClient.invalidateQueries([projectService.getEndpoint(), project.id]);
      }
    }
  );
};
