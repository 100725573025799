import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { ProductFilters } from '@Components/container/ProductFilters/ProductFilters';
import { Button, Icon, Text } from '@Components/ui';

import styles from './Filter.module.scss';
import type { ProductFilterProps } from './Filter.props';

export const Filter = ({
  filterGroups,
  onChange,
  initialFilters,
  isOpen,
  onClose
}: ProductFilterProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PRODUCT.OVERVIEW'
  });

  return (
    <div
      className={cn(styles.Filter, {
        [styles.isOpen]: isOpen
      })}
    >
      <ProductFilters
        onChange={onChange}
        filterGroups={filterGroups}
        initialFilters={initialFilters}
      >
        <div className={styles.MobileHeader}>
          <Text.H3 className={styles.Label}>{t('FILTERS.TITLE')}</Text.H3>

          <Icon icon={faTimes} size={24} onClick={onClose} />
        </div>
      </ProductFilters>

      <Button className={styles.Submit} onClick={onClose}>
        {t('FILTERS.SUBMIT_BUTTON')}
      </Button>
    </div>
  );
};
