import cn from 'classnames';

import type { IBaseModel } from '@Constants/interfaces';

import { Row } from './internal';
import styles from './Table.module.scss';
import type { TableProps } from './Table.props';

export const Table = <T extends IBaseModel>({
  className,
  children,
  data,
  columns,
  ...props
}: TableProps<T>) => {
  const classNames = cn(styles.Table, className);

  return (
    <table className={classNames} {...props}>
      <thead>
        <tr>{children}</tr>
      </thead>

      <tbody>
        {data.map((row) => {
          return <Row key={row.id} row={row} columns={columns} />;
        })}
      </tbody>
    </table>
  );
};

export { createTable } from './internal';
