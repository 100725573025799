import { faQuoteLeft } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

import { Icon, Rounded } from '@Components/ui';

import styles from './QuoteCard.module.scss';
import type { QuoteCardProps } from './QuoteCard.props';

export const QuoteCard = ({ className, children, author, ...props }: QuoteCardProps) => {
  const classNames = cn(styles.QuoteCard, className);

  return (
    <Rounded className={classNames}>
      <figure>
        <blockquote className={styles.Quote} {...props}>
          <p>{children}</p>
        </blockquote>

        {!!author && (
          <figcaption className={styles.Cite}>
            <cite>{author}</cite>
          </figcaption>
        )}
      </figure>

      <Icon icon={faQuoteLeft} size={122} className={styles.Quotes} />
    </Rounded>
  );
};
