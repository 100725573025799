import { Outlet } from 'react-router-dom';

import { Products } from '@Components/network/Products/Products';

export const CustomerProductScreen = () => {
  return (
    <div>
      <Products />

      <Outlet />
    </div>
  );
};
