import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { AuthRegisterForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { DASHBOARD_LINK } from '@Constants/configs';
import { useAppRoutes } from '@Hooks/Common';
import { isUserAdvisorFromUrl } from '@Utils/RouteUtils';
import { i18n } from '@Utils/TranslationUtils';

export const AuthRegisterScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.REGISTER'
  });
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const appRoutes = useAppRoutes();

  const isAdvisor = isUserAdvisorFromUrl(window.location.href);

  const handleSubmit = (success: boolean) => {
    if (success) {
      if (isAdvisor) return navigate(appRoutes.getRegisterCompleteAdvisor().to);

      return navigate(appRoutes.getRegisterComplete().to);
    }

    if (isAdvisor) return navigate(appRoutes.getLogin().to);

    if (DASHBOARD_LINK) window.location.replace(DASHBOARD_LINK);
    return null;
  };

  const token = params.get('token');
  const email = params.get('email');
  const locale = params.get('locale');

  useEffect(() => {
    if (locale) {
      i18n.changeLanguage(locale);
    }
  }, [locale]);

  if (!token || !email) {
    return <Navigate to={appRoutes.getLogin().to} />;
  }

  return (
    <Modal isClosable={false} title={t('TITLE')}>
      <AuthRegisterForm
        initialValues={{ registrationToken: token, email }}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
