import cn from 'classnames';

import type { TextElement, TextProps } from '@Components/ui';
import { BaseText } from '@Components/ui';

import styles from './H3.module.scss';

const defaultElement = 'h3' as TextElement;
export const H3 = <T extends TextElement = typeof defaultElement>({
  className,
  as = defaultElement as T,
  ...props
}: TextProps<T>) =>
  BaseText<T>({ className: cn(className, styles.H3), as, ...props } as TextProps<T>);
