import cn from 'classnames';

import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './SpacingGroup.module.scss';
import type { SpacingGroupProps } from './SpacingGroup.props';

export const SpacingGroup = ({
  isVertical = false,
  spacing = 2,
  className,
  children,
  ...props
}: SpacingGroupProps) => {
  const spacingStyle = scopedCssVars({ gap: spacing });

  const classNames = cn(spacingStyle, styles.SpacingGroup, className, {
    [styles.isVertical]: isVertical
  });
  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
