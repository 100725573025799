import { yupResolver } from '@hookform/resolvers/yup';
import cn from 'classnames';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from 'react';
import type { FieldValues } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';

import styles from './Form.module.scss';
import type { FormProps, FormRef } from './Form.props';

const FormComponent = function <T extends FieldValues>(
  {
    onSubmit,
    children,
    initialValues,
    disableSubmitOnEnter,
    validationSchema,
    className,
    onChange,
    mode = 'onTouched',
    reValidateMode = 'onBlur',
    ...props
  }: FormProps<T>,
  ref?: FormRef<T>
) {
  const formMethods = useForm({
    defaultValues: initialValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined,
    mode,
    reValidateMode
  });

  const submitHandler = useMemo(() => {
    if (onSubmit) {
      return formMethods.handleSubmit(onSubmit);
    }
  }, [onSubmit, formMethods]);

  const watch = formMethods.watch;

  useEffect(() => {
    if (onChange) {
      const subscription = watch((values) => {
        if (onChange) {
          onChange(values);
        }
      });

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [watch, onChange, submitHandler]);

  useImperativeHandle(ref, () => {
    return {
      submit: submitHandler,
      reset: formMethods.reset,
      watch: formMethods.watch
    };
  });

  const handleKeyPress = useCallback((event: any) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      return false;
    }
  }, []);

  return (
    <FormProvider {...formMethods}>
      <form
        onKeyPress={disableSubmitOnEnter ? handleKeyPress : undefined}
        className={cn(styles.Form, className)}
        onSubmit={onSubmit && submitHandler}
        {...props}
      >
        {children}
      </form>
    </FormProvider>
  );
};

export const Form = forwardRef(FormComponent) as <T extends FieldValues>(
  props: FormProps<T> & { ref?: FormRef<T> }
) => JSX.Element;
