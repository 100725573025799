import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import { useListInputOptions } from '@Hooks/Common';
import { buildingService } from '@Services/Building';
import Yup from '@Utils/YupUtils';

import type { CreateProjectFormProps, CreateProjectFormValues } from './CreateProjectForm.props';

export const CreateProjectForm = ({ onSubmit, ...props }: CreateProjectFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.CREATE_PROJECT'
  });
  const createProjectSchema = Yup.object({
    name: Yup.string().required(),
    building: Yup.object({
      id: Yup.string().required()
    }).required(),
    language: Yup.string().required()
  });
  const buildings = useListInputOptions(buildingService);
  const formRef = useRef<FormMethods<CreateProjectFormValues>>(null);

  const submitHandler = useCallback(
    async (data: CreateProjectFormValues) => {
      await onSubmit?.(data);

      formRef.current?.reset();
    },
    [onSubmit]
  );

  return (
    <Form<CreateProjectFormValues>
      {...props}
      validationSchema={createProjectSchema}
      ref={formRef}
      onSubmit={submitHandler}
    >
      <Form.Input.Text
        inputProps={{ placeholder: t('FIELDS.NAME.PLACEHOLDER') }}
        name="name"
        isRequired
        label={t('FIELDS.NAME.LABEL')}
      />

      <Form.Input.Select
        options={buildings}
        name="building.id"
        isRequired
        label={t('FIELDS.BUILDING.LABEL')}
      />

      <Form.Input.Language
        inputProps={{ placeholder: t('FIELDS.LANGUAGE.PLACEHOLDER') }}
        name="language"
        label={t('FIELDS.LANGUAGE.LABEL')}
      />

      <Button isSubmit>{t('SUBMIT')}</Button>
    </Form>
  );
};
