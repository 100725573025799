import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { useAuthGetMe } from '@Hooks/Auth';

import { AdvisorStack } from './AdvisorStack';
import { PublicStack } from './PublicStack';

export const Router = () => {
  const user = useAuthGetMe();

  return (
    <BrowserRouter>
      <QueryParamProvider adapter={ReactRouter6Adapter}>
        {!!user.data ? <AdvisorStack /> : <PublicStack />}
      </QueryParamProvider>
    </BrowserRouter>
  );
};
