import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import type { RoomMeasurementFormValues } from '@Components/form';
import { RoomMeasurementForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { useRoomAddMeasurement, useRoomGetOne } from '@Hooks/Rooms';

export const AdvisorProjectRoomAddMeasurementScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.ADD_MEASUREMENT'
  });

  const { projectId, roomId } = useParams();
  const { data: room } = useRoomGetOne(roomId);
  const appRoutes = useAppRoutes();

  const navigate = useNavigate();
  const { mutateAsync: addMeasurement } = useRoomAddMeasurement();

  const handleClose = () => {
    navigate(appRoutes.getAdvisorProjectDetail(projectId).to);
  };

  if (!room) return null;

  const handleSubmit = async (data: RoomMeasurementFormValues) => {
    handleClose();

    try {
      const { name } = await addMeasurement(data);

      toast.success(t('SUCCESS_MESSAGE', { name }));
    } catch (error) {
      toast.error(t('ERROR_MESSAGE'));
    }
  };

  const [
    MeasuredValue125Hz,
    MeasuredValue250Hz,
    MeasuredValue500Hz,
    MeasuredValue1KHz,
    MeasuredValue2KHz,
    MeasuredValue4KHz
  ] = room.measurements.current;

  return (
    <Modal
      title={appRoutes.getAdvisorProjectRoomAddMeasurements().label}
      open
      onOpenChange={handleClose}
    >
      <RoomMeasurementForm
        initialValues={{
          id: roomId,
          MeasuredValue125Hz,
          MeasuredValue250Hz,
          MeasuredValue500Hz,
          MeasuredValue1KHz,
          MeasuredValue2KHz,
          MeasuredValue4KHz
        }}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
