import { useInfiniteQuery } from 'react-query';

import { PAGE_SIZE_LG } from '@Constants/configs';
import type { ProjectGetListForCustomerRequest } from '@Services/Project';
import { projectService } from '@Services/Project';

export const useProjectGetListForCustomer = (params: ProjectGetListForCustomerRequest) => {
  const queryParams = {
    ...params,
    perPage: params.perPage ?? PAGE_SIZE_LG
  };

  return useInfiniteQuery(
    [projectService.getEndpoint(), params.customerId, queryParams],
    ({ pageParam = 1 }) => {
      return projectService.getListForCustomer({
        ...queryParams,
        page: pageParam
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page + 1 <= lastPage.totalPages) {
          return lastPage.page + 1;
        }
      }
    }
  );
};
