import { Children, useCallback, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import type SwiperClass from 'swiper/types/swiper-class';

import 'swiper/swiper.min.css';

import { Pagination, SpacingGroup } from '@Components/ui';

import type { CarouselProps } from './Carousel.props';

export const Carousel = ({ children, onSlideChange, onSwiper, ...props }: CarouselProps) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [swiper, setSwiper] = useState<SwiperClass>();

  const handleSlideChange = useCallback(
    (swiperInstance: SwiperClass) => {
      setActiveSlide(swiperInstance.activeIndex);

      if (onSlideChange) {
        onSlideChange(swiperInstance);
      }
    },
    [onSlideChange]
  );

  const handlePaginationClick = useCallback(
    (newActiveIndex: number) => {
      setActiveSlide(newActiveIndex);
      swiper?.slideTo(newActiveIndex);
    },
    [swiper]
  );

  useEffect(() => {
    if (onSwiper && swiper) {
      onSwiper(swiper);
    }
  }, [swiper, onSwiper]);

  const items = Children.toArray(children);

  return (
    <SpacingGroup isVertical spacing={2}>
      <Swiper
        style={{ width: '100%' }}
        spaceBetween={16}
        onSlideChange={handleSlideChange}
        onSwiper={setSwiper}
        {...props}
      >
        {items.map((child, idx) => {
          return <SwiperSlide key={idx}>{child}</SwiperSlide>;
        })}
      </Swiper>

      <Pagination
        activeIndex={activeSlide}
        totalLength={items.length}
        onClick={handlePaginationClick}
      />
    </SpacingGroup>
  );
};
