import cn from 'classnames';
import { useState } from 'react';

import { Modal } from '@Components/layout';

import { Rounded } from '../Rounded/Rounded';
import styles from './CardRow.module.scss';
import type { CardRowProps } from './CardRow.props';

export const CardRow = ({
  className,
  children,
  imageSrc,
  variant,
  isLarge,
  isImageClickable, // TODO: Use larger imageUrl for Modal
  onClick,
  ...props
}: CardRowProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const classNames = cn(styles.CardRow, className, {
    [styles[`is${variant}`]]: !!variant,
    [styles.isLarge]: isLarge
  });

  const handleToggle = () => {
    setIsOpen((wasOpen) => !wasOpen);
  };

  return (
    <Rounded className={classNames} hasShadow onClick={onClick} {...props}>
      <img
        src={imageSrc}
        className={styles.Image}
        onClick={isImageClickable ? handleToggle : undefined}
      />

      {isImageClickable && (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
          <img src={imageSrc} />
        </Modal>
      )}

      {children}
    </Rounded>
  );
};
