import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';

import { Icon } from '@Components/ui/Icon/Icon';
import { Tooltip } from '@Components/ui/Tooltip/Tooltip';

import { Text } from '../Text/Text';
import styles from './InputField.module.scss';
import type { InputFieldProps } from './InputField.props';

export const InputField = ({
  className,
  label,
  children,
  name,
  isRequired,
  description,
  tooltip,
  error,
  ...props
}: InputFieldProps) => {
  const classNames = cn(styles.InputField, className);

  return (
    <div className={classNames} {...props}>
      {(!!label || !!description) && (
        <div className={styles.Header}>
          {!!label && (
            <label htmlFor={name} className={styles.Label}>
              {isRequired ? `${label}*` : label}
            </label>
          )}

          {!!tooltip && (
            <Tooltip description={tooltip} className={styles.Tooltip}>
              <Icon hasCircle size={12} icon={faInfoCircle} />
            </Tooltip>
          )}
        </div>
      )}

      {children}

      {!!description && <Text.Normal className={styles.Description}>{description}</Text.Normal>}

      {error && <Text.Error className={styles.Error}>{error}</Text.Error>}
    </div>
  );
};
