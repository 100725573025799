import { getTheme } from '@Utils/ThemeUtils';

export const getProgressBarColor = (progress: number, max = 1) => {
  const { color } = getTheme();

  switch (true) {
    case progress > max || max < 0:
      return color.primary.default;
    case progress / max >= 0.74:
      return color.progress.default;
    case progress / max >= 0.65:
      return color.secondary.default;
    default:
      return color.red.default;
  }
};
