import type { IBaseModel, ListRequest, ListResponse, OneRequest } from '@Constants/interfaces';
import { BaseService } from '@Services/Common';

export class ResourceService<T extends IBaseModel> extends BaseService {
  getList = async ({ sortDirection, sortBy, searchPhrase, ...params }: ListRequest = {}) => {
    const { data } = await this.get<ListResponse<T>>('/', {
      sortDirection: sortDirection || undefined,
      sortBy: sortBy || undefined,
      searchPhrase: searchPhrase || undefined,
      ...params
    });

    return data;
  };

  getOne = async ({ id }: OneRequest) => {
    const { data } = await this.get<T>(`/${id}`);

    return data;
  };
}
