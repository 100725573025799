import { ContactCardRow } from '@Components/ui';

import type { ContactCardProps } from './ContactCard.props';

export const ContactCard = ({ contact }: ContactCardProps) => {
  const { email, firstName, phoneNumber, lastName, image } = contact;

  return (
    <ContactCardRow
      email={email}
      name={`${firstName} ${lastName}`}
      phone={phoneNumber}
      image={image?.path}
    />
  );
};
