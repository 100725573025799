import { CustomerProjectCard } from '@Components/container';
import { GridList } from '@Components/layout';

import type { CustomerProjectCardListProps } from './CustomerProjectCardList.props';

export const CustomerProjectCardList = ({
  className,
  children,
  projects,
  ...props
}: CustomerProjectCardListProps) => {
  return (
    <GridList className={className} {...props}>
      {projects?.map((project) => {
        return <CustomerProjectCard key={project.id} project={project} />;
      })}
    </GridList>
  );
};
