import { useState } from 'react';

import { createTable } from '@Components/ui';
import { SortDirection } from '@Constants/enums';
import type { IBaseModel } from '@Constants/interfaces';

export const useTable = <T extends IBaseModel>() => {
  const [sortBy, setSortBy] = useState<any>('');
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.INTERMEDIATE);
  const context = { sortBy, setSortBy, sortDirection, setSortDirection };
  const Table = createTable<T>(context);

  return { Table, ...context };
};
