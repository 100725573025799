import { useTranslation } from 'react-i18next';

import { BlogCard } from '@Components/container';
import { Carousel, SpacingGroup, Text } from '@Components/ui';

import type { ProjectBlogProps } from './ProjectBlog.props';

export const ProjectBlog = ({ blogs }: ProjectBlogProps) => {
  const { t } = useTranslation();

  return (
    <SpacingGroup isVertical>
      <Text.H3 color="primary">{t('COMPONENTS.PROJECT_BLOG.TITLE')}</Text.H3>

      <Carousel>
        {blogs.map((blog, idx) => {
          return <BlogCard key={idx} blog={blog} />;
        })}
      </Carousel>
    </SpacingGroup>
  );
};
