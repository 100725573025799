import type { NavigateProps } from 'react-router-dom';
import { Navigate, useParams } from 'react-router-dom';

import type { HintedString } from '@Constants/types';
import { useAppRoutes } from '@Hooks/Common';
import type locales from '@Locales/index';

import { i18n } from './TranslationUtils';

export type TranslatedSlugs = keyof typeof locales.nl.translation.COMMON.NAVIGATION;

export type NavSlugs = HintedString<TranslatedSlugs>;

export const translateSlug = (slug: NavSlugs) => i18n.t(`COMMON.NAVIGATION.${slug}.SLUG`, slug);

export const getLink = (...urlPaths: (NavSlugs | number | undefined)[]) => {
  const urlParts = urlPaths.flatMap((urlPart) => String(urlPart ?? '').split('/'));

  const translatedUrlParts = urlParts.map(translateSlug).filter(Boolean);
  return `/${translatedUrlParts.join('/')}`;
};

export const getLinkLabel = (slug: TranslatedSlugs) => i18n.t(`COMMON.NAVIGATION.${slug}.LABEL`);

export interface RedirectProps extends Omit<NavigateProps, 'to'> {
  home: Lowercase<keyof typeof HomeRoutes>;
}

export enum HomeRoutes {
  ADVISOR = 'ADVISOR',
  CUSTOMER = 'CUSTOMER',
  LOGIN = 'LOGIN'
}

export const Redirect = ({ home, ...props }: RedirectProps) => {
  const { customerId } = useParams();
  const appRoutes = useAppRoutes();

  const HomeRouteMethods = {
    advisor: appRoutes.getAdvisorHome().to,
    customer: appRoutes.getCustomerHome(customerId).to,
    login: appRoutes.getLogin().to
  };

  const to = HomeRouteMethods[home];

  return <Navigate to={to} {...props} />;
};

export const isUserAdvisorFromUrl = (url: string) => {
  if (url.includes('&role=advisor')) return true;
  return false;
};
