import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export const labelFromIconProp = (
  accessibilityLabel: string | undefined,
  iconProp: IconDefinition,
  suffix: string
) => {
  if (accessibilityLabel) {
    return accessibilityLabel;
  }

  if (typeof iconProp === 'object') {
    const { iconName } = iconProp as { iconName: string };

    return `${iconName} ${suffix}`;
  }

  return `${iconProp} ${suffix}`;
};
