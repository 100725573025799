import cn from 'classnames';

import type { TextElement, TextProps } from '@Components/ui';
import { BaseText } from '@Components/ui';

import styles from './H2.module.scss';

const defaultElement = 'h2' as TextElement;
export const H2 = <T extends TextElement = typeof defaultElement>({
  className,
  as = defaultElement as T,
  ...props
}: TextProps<T>) =>
  BaseText<T>({ className: cn(className, styles.H2), as, ...props } as TextProps<T>);
