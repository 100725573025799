import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { CreateCustomerForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { useCustomerCreate } from '@Hooks/Customer';
import type { CustomerCreateRequest } from '@Services/Customer';

export const AdvisorCreateCustomerScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.CUSTOMER.FORM.CREATE_UPDATE_CUSTOMER'
  });
  const appRoutes = useAppRoutes();
  const handleClose = useNavigate();
  const { mutateAsync: createCustomer } = useCustomerCreate();

  const handleSubmit = async (data: CustomerCreateRequest) => {
    try {
      const { name, id } = await createCustomer(data);

      toast.success(t('SUCCESS_CREATE_MESSAGE', { name }), {
        onClick: () => handleClose(appRoutes.getAdvisorCustomerDetail(id).to)
      });

      handleClose(appRoutes.getAdvisorHome().to);
    } catch (error) {
      toast.error(t('ERROR_MESSAGE'));
    }
  };

  return (
    <Modal
      title={appRoutes.getAdvisorCustomersCreate().label}
      open
      onOpenChange={(open) => !open && handleClose(appRoutes.getAdvisorHome().to)}
    >
      <CreateCustomerForm onSubmit={handleSubmit} />
    </Modal>
  );
};
