import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { Children } from 'react';

import { Icon, SpacingGroup } from '@Components/ui';

import styles from './ListChecked.module.scss';
import type { ListCheckedProps } from './ListChecked.props';

export const ListChecked = ({ className, children, ...props }: ListCheckedProps) => {
  const classNames = cn(styles.ListChecked, className);

  return (
    <ul className={classNames} {...props}>
      {Children.map(children, (text, idx) => {
        return (
          <li key={idx}>
            <SpacingGroup spacing={1.5}>
              <Icon icon={faCheck} color="secondary" isCentered />

              {text}
            </SpacingGroup>
          </li>
        );
      })}
    </ul>
  );
};
