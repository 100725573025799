import { useMutation, useQueryClient } from 'react-query';

import type { ProjectCreateRequest } from '@Services/Project';
import { projectService } from '@Services/Project';

export const useProjectCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: ProjectCreateRequest) => {
      return projectService.createProject(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([projectService.getEndpoint()]);
      }
    }
  );
};
