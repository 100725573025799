import { useInfiniteQuery } from 'react-query';

import { PAGE_SIZE_LG } from '@Constants/configs';
import type { ProjectGetListRequest } from '@Services/Project';
import { projectService } from '@Services/Project';

export const useProjectGetList = (params?: ProjectGetListRequest) => {
  const queryParams: ProjectGetListRequest = {
    perPage: PAGE_SIZE_LG,
    ...params
  };

  return useInfiniteQuery(
    [projectService.getEndpoint(), queryParams],
    ({ pageParam = 1 }) => {
      return projectService.getList({
        ...queryParams,
        page: pageParam
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page + 1 <= lastPage.totalPages) {
          return lastPage.page + 1;
        }
      }
    }
  );
};
