import { Card, SpacingGroup, Text } from '@Components/ui';

import type { BlogCardProps } from './BlogCard.props';

export const BlogCard = ({ blog }: BlogCardProps) => {
  return (
    <Card isRow>
      <Card.Image src={blog.image?.path} />

      <Card.Main>
        <SpacingGroup isVertical>
          <Text.H4>{blog.name}</Text.H4>

          <Text.Paragraph>{blog.description}</Text.Paragraph>
        </SpacingGroup>
      </Card.Main>
    </Card>
  );
};
