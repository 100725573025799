import cn from 'classnames';

import type { TextElement, TextProps } from '@Components/ui';
import { BaseText } from '@Components/ui';

import styles from './H4.module.scss';

const defaultElement = 'h4' as TextElement;
export const H4 = <T extends TextElement = typeof defaultElement>({
  className,
  as = defaultElement as T,
  ...props
}: TextProps<T>) =>
  BaseText<T>({ className: cn(className, styles.H4), as, ...props } as TextProps<T>);
