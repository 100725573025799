import { QuoteCard } from '@Components/ui';

import type { ProjectQuoteCardProps } from './ProjectQuoteCard.props';

export const ProjectQuoteCard = ({ project }: ProjectQuoteCardProps) => {
  if (project.testimonial) {
    return (
      <QuoteCard author={project.testimonial?.author}>{project.testimonial?.description}</QuoteCard>
    );
  }

  return null;
};
