import { useQuery } from 'react-query';

import type { UseQueryConfig } from '@Constants/types';
import type { CustomerGetOnePublicRequest, ICustomer } from '@Services/Customer';
import { customerService } from '@Services/Customer';

export const useCustomerGetOnePublic = (
  { publicId }: CustomerGetOnePublicRequest,
  options?: UseQueryConfig<ICustomer>
) => {
  return useQuery(
    [customerService.getEndpoint(), 'public', publicId],
    () => {
      return customerService.getOnePublic({ publicId });
    },
    options
  );
};
