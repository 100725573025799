import { useMutation, useQueryClient } from 'react-query';

import type { RoomDeleteSolutionRequest } from '@Services/Room';
import { roomService } from '@Services/Room';
import { handleUnknownError } from '@Utils/ErrorUtils';

export const useRoomDeleteSolution = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: RoomDeleteSolutionRequest) => {
      return roomService.deleteSolution(params);
    },
    {
      onError: handleUnknownError,
      onSuccess: () => {
        queryClient.invalidateQueries(roomService.getEndpoint());
      }
    }
  );
};
