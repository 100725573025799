import { ProjectCard } from '@Components/container';
import { GridList } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { isAdvisor } from '@Utils/AuthUtils';

import type { ProjectListProps } from './ProjectList.props';

export const ProjectList = ({ projects, children, ...props }: ProjectListProps) => {
  const appRoutes = useAppRoutes();

  return (
    <GridList {...props}>
      {projects?.map((project) => (
        <ProjectCard
          project={project}
          to={
            isAdvisor()
              ? appRoutes.getAdvisorProjectDetail(project.id).to
              : appRoutes.getCustomerProjectDetail(project.id).to
          }
          key={project.id}
        />
      )) || children}
    </GridList>
  );
};
