import { useInfiniteQuery } from 'react-query';

import { PAGE_SIZE_MD } from '@Constants/configs';
import type { ListRequest } from '@Constants/interfaces';
import { customerService } from '@Services/Customer';

export const useCustomerGetList = (params?: ListRequest) => {
  const parameters = {
    ...params,
    perPage: params?.perPage ?? PAGE_SIZE_MD
  };

  return useInfiniteQuery(
    [customerService.getEndpoint(), parameters],
    ({ pageParam = 1 }) => {
      return customerService.getList({
        ...parameters,
        page: pageParam
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page + 1 <= lastPage.totalPages) {
          return lastPage.page + 1;
        }
      }
    }
  );
};
