import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField, InputText } from '@Components/ui';
import { useDebounce } from '@Hooks/Common';
import type { ConfigurationOption, ConfigurationSuboption } from '@Services/Solution';
import Yup from '@Utils/YupUtils';

import styles from './SolutionConfiguratorNumberOption.module.scss';

interface SolutionConfiguratorNumberOptionProps {
  option: ConfigurationOption;
  onChange: ({ value, optionId }: { value: number; optionId: string }) => void;
  isLoading?: boolean;
}

interface NumberInputFieldProps {
  option: ConfigurationSuboption;
  onChange: SolutionConfiguratorNumberOptionProps['onChange'];
  isLoading?: boolean;
}

const NumberInputField = ({ option, onChange, isLoading }: NumberInputFieldProps) => {
  const [error, setError] = useState('');
  const { debounce } = useDebounce(500);
  const [internalValue, setInternalValue] = useState(option.value);

  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.ROOM.SOLUTIONS.ADD_MODAL'
  });

  useEffect(() => {
    setInternalValue(option.value);
  }, [option.value]);

  const getValidationSchema = () => {
    let schema = Yup.number().required();

    if (option.min) {
      schema = schema.min(option.min);
    }

    if (option.max) {
      schema = schema.max(option.max);
    }

    return schema;
  };

  const getDescription = () => {
    if (option.min && option.max) {
      return t('MINMAX_VALUE', {
        minValue: option.min,
        maxValue: option.max
      });
    }

    if (option.min) {
      return t('MIN_VALUE', { minValue: option.min });
    }

    if (option.max) {
      return t('MAX_VALUE', { maxValue: option.max });
    }
  };

  const handleValueChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setError('');
    setInternalValue(Number(inputValue));

    debounce(async () => {
      try {
        const value = await getValidationSchema().validate(event.target.value);

        onChange({ value, optionId: option.id });
      } catch (err: any) {
        setError(err.message);
      }
    });
  };

  return (
    <InputField
      error={error}
      description={getDescription()}
      key={option.id}
      label={option.text}
      style={{ maxWidth: '47%', marginBottom: 0 }}
    >
      <InputText
        disabled={isLoading}
        value={internalValue}
        onChange={handleValueChange}
        isError={!!error}
        type="number"
      />
    </InputField>
  );
};

export const SolutionConfiguratorNumberOption = ({
  option,
  onChange,
  isLoading
}: SolutionConfiguratorNumberOptionProps) => {
  return (
    <InputField
      className={styles.SolutionConfiguratorNumberOption}
      label={option.name}
      key={option.id}
    >
      <div className={styles.NumberInput}>
        {option.options?.map((subOption) => {
          return (
            <NumberInputField
              isLoading={isLoading}
              key={option.id}
              onChange={onChange}
              option={subOption}
            />
          );
        })}
      </div>
    </InputField>
  );
};
