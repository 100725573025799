import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Hero } from '@Components/layout';
import { Chip, EditableTitle, Icon, SpacingGroup, Text } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';
import { formatDateIntl } from '@Utils/DateUtils';

import styles from './ProjectHero.module.scss';
import type { ProjectHeroProps } from './ProjectHero.props';

export const ProjectHero = ({ className, project, ...props }: ProjectHeroProps) => {
  const classNames = cn(styles.ProjectHero, className);
  const { t } = useTranslation();
  const appRoutes = useAppRoutes();

  return (
    <Hero className={classNames} src={project.building?.image ?? ''}>
      <SpacingGroup spacing={1} className={styles.TitleSpacingGroup}>
        <EditableTitle
          key={project.name}
          {...props}
          title={project.name}
          shareUrl={`${window.location.protocol}//${window.location.hostname}${
            appRoutes.getCustomerProjectDetail(project.customer.publicId, project.id).to
          }`}
        />
      </SpacingGroup>

      <SpacingGroup spacing={3}>
        <Chip>
          <Text.H4 className={project.building ? '' : styles.Italic}>
            {project.building?.name ?? t('COMPONENTS.PROJECT_HERO.PLACEHOLDERS.NO_BUILDING_TYPE')}
          </Text.H4>
        </Chip>

        <SpacingGroup spacing={1} className={styles.CalendarSpacingGroup}>
          <Icon color="white" size={20} icon={faCalendar} />

          <Text.Normal color="white">{formatDateIntl(project.createdAt)}</Text.Normal>
        </SpacingGroup>
      </SpacingGroup>
    </Hero>
  );
};
