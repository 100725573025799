import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, Text } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import { storageService } from '@Services/Storage';
import Yup from '@Utils/YupUtils';

import styles from './UpdateProjectQuotationForm.module.scss';
import type {
  UpdateProjectQuotationFormProps,
  UpdateProjectQuotationFormValues
} from './UpdateProjectQuotationForm.props';

export const UpdateProjectQuotationForm = ({
  onSubmit,
  ...props
}: UpdateProjectQuotationFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.QUOTATION'
  });
  const updateProjectQuotationSchema = Yup.object({
    quotation: Yup.string().url()
  });
  const formRef = useRef<FormMethods<UpdateProjectQuotationFormValues>>(null);

  const submitHandler = useCallback(
    async (data: UpdateProjectQuotationFormValues) => {
      const params = { ...data };

      if (data.file) {
        const response = await storageService.upload(data.file);

        if (response && response[0].id) params.quotation = response[0].path;
      }

      await onSubmit?.(params);

      formRef.current?.reset();
    },
    [onSubmit]
  );

  return (
    <Form<UpdateProjectQuotationFormValues>
      {...props}
      validationSchema={updateProjectQuotationSchema}
      ref={formRef}
      onSubmit={submitHandler}
    >
      <Form.Input.Text
        inputProps={{ placeholder: t('FIELDS.URL.PLACEHOLDER'), type: 'url' }}
        name="quotation"
        label={t('FIELDS.URL.LABEL')}
      />

      <Form.Input.File name="file" label={t('FIELDS.UPLOAD.LABEL')} />

      <Text.H4 className={styles.Warning}>{t('WARNING')}</Text.H4>

      <Button isSubmit>{t('SUBMIT')}</Button>
    </Form>
  );
};
