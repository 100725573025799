import { Footer } from '@Components/layout';
import { Text } from '@Components/ui';
import { useAuthGetMe } from '@Hooks/Auth';

import type { AdvisorFooterProps } from './AdvisorFooter.props';

export const AdvisorFooter = ({ className }: AdvisorFooterProps) => {
  const { data } = useAuthGetMe();

  if (!data) return null;

  const { phoneNumber, name, street, houseNumber, postalCode, city } = data?.company ?? {};

  return (
    <Footer className={className} title={name}>
      <Text.Small>{`${phoneNumber} | ${street} ${houseNumber}, ${postalCode} ${city}`}</Text.Small>
    </Footer>
  );
};
