import cn from 'classnames';

import styles from './Rounded.module.scss';
import type { RoundedProps } from './Rounded.props';

export const Rounded = ({ className, children, hasShadow = true, ...props }: RoundedProps) => {
  const classNames = cn(className, styles.Rounded, {
    [styles.hasShadow]: hasShadow
  });

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
