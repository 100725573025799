import cn from 'classnames';
import { Container } from 'react-bootstrap';

import { SpacingGroup } from '@Components/ui';

import styles from './Hero.module.scss';
import type { HeroProps } from './Hero.props';

export const Hero = ({ className, src, children, ...props }: HeroProps) => {
  const classNames = cn(styles.Hero, className);

  return (
    <header className={classNames} {...props}>
      {!!src && <img className={styles.Background} src={src} />}

      <Container className={styles.Container}>
        <SpacingGroup isVertical spacing={1}>
          {children}
        </SpacingGroup>
      </Container>
    </header>
  );
};
