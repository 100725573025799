import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button/Button';
import styles from './InputImageSmall.module.scss';
import type { InputImageSmallProps } from './InputImageSmall.props';

export const InputImageSmall = ({
  className,
  children,
  options,
  value,
  isLoading,
  ...props
}: InputImageSmallProps) => {
  const classNames = cn(styles.InputImageSmall, className, {
    [styles.isLoading]: isLoading
  });

  const { t } = useTranslation();

  return (
    <>
      <p className={styles.Chosen}>
        {`${t('COMPONENTS.PRODUCT_CONFIGURATOR.CONFIGURATION.chosen')}: 
        ${options.find((option) => option.value === value)?.label}`}
      </p>

      <RadioGroup.Root disabled={isLoading} {...props} className={classNames} value={value}>
        {options.map((option) => {
          const optionValue = String(option.value);
          const isSelected = value === optionValue;

          return (
            <RadioGroup.Item value={optionValue} key={option.value}>
              <Button isOutline={!isSelected} className={styles.Button}>
                {option.url ? (
                  <img className={styles.Image} src={option.url} alt={option.label} />
                ) : (
                  <span>{option.label}</span>
                )}
              </Button>
            </RadioGroup.Item>
          );
        })}
      </RadioGroup.Root>
    </>
  );
};
