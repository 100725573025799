import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Modal } from '@Components/layout';
import { Button, SpacingGroup } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';

export const AuthRegisterCompleteAdvisorScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.REGISTER.SUCCESS'
  });
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();

  const handleClick = () => {
    navigate(appRoutes.getLogin().to);
    return null;
  };

  return (
    <Modal isClosable={false} title={t('TITLE')}>
      <SpacingGroup spacing={2}>
        <Button onClick={handleClick}>{t('BUTTON')}</Button>
      </SpacingGroup>
    </Modal>
  );
};
