import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@Components/ui';

import styles from './ResetFiltersButton.module.scss';

export const ResetFiltersButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PRODUCT.OVERVIEW'
  });

  const { reset } = useFormContext();

  return (
    <button
      className={styles.ResetFiltersButton}
      onClick={() =>
        reset({
          type: [],
          material: [],
          solutions: [],
          appliances: []
        })
      }
    >
      <Icon className={styles.Icon} icon={faTimes} />

      <Text.Normal className={styles.Label}>{t('FILTERS.RESET')}</Text.Normal>
    </button>
  );
};
