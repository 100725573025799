import { Video } from '@Components/ui';

import type { ProjectVideoProps } from './ProjectVideo.props';

export const ProjectVideo = ({ videoId }: ProjectVideoProps) => {
  return (
    <Video
      thumbnailSrc={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
      href={`https://youtu.be/${videoId}`}
    />
  );
};
