import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { InputSelect } from '@Components/ui';
import { getLanguageOptions } from '@Utils/FormUtils';

import { FormField } from '../FormField/FormField';
import type { FormLanguageSelectProps } from './FormLanguageSelect.props';

export const FormLanguageSelect = ({ inputProps, className, ...form }: FormLanguageSelectProps) => {
  const { i18n } = useTranslation();

  const options = getLanguageOptions(i18n.language);

  return (
    <FormField {...form} hasController className={className}>
      <Controller
        name={form.name}
        render={({ field }) => {
          return <InputSelect {...inputProps} {...field} options={options} />;
        }}
      />
    </FormField>
  );
};
