import { Col, Row } from 'react-bootstrap';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import {
  ContactCard,
  ProjectBlog,
  ProjectContact,
  ProjectQuoteCard,
  ProjectUspsWhitepaperCard,
  ProjectVideo
} from '@Components/container';
import { AddQuotationButton } from '@Components/container/AddQuotationButton/AddQuotationButton';
import { AdvisorProjectHero, Notes, ProjectRooms } from '@Components/network';
import { SpacingGroup } from '@Components/ui';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import { useProjectGetOne } from '@Hooks/Project';

import styles from './AdvisorProjectDetailScreen.module.scss';

export const AdvisorProjectDetailScreen = () => {
  const { projectId } = useParams();
  const { data: project } = useProjectGetOne(projectId);
  const { data: currentAdvisor } = useAuthGetMe();
  const navigate = useNavigate();
  const appRoutes = useAppRoutes();

  if (!project) return null;

  if (currentAdvisor && project.advisor.id !== currentAdvisor?.id) {
    navigate(appRoutes.getCustomerProjectDetail(project.customer.publicId, project.id).to, {
      replace: true
    });
    return null;
  }

  return (
    <div>
      <AdvisorProjectHero project={project} />

      <Row>
        <Col lg={8}>
          <SpacingGroup isVertical spacing={6}>
            <Notes project={project} />

            <ProjectContact project={project} />

            <ProjectRooms project={project} />
          </SpacingGroup>
        </Col>

        <Col lg={4} className={styles.MobileGap}>
          <SpacingGroup isVertical spacing={3}>
            {!!projectId && <AddQuotationButton projectId={projectId} />}

            <ContactCard contact={project.advisor} />

            <ProjectUspsWhitepaperCard project={project} />

            <ProjectQuoteCard project={project} />

            {project.blogs && <ProjectBlog blogs={project.blogs} />}

            {project.videoUrl && <ProjectVideo videoId={project.videoUrl} />}
          </SpacingGroup>
        </Col>
      </Row>

      <Outlet />
    </div>
  );
};
