import { useMutation, useQueryClient } from 'react-query';

import { roomService } from '@Services/Room';
import { type SolutionConfigureRequest, solutionService } from '@Services/Solution';
import { handleUnknownError } from '@Utils/ErrorUtils';

export const useSolutionConfigure = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: SolutionConfigureRequest) => {
      return solutionService.configure(params);
    },
    {
      onError: handleUnknownError,
      onSuccess: () => {
        queryClient.invalidateQueries([roomService.getEndpoint()]);
      }
    }
  );
};
