import { useTranslation } from 'react-i18next';

import { Text } from '@Components/ui';

import { CustomerProjectsArchive } from '../CustomerProjectsArchive/CustomerProjectsArchive';
import styles from './CustomerProjectsPreview.module.scss';
import type { CustomerProjectsPreviewProps } from './CustomerProjectsPreview.props';

export const CustomerProjectsPreview = (props: CustomerProjectsPreviewProps) => {
  const { t } = useTranslation();

  return (
    <div className={styles.CustomerProjectsPreview} {...props}>
      <Text.H2 className={styles.Title}>{t('DOMAIN.CUSTOMER.HOME_PAGE.PROJECTS.TITLE')}</Text.H2>

      <CustomerProjectsArchive />
    </div>
  );
};
