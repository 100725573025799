import cn from 'classnames';
import { Container } from 'react-bootstrap';

import { Navbar } from '@Components/layout';
import { AdvisorFooter, CustomerFooter } from '@Components/network';
import { isAdvisor } from '@Utils/AuthUtils';

import styles from './Page.module.scss';
import type { PageProps } from './Page.props';

export const Page = ({
  className,
  children,
  links,
  icons,
  homeUrl,
  logoSrc,
  ...props
}: PageProps) => {
  const classNames = cn(styles.Page, className);

  return (
    <div className={classNames} {...props}>
      <Navbar links={links} icons={icons} homeUrl={homeUrl} logoSrc={logoSrc} />

      <main className={styles.Body}>
        <Container>{children}</Container>
      </main>

      {isAdvisor() ? (
        <AdvisorFooter className={styles.Footer} />
      ) : (
        <CustomerFooter className={styles.Footer} />
      )}
    </div>
  );
};
