import { useMutation } from 'react-query';

import type { AuthUpdatePasswordRequest } from '@Services/AuthService';
import { authService } from '@Services/AuthService';

export const useAuthUpdatePassword = () => {
  return useMutation((request: AuthUpdatePasswordRequest) => {
    return authService.updatePassword(request);
  });
};
