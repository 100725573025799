import type { ReactElement } from 'react';

import type { BaseTableProps, TableContextData } from '@Components/ui';
import { Table as BaseTable } from '@Components/ui';
import type { IBaseModel } from '@Constants/interfaces';
import { getSlots } from '@Utils/SlotUtils';

import type { BaseColumnProps, ColumnProps } from '..';
import { Column as BaseColumn } from '..';

export const createTable = <T extends IBaseModel>(context: TableContextData<T>) => {
  const Column = (props: BaseColumnProps<T>) =>
    BaseColumn<T>({ ...context, ...props } as ColumnProps<T>);

  const Table = ({ children, ...props }: BaseTableProps<T>) => {
    const { slots } = getSlots(children, Column);

    const columnElements = [...(slots.get(Column) || [])];

    const columns = columnElements.map(
      ({ props: { source, render } }: ReactElement<ColumnProps<T>>) => ({ source, render })
    );

    return BaseTable<T>({ children: columnElements, columns, ...props });
  };

  return Object.assign(Table, {
    Column,
    Context: context
  });
};
