import { faLocationDot, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faPen } from '@fortawesome/pro-solid-svg-icons';

import { ButtonIcon, Icon, Rounded, SpacingGroup, Text } from '@Components/ui';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import { isAdvisor } from '@Utils/AuthUtils';

import styles from './ContactCard.module.scss';
import type { ContactCardProps } from './ContactCard.props';

export const ContactCard = ({
  advisorId,
  title,
  address,
  contactPerson,
  phone,
  email,
  projectId
}: ContactCardProps) => {
  const { data: currentAdvisor } = useAuthGetMe();
  const isCurrentAdvisor = isAdvisor() && !!currentAdvisor && advisorId === currentAdvisor?.id;
  const appRoutes = useAppRoutes();

  return (
    <Rounded className={styles.ContactCard}>
      <div className={styles.TitleEdit}>
        <Text.H2 color="primary">{title}</Text.H2>

        {isCurrentAdvisor && (
          <ButtonIcon
            icon={faPen}
            backgroundColor="gray-light"
            color="primary"
            to={appRoutes.getAdvisorProjectUpdate(projectId).to}
          />
        )}
      </div>

      <div className={styles.Item}>
        <Icon icon={faLocationDot} color="primary" />

        <Text.Paragraph>{address}</Text.Paragraph>
      </div>

      <div className={styles.BreakingLine} />

      <SpacingGroup className={styles.MobileGroup} spacing={6}>
        <div className={styles.Item}>
          <Icon icon={faUser} color="primary" />

          <Text.Paragraph>{contactPerson}</Text.Paragraph>
        </div>

        <div className={styles.Item}>
          <Text.Paragraph>{phone}</Text.Paragraph>
        </div>

        <div className={styles.Item}>
          <Text.Paragraph>{email}</Text.Paragraph>
        </div>
      </SpacingGroup>
    </Rounded>
  );
};
