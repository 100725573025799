import { SolutionConfigType } from '@Constants/enums';

import {
  SolutionConfiguratorCheckboxOption,
  SolutionConfiguratorColorOption,
  SolutionConfiguratorFileOption,
  SolutionConfiguratorNumberOption,
  SolutionConfiguratorQuantity,
  SolutionConfiguratorRadioOption,
  SolutionConfiguratorRadioWithImageSmallOption,
  SolutionConfiguratorSelectOption,
  SolutionConfiguratorTextArea
} from './internal/components';
import type { SolutionConfiguratorProps } from './SolutionConfigurator.props';

export const SolutionConfigurator = ({
  className,
  children,
  solution,
  isLoading,
  onChange,
  onQuantityChange,
  onOverrideQuantityChange,
  setQuantityError,
  ...props
}: SolutionConfiguratorProps) => {
  return (
    <div className={className} {...props}>
      {solution.options.map((option) => {
        switch (option.type) {
          case SolutionConfigType.CHECKBOX: {
            return (
              <SolutionConfiguratorCheckboxOption
                isLoading={isLoading}
                onChange={(values, selected) => {
                  onChange({
                    optionId: values,
                    type: option.type,
                    selected
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.COLORPICKER: {
            return (
              <SolutionConfiguratorColorOption
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: option.id,
                    value: value,
                    type: option.type
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.DROPDOWN: {
            return (
              <SolutionConfiguratorSelectOption
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: value,
                    type: option.type
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.FILE: {
            return (
              <SolutionConfiguratorFileOption
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: value,
                    type: option.type
                  });
                }}
                onFileChange={(value) => {
                  onChange({
                    optionId: option.options?.[0]?.id ?? '',
                    type: option.type,
                    imageId: value
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.NUMBER: {
            return (
              <SolutionConfiguratorNumberOption
                onChange={({ optionId, value }) => {
                  onChange({
                    type: option.type,
                    optionId,
                    value
                  });
                }}
                key={option.id}
                option={option}
                isLoading={isLoading}
              />
            );
          }

          case SolutionConfigType.RADIO: {
            return (
              <SolutionConfiguratorRadioOption
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: value,
                    type: option.type
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.RADIOCOLOR: {
            return (
              <SolutionConfiguratorRadioWithImageSmallOption
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: value,
                    type: option.type
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.RADIOWITHIMAGE: {
            return (
              <SolutionConfiguratorRadioWithImageSmallOption
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: value,
                    type: option.type
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.RADIOWITHIMAGESMALL: {
            return (
              <SolutionConfiguratorRadioWithImageSmallOption
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: value,
                    type: option.type
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          case SolutionConfigType.TEXTAREA: {
            return (
              <SolutionConfiguratorTextArea
                isLoading={isLoading}
                onChange={(value) => {
                  onChange({
                    optionId: option.id,
                    value,
                    type: option.type
                  });
                }}
                option={option}
                key={option.id}
              />
            );
          }

          default: {
            return <p key={option.id}>{`Please add ${option.type} option`}</p>;
          }
        }
      })}

      <SolutionConfiguratorQuantity
        onChange={onQuantityChange}
        setQuantityError={setQuantityError}
        onOverrideQuantityChange={onOverrideQuantityChange}
        quantity={solution.quantity}
        value={solution.quantity.current}
      />
    </div>
  );
};
