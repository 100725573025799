import type { ReactNode } from 'react';
import { Children, isValidElement } from 'react';

export interface SlotProps {
  children?: ReactNode | ReactNode[];
}

export const createSlot = () => {
  const Slot = ({ children }: SlotProps) => {
    return <>{children}</>;
  };

  return Slot;
};

export const getSlots = (children: ReactNode | ReactNode[], ...slots: ReactNode[]) => {
  const slotMap = new Map();

  const contents = Children.map(children, (child) => {
    if (isValidElement(child) && slots.includes(child.type)) {
      const slot = slotMap.get(child.type) ?? [];
      slotMap.set(child.type, [...slot, child]);
      return null;
    }

    return child;
  });

  return {
    contents,
    slots: slotMap
  };
};
