import { Controller } from 'react-hook-form';

import { InputFile } from '@Components/ui';

import { FormField } from '../FormField/FormField';
import type { FormFileProps } from './FormFile.props';

export const FormFile = ({ options, className, ...form }: FormFileProps) => {
  return (
    <FormField {...form} className={className} hasController>
      <Controller
        name={form.name}
        render={({ field }) => {
          return <InputFile {...field} />;
        }}
      />
    </FormField>
  );
};
