import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import { Anchor, Card, Icon, Text } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';
import { formatDateIntl } from '@Utils/DateUtils';

import styles from './ProjectCard.module.scss';
import type { ProjectCardProps } from './ProjectCard.props';

export const ProjectCard = ({ className, project, to, ...props }: ProjectCardProps) => {
  const classNames = cn(styles.ProjectCard, className);
  const { t } = useTranslation();
  const appRoutes = useAppRoutes();
  const { building, name, createdAt, id } = project;

  const formattedDate = formatDateIntl(createdAt);

  return (
    <Card className={classNames} {...(props as any)} to={to}>
      <Card.Image src={building?.image} ratio={350 / 200} />

      <Card.Main className={styles.Main}>
        <Text.H1 color="primary" className={styles.Title}>
          {name}
        </Text.H1>

        {!!formattedDate && (
          <time dateTime={createdAt} className={styles.Date}>
            <Icon icon={faCalendar} size={16} color="primary" className={styles.Icon} />

            <Text.Normal>{formattedDate}</Text.Normal>
          </time>
        )}

        <footer className={styles.Footer}>
          <Anchor
            icon={faArrowRight}
            color="secondary"
            hasUnderline={false}
            className={styles.Button}
            to={appRoutes.getAdvisorProjectDetail(id).to}
          >
            {t('COMPONENTS.PROJECT_CARD.BUTTON')}
          </Anchor>
        </footer>
      </Card.Main>
    </Card>
  );
};
