import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import type { RoomMeasurements } from '@Services/Room';
import Yup from '@Utils/YupUtils';

import type {
  RoomMeasurementFormProps,
  RoomMeasurementFormValues
} from './RoomMeasurementForm.props';

export const RoomMeasurementForm = ({ onSubmit, ...props }: RoomMeasurementFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.ADD_MEASUREMENT'
  });
  const measurementSchema = Yup.object({
    MeasuredValue125Hz: Yup.number().min(0).max(10).required(),
    MeasuredValue250Hz: Yup.number().min(0).max(10).required(),
    MeasuredValue500Hz: Yup.number().min(0).max(10).required(),
    MeasuredValue1KHz: Yup.number().min(0).max(10).required(),
    MeasuredValue2KHz: Yup.number().min(0).max(10).required(),
    MeasuredValue4KHz: Yup.number().min(0).max(10).required()
  });
  const formRef = useRef<FormMethods<RoomMeasurementFormValues>>(null);

  const fields = Object.keys(measurementSchema.fields) as Array<keyof RoomMeasurements>;

  const submitHandler = useCallback(
    async (data: RoomMeasurementFormValues) => {
      await onSubmit?.(data);
    },
    [onSubmit]
  );

  return (
    <Form<RoomMeasurementFormValues>
      {...props}
      validationSchema={measurementSchema}
      ref={formRef}
      onSubmit={submitHandler}
    >
      {fields.map((field) => (
        <Form.Input.Text
          key={field}
          label={t(`FIELDS.MEASUREMENT.LABEL.${field}`)}
          name={field}
          inputProps={{
            type: 'number',
            placeholder: t('FIELDS.MEASUREMENT.PLACEHOLDER'),
            step: 0.0000001
          }}
        />
      ))}

      <Button isSubmit>{t('SUBMIT')}</Button>
    </Form>
  );
};
