import { useQuery } from 'react-query';

import type { ProjectGetByCustomerRequest } from '@Services/Project';
import { projectService } from '@Services/Project';

export const useProjectGetByCustomer = (params: ProjectGetByCustomerRequest) => {
  return useQuery(['projects', params.projectId], () => {
    return projectService.getByCustomer(params);
  });
};
