import { Text } from '@Components/ui';
import { SolutionConfigType } from '@Constants/enums';
import { getHexFromValue } from '@Utils/CommonUtils';

import styles from './Options.module.scss';
import type { OptionsProps } from './Options.props';

export const Options = ({ solution }: OptionsProps) => {
  const cleanOptions = solution.options.filter((option) => option.value);

  return (
    <div className={styles.Options}>
      {cleanOptions.map((option) => {
        switch (option.type) {
          case SolutionConfigType.RADIOCOLOR:
          case SolutionConfigType.RADIOWITHIMAGE:
          case SolutionConfigType.RADIOWITHIMAGESMALL: {
            return (
              <div className={styles.Option} key={option.name}>
                <Text.Label className={styles.OptionLabel}>{option.name}</Text.Label>

                <img src={option.url} alt={option.value} className={styles.Image} />
              </div>
            );
          }

          case SolutionConfigType.FILE: {
            return (
              <div className={styles.Option} key={option.name}>
                <Text.Label className={styles.OptionLabel}>{option.name}</Text.Label>

                <img src={option.value} alt={option.value} className={styles.Image} />
              </div>
            );
          }

          case SolutionConfigType.COLORPICKER: {
            const hex = getHexFromValue(option.value);

            if (!hex) return;

            return (
              <div className={styles.Option} key={option.name}>
                <Text.Label className={styles.OptionLabel}>{option.name}</Text.Label>

                <div style={{ backgroundColor: hex }} className={styles.HexTag} />
              </div>
            );
          }

          case SolutionConfigType.CHECKBOX: {
            const value = [option.value].flat().join(', ');

            if (!value) return;

            return (
              <div className={styles.Option} key={option.name}>
                <Text.Label className={styles.OptionLabel}>{option.name}</Text.Label>

                <Text.Normal>{value}</Text.Normal>
              </div>
            );
          }

          default: {
            return (
              <div className={styles.Option} key={option.name}>
                <Text.Label className={styles.OptionLabel}>{option.name}</Text.Label>

                <Text.Normal>{option.value}</Text.Normal>
              </div>
            );
          }
        }
      })}
    </div>
  );
};
