import { useMutation, useQueryClient } from 'react-query';

import type { CustomerUpdateRequest } from '@Services/Customer';
import { customerService } from '@Services/Customer';

export const useCustomerUpdate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: CustomerUpdateRequest) => {
      return customerService.updateCustomer(params);
    },
    {
      onSuccess: ({ id, publicId }) => {
        queryClient.invalidateQueries([customerService.getEndpoint(), id]);
        queryClient.invalidateQueries([customerService.getEndpoint(), 'public', publicId]);
      }
    }
  );
};
