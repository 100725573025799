export const sleep = (duration: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
};

const hexRegex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export const getHexFromValue = (value: string) => {
  const hex = value.slice(value.indexOf('#'), value.length);

  return hexRegex.test(hex) && hex;
};
