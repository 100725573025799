import cn from 'classnames';

import { Link, Rounded } from '@Components/ui';
import { getSlots } from '@Utils/SlotUtils';

import styles from './Card.module.scss';
import type { CardProps } from './Card.props';
import { CardImage, Main } from './internal';

export const Card = ({ className, children, isRow, ...props }: CardProps) => {
  const classNames = cn(
    styles.Card,
    {
      [styles.isRow]: isRow
    },
    className
  );

  const { slots, contents } = getSlots(children, Card.Image);

  return (
    <Rounded className={classNames}>
      <Link {...props} className={styles.Layout}>
        {slots.get(Card.Image)}

        <div className={styles.Contents}>{contents}</div>
      </Link>
    </Rounded>
  );
};

Card.Image = CardImage;
Card.Main = Main;
