import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { AppRoutes } from '@Navigation/Routes';

export const useAppRoutes = () => {
  const { i18n } = useTranslation();

  const appRoutes = useMemo(() => new AppRoutes(i18n.language), [i18n.language]);

  return appRoutes;
};
