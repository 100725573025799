import { faPlus, faUpload } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { forwardRef, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

import { Icon, Text } from '@Components/ui';
import { isFileList } from '@Utils/FormUtils';

import styles from './InputFile.module.scss';
import type { InputFileProps } from './InputFile.props';

const InputFileComponent = (
  { className, label, name, description, isError, onChange, value, ...props }: InputFileProps,
  ref: React.ForwardedRef<HTMLInputElement>
) => {
  const { t } = useTranslation();
  const onDrop = useCallback(
    (files?: Array<File>) => {
      if (onChange) {
        onChange(files);
      }
    },
    [onChange]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const classNames = cn(styles.InputFile, className, {
    [styles.isError]: isError
  });

  const getFilesLabel = (files: Array<File>) => {
    const fileName = files[0].name;
    const restCount = files.length - 1;

    return restCount > 0
      ? t('COMPONENTS.INPUT_FILE.REST_FILES_LABEL', {
          fileName,
          restCount
        })
      : fileName;
  };

  const getLabelText = () => {
    if (isDragActive) {
      return t('COMPONENTS.INPUT_FILE.DROP_FILE');
    }

    if (isFileList(value)) {
      return getFilesLabel(value);
    }

    return t('COMPONENTS.INPUT_FILE.UPLOAD_FILE');
  };

  return (
    <div {...getRootProps()} className={classNames}>
      <input
        className={classNames}
        type="file"
        name={name}
        id={name}
        ref={ref}
        {...props}
        {...getInputProps()}
      />

      <Icon
        icon={isDragActive ? faPlus : faUpload}
        size={14}
        color="primary"
        backgroundColor="gray-light"
        hasCircle
        className={styles.Icon}
      />

      <Text.H4 as="div">{getLabelText()} </Text.H4>
    </div>
  );
};

export const InputFile = forwardRef(InputFileComponent);
