import cn from 'classnames';

import { SpacingGroup, Text } from '@Components/ui';
import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './StatisticScore.module.scss';
import type { StatisticScoreProps } from './StatisticScore.props';

export const StatisticScore = ({
  className,
  color,
  label,
  children,
  ...props
}: StatisticScoreProps) => {
  const statisticScoreVars = scopedCssVars({ backgroundColor: color });

  const classNames = cn(statisticScoreVars, styles.StatisticScore, className);

  return (
    <div className={classNames} {...props}>
      <SpacingGroup isVertical spacing={0.5}>
        {!!label && (
          <Text.Small color="white" className={styles.Label}>
            {label}
          </Text.Small>
        )}

        <Text.H3 color="white">{children}</Text.H3>
      </SpacingGroup>
    </div>
  );
};
