import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  type UpdateProjectContactDetailFormValues,
  UpdateProjectContactDetailForm
} from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import { useProjectGetOne, useProjectUpdate } from '@Hooks/Project';

export const AdvisorUpdateProjectContactDetailScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.CONTACT_DETAILS'
  });
  const appRoutes = useAppRoutes();
  const { projectId } = useParams();
  const { data: project } = useProjectGetOne(projectId);

  const navigate = useNavigate();
  const { mutateAsync: updateContactDetails } = useProjectUpdate();

  const handleClose = () => {
    navigate(appRoutes.getAdvisorProjectDetail(projectId).to);
  };

  if (!project) return null;

  const handleSubmit = async (data: UpdateProjectContactDetailFormValues) => {
    try {
      const { name } = await updateContactDetails({
        ...project,
        name: data.name,
        language: data.language ?? project.language,
        customerDetails: data
      });

      toast.success(t('SUCCESS_MESSAGE', { name }));

      handleClose();
    } catch (error) {
      toast.error(t('ERROR_MESSAGE'));
    }
  };

  return (
    <Modal
      title={appRoutes.getAdvisorProjectUpdate().label}
      open
      onOpenChange={(open) => !open && handleClose()}
    >
      <UpdateProjectContactDetailForm
        initialValues={
          project.customerDetails
            ? { ...project.customerDetails, name: project.name, language: project.language }
            : { ...project.customer, name: project.name, language: project.language }
        }
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};
