import type { TabsTriggerProps } from '@radix-ui/react-tabs';
import { TabsTrigger } from '@radix-ui/react-tabs';
import cn from 'classnames';

import styles from './Button.module.scss';

export const Button = ({ className, children, ...props }: TabsTriggerProps) => {
  const classNames = cn(className, styles.Button);

  return (
    <TabsTrigger {...props} className={classNames}>
      {children}
    </TabsTrigger>
  );
};
