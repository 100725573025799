import { faFileInvoice, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import { Button } from '@Components/ui';
import { useAppRoutes } from '@Hooks/Common';

import type { AddQuotationButtonProps } from './AddQuotationButton.props';

export const AddQuotationButton = ({ projectId }: AddQuotationButtonProps) => {
  const { t } = useTranslation();

  const appRoutes = useAppRoutes();

  return (
    <Button
      to={appRoutes.getAdvisorProjectQuotation(projectId).to}
      iconLeft={faFileInvoice}
      iconRight={faPlus}
    >
      {t('COMPONENTS.ADD_QUOTATION_BUTTON.LABEL')}
    </Button>
  );
};
