import { useCallback, useState } from 'react';

import { InputField, InputFile, InputSelect } from '@Components/ui';
import type { ConfigurationOption } from '@Services/Solution';
import { storageService } from '@Services/Storage';

import { getSelectedOptionIndex } from '../../utils/utils';
import styles from './SolutionConfiguratorFileOption.module.scss';

interface SolutionConfiguratorFileOptionProps {
  option: ConfigurationOption;
  onChange: (value: string) => void;
  onFileChange: (value: string) => void;
  isLoading?: boolean;
}

export const SolutionConfiguratorFileOption = ({
  isLoading,
  option,
  onChange,
  onFileChange
}: SolutionConfiguratorFileOptionProps) => {
  const [image, setImage] = useState<undefined | File[]>();

  const handleFileChange = useCallback(
    async (data: any) => {
      if (data[0]) {
        const response = await storageService.upload(data[0]);

        if (response && response[0].id) {
          onFileChange?.(response[0].id);
        }
      }
    },
    [onFileChange]
  );

  return (
    <InputField label={option.name} key={option.id}>
      {!!option.options && (
        <InputSelect
          value={option.options?.find((_option) => _option.selected)?.id}
          isLoading={isLoading}
          onChange={(event) => {
            onChange(event.target.value);
          }}
          options={
            option.options?.map((_option) => {
              return {
                value: _option.id,
                label: _option.text
              };
            }) ?? []
          }
        />
      )}

      {getSelectedOptionIndex(option.options) === 0 && (
        <InputFile
          className={styles.InputFile}
          value={image}
          onChange={(value) => {
            setImage(value);
            handleFileChange(value);
          }}
        />
      )}
    </InputField>
  );
};
