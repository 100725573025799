import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { AdvisorProjectsList } from '@Components/network';
import { Text } from '@Components/ui';

import styles from './AdvisorProjectsScreen.module.scss';

export const AdvisorProjectsScreen = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'PAGE.PROJECTS' });

  return (
    <>
      <section className={styles.AdvisorProjectsScreen}>
        <header className={styles.Header}>
          <Text.H1 className={styles.Title} color="primary">
            {t('TITLE')}
          </Text.H1>
        </header>

        <AdvisorProjectsList />
      </section>

      <Outlet />
    </>
  );
};
