import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import * as RadixSelect from '@radix-ui/react-select';
import cn from 'classnames';

import { Icon } from '@Components/ui/Icon/Icon';

import styles from './InputSelectColor.module.scss';
import type { InputSelectColorProps } from './InputSelectColor.props';

export const InputSelectColor = ({
  className,
  isLoading,
  options,
  placeholderText,
  value,
  onChange,
  ...props
}: InputSelectColorProps) => {
  const classNames = cn(styles.Trigger, className, {
    [styles.isLoading]: isLoading
  });

  return (
    <RadixSelect.Root
      onValueChange={(val) => {
        onChange(val);
      }}
      disabled={isLoading}
      value={value}
      {...props}
    >
      <RadixSelect.Trigger className={classNames}>
        <RadixSelect.Value>
          <div
            style={{
              backgroundColor: options.find((opt) => opt.value === value)?.hex
            }}
            className={styles.ColorSquare}
          />

          {value}

          <RadixSelect.Icon>
            <Icon icon={faChevronDown} />
          </RadixSelect.Icon>
        </RadixSelect.Value>
      </RadixSelect.Trigger>

      <RadixSelect.Portal>
        <RadixSelect.Content className={styles.Content}>
          <RadixSelect.Viewport>
            {options.map((option) => {
              return (
                <RadixSelect.Item key={option.value} value={option.value} className={styles.Item}>
                  <div
                    style={{
                      backgroundColor: option.hex
                    }}
                    className={styles.ColorSquare}
                  />

                  <RadixSelect.ItemText>{option.value}</RadixSelect.ItemText>
                </RadixSelect.Item>
              );
            })}
          </RadixSelect.Viewport>
        </RadixSelect.Content>
      </RadixSelect.Portal>
    </RadixSelect.Root>
  );
};
