import { Outlet } from 'react-router-dom';

import { Products } from '@Components/network';

export const AdvisorProductsScreen = () => {
  return (
    <>
      <Products />

      <Outlet />
    </>
  );
};
