import cn from 'classnames';

import { Link } from '@Components/ui/Link/Link';
import { capitalize } from '@Utils/StringUtils';

import { Icon } from '../Icon/Icon';
import styles from './Button.module.scss';
import type { ButtonProps } from './Button.props';

export const Button = ({
  children,
  className,
  color = 'secondary',
  isLoading,
  isOutline,
  iconRight,
  iconLeft,
  ...props
}: ButtonProps) => {
  const colorName = capitalize(color);

  const classNames = cn(styles.Button, className, {
    [styles.isOutline]: isOutline,
    [styles[`is${colorName}`]]: Boolean(color),
    [styles.isLoading]: isLoading,
    [styles.isDisabled]: props.disabled
  });

  return (
    <Link className={classNames} {...props}>
      {iconLeft && (
        <div className={styles.IconLeft}>
          <Icon icon={iconLeft} color={color} />
        </div>
      )}

      <div className={styles.Label}>{children}</div>

      {iconRight && <Icon icon={iconRight} className={styles.IconRight} />}
    </Link>
  );
};
