import { cx } from '@emotion/css';

import { Progress, Text } from '@Components/ui';
import { formatPercentage } from '@Utils/StringUtils';
import { scopedCssVars } from '@Utils/ThemeUtils';

import type { ProgressBarProps } from '..';
import { getProgressBarColor } from './internal/utils';
import styles from './ProgressBar.module.scss';

export const ProgressBar = ({ className, value, max, ...props }: ProgressBarProps) => {
  const progressColor = getProgressBarColor(value ?? 0, max);
  const cssVars = scopedCssVars({ progressColor });
  const classNames = cx(styles.ProgressLabel, className, cssVars);

  const label = formatPercentage(value ?? 0, max);

  return (
    <>
      <Text.Small className={classNames}>
        <span className={styles.Spacer}>100%</span>

        <span>{label}</span>
      </Text.Small>

      <Progress
        {...props}
        className={styles.ProgressBar}
        value={value}
        max={max}
        color={progressColor}
      />
    </>
  );
};
