import { acousticClient } from '@Services/Clients';
import { ResourceService } from '@Services/Common';

import type {
  IProject,
  ProjectCreateRequest,
  ProjectCreateResponse,
  ProjectGetByCustomerRequest,
  ProjectGetListForCustomerRequest,
  ProjectGetListForCustomerResponse,
  ProjectGetListRequest,
  ProjectGetListResponse,
  ProjectUpdateRequest,
  ProjectUpdateResponse
} from './ProjectService.props';

export class ProjectService extends ResourceService<IProject> {
  getListForCustomer = async ({ customerId, ...config }: ProjectGetListForCustomerRequest) => {
    const response = await this.get<ProjectGetListForCustomerResponse>(
      `/customer/${customerId}`,
      config
    );

    return response.data;
  };

  getList = async (config?: ProjectGetListRequest) => {
    const { data } = await this.get<ProjectGetListResponse>('/', config);

    return data;
  };

  createProject = async (params: ProjectCreateRequest) => {
    const { data } = await this.post<ProjectCreateResponse>('/', params);

    return data;
  };

  updateProject = async (params: ProjectUpdateRequest) => {
    const { data } = await this.put<ProjectUpdateResponse>(`/${params.id}`, params);

    return data;
  };

  getByCustomer = async ({ customerId, projectId }: ProjectGetByCustomerRequest) => {
    const { data } = await this.get<IProject>(`/${projectId}/customer/${customerId}`);

    return data;
  };
}

export const projectService = new ProjectService(acousticClient.getAxiosInstance(), '/projects');
