import { ProjectHero } from '@Components/container';
import { useProjectUpdate } from '@Hooks/Project';
import { isAdvisor } from '@Utils/AuthUtils';

import type { AdvisorProjectHeroProps } from './AdvisorProjectHero.props';

export const AdvisorProjectHero = ({ project }: AdvisorProjectHeroProps) => {
  const { mutateAsync } = useProjectUpdate();

  const handleChangeTitle = async (name: string) => {
    await mutateAsync({ ...project, name });
  };

  return <ProjectHero project={project} isAdvisor={isAdvisor()} onChange={handleChangeTitle} />;
};
