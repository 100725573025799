import cn from 'classnames';
import { Container } from 'react-bootstrap';

import { Text } from '@Components/ui';

import styles from './Footer.module.scss';
import type { FooterProps } from './Footer.props';

export const Footer = ({ className, children, title, ...props }: FooterProps) => {
  const classNames = cn(styles.Footer, className);

  return (
    <footer className={classNames} {...props}>
      <Container className={styles.Container}>
        {!!title && <Text.H4 className={styles.Title}>{title}</Text.H4>}

        {children}
      </Container>
    </footer>
  );
};
