import cn from 'classnames';
import type { ForwardedRef } from 'react';
import { forwardRef } from 'react';

import styles from './InputText.module.scss';
import type { InputTextProps } from './InputText.props';

const InputTextComponent = (
  { className, children, label, name, description, isError, ...props }: InputTextProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const classNames = cn(styles.InputText, className, {
    [styles.isError]: isError
  });

  return <input className={classNames} type="text" name={name} id={name} {...props} ref={ref} />;
};

export const InputText = forwardRef(InputTextComponent);
