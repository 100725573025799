import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import * as Dialog from '@radix-ui/react-dialog';
import cn from 'classnames';

import { ButtonIcon } from '@Components/ui';

import styles from './Modal.module.scss';
import type { ModalProps } from './Modal.props';

const ModalRoot = ({
  title,
  className,
  children,
  defaultOpen,
  open,
  onOpenChange,
  isClosable = true,
  isLarge,
  contentClassName,
  ...props
}: ModalProps) => {
  const classNames = cn(styles.Modal, className, {
    [styles.isLarge]: isLarge
  });

  return (
    <Dialog.Root defaultOpen={defaultOpen} open={!isClosable || open} onOpenChange={onOpenChange}>
      <Dialog.Portal>
        <Dialog.Overlay className={styles.Overlay} />

        <Dialog.Content className={classNames} {...props}>
          {!!title && <Dialog.Title className={styles.Title}>{title}</Dialog.Title>}

          <Dialog.Description asChild className={cn(styles.Description, contentClassName)}>
            <div>{children}</div>
          </Dialog.Description>

          {isClosable && (
            <Dialog.Close className={styles.Close}>
              <ButtonIcon icon={faTimes} backgroundColor="gray-light" />
            </Dialog.Close>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const Modal = Object.assign(ModalRoot, {
  Close: Dialog.Close
});
