import { useMutation, useQueryClient } from 'react-query';

import type { RoomAddMeasurementRequest } from '@Services/Room';
import { roomService } from '@Services/Room';

export const useRoomAddMeasurement = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: RoomAddMeasurementRequest) => {
      return roomService.addMeasurement(params);
    },
    {
      onSuccess: ({ id }) => {
        queryClient.invalidateQueries([roomService.getEndpoint(), id]);
      }
    }
  );
};
