import { useParams } from 'react-router-dom';

import { AppError } from '@Constants/enums';

import { useCustomerGetOnePublic } from './useCustomerGetOnePublic';

export const useCustomerGetCurrent = () => {
  const { customerId } = useParams();

  if (!customerId) {
    throw new Error(AppError.NO_CUSTOMER_ID_PARAM);
  }

  return useCustomerGetOnePublic({ publicId: customerId });
};
