import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';

import { AuthUpdatePasswordForm } from '@Components/form';
import { Modal } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';

export const AuthUpdatePasswordScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.RECOVERY.RESET.FORM'
  });
  const appRoutes = useAppRoutes();

  const navigate = useNavigate();

  const [params] = useSearchParams();

  const handleSubmit = (success: boolean) => {
    if (success) {
      return navigate(appRoutes.getUpdatePasswordComplete().to);
    }

    return navigate(appRoutes.getLogin().to);
  };

  const token = params.get('token');
  const email = params.get('email');

  if (!token || !email) {
    return <Navigate to={appRoutes.getLogin().to} />;
  }

  return (
    <Modal isClosable={false} title={t('TITLE')}>
      <AuthUpdatePasswordForm initialValues={{ token, email }} onSubmit={handleSubmit} />
    </Modal>
  );
};
