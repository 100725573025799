import type { ControllerFieldState } from 'react-hook-form';
import { get } from 'react-hook-form';
import { getI18n } from 'react-i18next';

import {
  DEFAULT_LANGUAGE,
  PROFILE_IMAGE_MAX_HEIGHT,
  PROFILE_IMAGE_MAX_WIDTH
} from '@Constants/configs';
import { Locale } from '@Constants/enums';
import type { ExtractFunction, IBaseModel } from '@Constants/interfaces';

import { getLocaleKeyByValue } from './TranslationUtils';

export const getFieldStateValidation = (fieldState: ControllerFieldState) => {
  const isError = !!fieldState.error === true;
  const isValid = !!fieldState.error === false && fieldState.isTouched;

  return {
    isValid,
    isError
  };
};
export const isFile = (input: unknown): input is File => {
  return 'File' in window && input instanceof File;
};

export const isFileList = (input: unknown): input is Array<File> => {
  return Array.isArray(input) && input.every(isFile);
};

export const modelToOptions = <T extends IBaseModel>(
  models?: T[],
  labelGetter: string | ExtractFunction<T> = 'name',
  valueGetter: string | ExtractFunction<T> = 'id'
) => {
  return (models ?? []).map((model) => {
    const label = typeof labelGetter === 'string' ? get(model, labelGetter) : labelGetter(model);
    const value = typeof valueGetter === 'string' ? get(model, valueGetter) : valueGetter(model);

    return {
      label,
      value
    } as const;
  });
};

export const checkImageSize = (imageFile: any) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = URL.createObjectURL(imageFile);

    img.addEventListener('load', () => {
      const width = img.width;
      const height = img.height;

      URL.revokeObjectURL(img.src);

      if (width > PROFILE_IMAGE_MAX_WIDTH || height > PROFILE_IMAGE_MAX_HEIGHT) {
        resolve(false);
      } else {
        resolve(true);
      }
    });

    img.addEventListener('error', () => {
      reject(false);
    });
  });
};

export const getLanguageOptions = (currentLocale: string) => {
  const i18n = getI18n();
  const localeKey = getLocaleKeyByValue(currentLocale);
  const languageData = i18n.getDataByLanguage(localeKey?.toLowerCase() ?? DEFAULT_LANGUAGE);

  if (!languageData) return [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { LANGUAGES } = languageData.translation.COMMON.GLOBALS;

  const options = Object.entries(LANGUAGES).map(([value, label]) => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    value: `${Locale[value]}`,
    label: `${label}`
  }));

  return options;
};
