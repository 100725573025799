import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import cn from 'classnames';
import { useCallback } from 'react';
import { useInViewEffect } from 'react-hook-inview';
import { useTranslation } from 'react-i18next';

import { Icon, Spinner, Text } from '@Components/ui';

import styles from './LazyList.module.scss';
import type { LazyListProps } from './LazyList.props';

export const LazyList = ({
  hasNextPage,
  fetchNextPage,
  isLoading,
  isFetched,
  totalItems,
  children,
  isError,
  className
}: LazyListProps) => {
  const { t } = useTranslation();

  const handleBottomReached = useCallback(() => {
    if (!isLoading && hasNextPage && !isError) {
      fetchNextPage();
    }
  }, [isLoading, isError, hasNextPage, fetchNextPage]);

  const ref = useInViewEffect(
    ([entry]) => {
      if (entry.isIntersecting) {
        handleBottomReached();
      }
    },
    { threshold: 0.5 },
    [isLoading, hasNextPage, fetchNextPage]
  );

  return (
    <div className={cn(styles.LazyList, className)}>
      {children}

      {!isFetched && (
        <div className={styles.Notification}>
          <Spinner />
        </div>
      )}

      {(hasNextPage || isLoading) && isFetched && (
        <div className={styles.Notification} ref={ref}>
          <Spinner />
        </div>
      )}

      {!hasNextPage && isFetched && totalItems === 0 && (
        <div className={styles.Notification}>
          <Icon className={styles.Icon} icon={faCheckCircle} size={20} />

          <Text.Normal>{t('COMPONENTS.LAZY_LIST.NONE')}</Text.Normal>
        </div>
      )}

      {!hasNextPage && isFetched && children && !!totalItems && (
        <div className={styles.Notification}>
          <Icon className={styles.Icon} icon={faCheckCircle} size={20} color="secondary" />

          <Text.Normal>{t('COMPONENTS.LAZY_LIST.COMPLETED')}</Text.Normal>
        </div>
      )}
    </div>
  );
};
