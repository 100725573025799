import * as RadioGroup from '@radix-ui/react-radio-group';
import cn from 'classnames';

import { Button } from '../Button/Button';
import styles from './InputRadio.module.scss';
import type { InputRadioProps } from './InputRadio.props';

export const InputRadio = ({
  className,
  children,
  options,
  value,
  isLoading,
  ...props
}: InputRadioProps) => {
  const classNames = cn(styles.InputRadio, className, {
    [styles.isLoading]: isLoading
  });

  return (
    <RadioGroup.Root disabled={isLoading} {...props} className={classNames} value={value}>
      {options.map((option) => {
        const optionValue = String(option.value);
        const isSelected = value === optionValue;

        return (
          <RadioGroup.Item value={optionValue} key={option.value} className={styles.Option}>
            <Button isOutline={!isSelected}>{option.label}</Button>
          </RadioGroup.Item>
        );
      })}
    </RadioGroup.Root>
  );
};
