import { type ChangeEvent, useState } from 'react';

import { InputField, InputTextArea } from '@Components/ui';
import { useDebounce } from '@Hooks/Common';
import type { ConfigurationOption } from '@Services/Solution';

import styles from './SolutionConfiguratorTextArea.module.scss';

interface SolutionConfiguratorTextAreaProps {
  option: ConfigurationOption;
  onChange: (value: string) => void;
  isLoading?: boolean;
}

export const SolutionConfiguratorTextArea = ({
  option,
  onChange,
  isLoading
}: SolutionConfiguratorTextAreaProps) => {
  const { debounce } = useDebounce(1000);
  const [error, setError] = useState('');
  const [internalValue, setInternalValue] = useState(option.value);

  const handleValueChange = async (event: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = event.target.value;
    setError('');
    setInternalValue(inputValue);

    debounce(async () => {
      try {
        onChange(inputValue);
      } catch (err: any) {
        setError(err.message);
      }
    });
  };

  return (
    <InputField
      label={option.name}
      key={option.id}
      className={styles.SolutionConfiguratorTextAreaOption}
    >
      <InputTextArea
        disabled={isLoading}
        value={internalValue}
        onChange={handleValueChange}
        className={styles.TextArea}
        isError={!!error}
      />
    </InputField>
  );
};
