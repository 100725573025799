import { ProductCard } from '@Components/container';
import { GridList } from '@Components/layout';

import type { ProductListProps } from './ProductList.props';

export const ProductList = ({ products, children, ...props }: ProductListProps) => {
  return (
    <GridList columnsDesktop={2} {...props}>
      {products?.map((product) => <ProductCard product={product} key={product.slug} />) || children}
    </GridList>
  );
};
