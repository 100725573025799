import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import type { FormMethods } from '@Components/ui/Form/Form.props';
import Yup from '@Utils/YupUtils';

import type {
  CreateProjectRoomFormProps,
  CreateProjectRoomFormValues
} from './CreateProjectRoomForm.props';

export const CreateProjectRoomForm = ({
  onSubmit,
  building,
  disabled,
  ...props
}: CreateProjectRoomFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.PROJECT.FORM.CREATE_ROOM'
  });
  const createProjectRoomSchema = Yup.object({
    name: Yup.string().required(),
    roomtype: Yup.object({
      id: Yup.string().required()
    }).required()
  });
  const formRef = useRef<FormMethods<CreateProjectRoomFormValues>>(null);

  const roomTypes = building.roomTypes.map(({ id, name }) => ({ value: id, label: name }));

  const submitHandler = useCallback(
    async (data: CreateProjectRoomFormValues) => {
      await onSubmit?.(data);

      formRef.current?.reset();
    },
    [onSubmit]
  );

  return (
    <Form<CreateProjectRoomFormValues>
      {...props}
      validationSchema={createProjectRoomSchema}
      ref={formRef}
      onSubmit={submitHandler}
    >
      <Form.Input.Text
        inputProps={{ placeholder: t('FIELDS.NAME.PLACEHOLDER'), disabled }}
        name="name"
        isRequired
        label={t('FIELDS.NAME.LABEL')}
      />

      <Form.Input.Select
        options={roomTypes}
        inputProps={{ disabled }}
        name="roomtype.id"
        isRequired
        label={t('FIELDS.ROOMTYPE.LABEL')}
      />

      <Button isSubmit disabled={disabled}>
        {t('SUBMIT')}
      </Button>
    </Form>
  );
};
