import { InputField, InputSelectColor } from '@Components/ui';
import type { ConfigurationOption } from '@Services/Solution';

interface SolutionConfiguratorColorOptionProps {
  option: ConfigurationOption;
  onChange: (optionId: string) => void;
  isLoading?: boolean;
}

export const SolutionConfiguratorColorOption = ({
  option,
  onChange,
  isLoading
}: SolutionConfiguratorColorOptionProps) => {
  const colorOptions = option.colorOptions;

  if (!colorOptions) return null;

  const options = Object.keys(colorOptions).map((key) => {
    return {
      value: key,
      hex: colorOptions[key]
    };
  });

  return (
    <InputField label={option.name} key={option.id}>
      <InputSelectColor
        value={option.value ?? ''}
        isLoading={isLoading}
        onChange={(value) => {
          onChange(value);
        }}
        options={options}
      />
    </InputField>
  );
};
