import { useMemo } from 'react';
import type { InfiniteData } from 'react-query';

import type { IBaseModel, ListResponse } from '@Constants/interfaces';
import { flattenInfiniteData } from '@Utils/QueryUtils';

export const useInfiniteData = <T extends IBaseModel>(
  data: InfiniteData<ListResponse<T>> | undefined
) => {
  const results = useMemo(() => {
    if (data && data.pages) {
      return flattenInfiniteData(data);
    }

    return [];
  }, [data]);

  return results;
};
