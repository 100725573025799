import { Route, Routes } from 'react-router-dom';

import { CustomerPage } from '@Components/layout';
import { useAppRoutes } from '@Hooks/Common';
import {
  AuthForgotPasswordScreen,
  AuthLoginScreen,
  AuthRegisterCompleteAdvisorScreen,
  AuthRegisterCompleteScreen,
  AuthRegisterScreen,
  AuthUpdatePasswordComplete,
  AuthUpdatePasswordScreen
} from '@Screens/Auth';
import {
  CustomerAccountScreen,
  CustomerHomeScreen,
  CustomerProductDetailScreen,
  CustomerProductScreen,
  CustomerProjectDetailScreen,
  CustomerProjectsScreen
} from '@Screens/Customer';
import { Redirect } from '@Utils/RouteUtils';

export const PublicStack = () => {
  const appRoutes = useAppRoutes();

  return (
    <Routes>
      <Route path={appRoutes.getLogin().path} element={<AuthLoginScreen />} />

      <Route path={appRoutes.getForgotPassword().path} element={<AuthForgotPasswordScreen />} />

      <Route path={appRoutes.getUpdatePassword().path} element={<AuthUpdatePasswordScreen />} />

      <Route
        path={appRoutes.getUpdatePasswordComplete().path}
        element={<AuthUpdatePasswordComplete />}
      />

      <Route path={appRoutes.getRegister().path} element={<AuthRegisterScreen />} />

      <Route path={appRoutes.getRegisterComplete().path} element={<AuthRegisterCompleteScreen />} />

      <Route
        path={appRoutes.getRegisterCompleteAdvisor().path}
        element={<AuthRegisterCompleteAdvisorScreen />}
      />

      <Route
        path={appRoutes.getUpdatePasswordComplete().path}
        element={<AuthUpdatePasswordComplete />}
      />

      <Route path={appRoutes.getCustomerHome().path} element={<CustomerPage />}>
        <Route index element={<CustomerHomeScreen />} />

        <Route path={appRoutes.getCustomerAccount().path} element={<CustomerAccountScreen />} />

        <Route path={appRoutes.getCustomerProjects().path}>
          <Route index element={<CustomerProjectsScreen />} />

          <Route
            path={appRoutes.getCustomerProjectDetail().path}
            element={<CustomerProjectDetailScreen />}
          />
        </Route>

        <Route path={appRoutes.getCustomerProducts().path} element={<CustomerProductScreen />}>
          <Route
            path={appRoutes.getCustomerProductDetail().path}
            element={<CustomerProductDetailScreen />}
          />
        </Route>

        <Route path="*" element={<Redirect home="customer" />} />
      </Route>

      <Route path="*" element={<Redirect home="login" />} />
    </Routes>
  );
};
