import type { ExtractFunction, IBaseModel } from '@Constants/interfaces';
import type { ResourceService } from '@Services/Common';
import { modelToOptions } from '@Utils/FormUtils';

import { useGetList } from './useGetList';
import { useInfiniteData } from './useInfiniteData';

export const useListInputOptions = <T extends IBaseModel>(
  service: ResourceService<T>,
  labelGetter: string | ExtractFunction<T> = 'name',
  valueGetter: string | ExtractFunction<T> = 'id'
) => {
  const { data } = useGetList(service, { perPage: 9999 });
  const items = useInfiniteData(data);

  return modelToOptions(items, labelGetter, valueGetter);
};
