import { InputSearch } from '@Components/ui';

import { FormField } from '../FormField/FormField';
import type { FormSearchProps } from './FormSearch.props';

export const FormSearch = ({ options, className, ...form }: FormSearchProps) => {
  return (
    <FormField {...form} className={className}>
      <InputSearch {...options} />
    </FormField>
  );
};
