import { useMutation } from 'react-query';

import type { AuthRecoveryRequest } from '@Services/AuthService';
import { authService } from '@Services/AuthService';

export const useAuthRecovery = () => {
  return useMutation((request: AuthRecoveryRequest) => {
    return authService.recover(request);
  });
};
