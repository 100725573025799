import COMMON from './common';
import COMPONENTS from './components';
import DOMAIN from './domain';
import PAGE from './page';

export default {
  translation: {
    COMMON,
    COMPONENTS,
    DOMAIN,
    PAGE
  }
};
