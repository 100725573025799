import { Icon, Link } from '@Components/ui';

import type { ButtonIconProps } from './ButtonIcon.props';

export const ButtonIcon = ({
  icon,
  color,
  backgroundColor,
  size = 16,
  hasSquare = false,
  accessibilityLabel,
  title,
  ...props
}: ButtonIconProps) => {
  return (
    <Link {...props} title={title ?? accessibilityLabel}>
      <Icon
        size={size}
        icon={icon}
        hasSquare={hasSquare}
        hasCircle={!hasSquare}
        color={color}
        backgroundColor={backgroundColor}
        accessibilityLabel={accessibilityLabel ?? title}
      />
    </Link>
  );
};
