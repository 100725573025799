import { useMutation, useQueryClient } from 'react-query';

import type { CustomerCreateRequest } from '@Services/Customer';
import { customerService } from '@Services/Customer';

export const useCustomerCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: CustomerCreateRequest) => {
      return customerService.createCustomer(params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([customerService.getEndpoint()]);
      }
    }
  );
};
