import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import { ButtonIcon, Spinner, Text } from '@Components/ui';
import { LocaleLanguageMap } from '@Constants/enums';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import type { IUser } from '@Services/AuthService';

import styles from './AdvisorAccountScreen.module.scss';

export const AdvisorAccountScreen = () => {
  const { t } = useTranslation();
  const { data } = useAuthGetMe();
  const appRoutes = useAppRoutes();

  if (!data) return <Spinner />;

  const { firstName, lastName, company, language, email, image } = data as IUser;

  return (
    <>
      <section className={styles.AdvisorAccountScreen}>
        <header className={styles.Header}>
          <Text.H1 className={styles.Title} color="primary">
            {`${firstName} ${lastName}`}
          </Text.H1>

          <ButtonIcon
            className={styles.Button}
            icon={faEdit}
            size={22}
            backgroundColor="secondary"
            color="white"
            accessibilityLabel={t('PAGE.ADVISOR_ACCOUNT.EDIT_ACCOUNT')}
            to={appRoutes.getAdvisorAccountUpdate().to}
          />
        </header>

        <section>
          {company && (
            <Text.H3>
              {`${t('PAGE.ADVISOR_ACCOUNT.LABELS.COMPANY')}: `}

              <span className={styles.FontWeightNormal}>{company.name}</span>
            </Text.H3>
          )}

          {language && (
            <Text.H3>
              {`${t('PAGE.ADVISOR_ACCOUNT.LABELS.LANGUAGE')}: `}

              <span className={styles.FontWeightNormal}>{`${t(
                `COMMON.GLOBALS.LANGUAGES.${LocaleLanguageMap[language]}` as any
              )}`}</span>
            </Text.H3>
          )}

          {email && (
            <Text.H3>
              {`${t('PAGE.ADVISOR_ACCOUNT.LABELS.EMAIL')}: `}

              <span className={styles.FontWeightNormal}>{email}</span>
            </Text.H3>
          )}

          {image && (
            <>
              <Text.H3>{`${t('PAGE.ADVISOR_ACCOUNT.LABELS.IMAGE')}:`}</Text.H3>

              <img className={styles.Image} src={image.path} />
            </>
          )}
        </section>
      </section>

      <Outlet />
    </>
  );
};
