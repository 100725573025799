import { faPencil, faSave } from '@fortawesome/pro-solid-svg-icons';
import cn from 'classnames';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ButtonIcon, SharePageButton, Text } from '@Components/ui';
import { useBoolean } from '@Hooks/Common';

import styles from './EditableTitle.module.scss';
import type { EditableTitleFormInputs, EditableTitleProps } from './EditableTitle.props';

export const EditableTitle = ({
  className,
  title = '',
  isAdvisor,
  onChange,
  shareUrl,
  ...props
}: EditableTitleProps) => {
  const { t } = useTranslation();
  const { value: isEditing, setTrue: setEditing, setFalse: setNotEditing } = useBoolean(false);

  const [content, setContent] = useState(title);

  // TODO: Replace with custom form handling later
  const { register, handleSubmit, watch, setFocus } = useForm<EditableTitleFormInputs>({
    defaultValues: { title }
  });

  useEffect(() => {
    const subscription = watch((values) => setContent(values.title ?? ''));
    return () => subscription.unsubscribe();
  }, [watch]);

  useLayoutEffect(() => {
    if (isEditing) {
      setFocus('title', { shouldSelect: true });
    }
  }, [isEditing, setFocus]);

  const handleOnSubmit = handleSubmit((values) => {
    onChange?.(values.title);

    setNotEditing();
  });

  const classNames = cn(styles.EditableTitle, className);

  if (!isEditing) {
    return (
      <div className={classNames}>
        <Text.H1 color="white" {...props}>
          {content}
        </Text.H1>

        {isAdvisor && (
          <div className={styles.ButtonGroup}>
            <ButtonIcon
              title="edit title"
              className={styles.Button}
              backgroundColor="gray-light"
              color="primary"
              icon={faPencil}
              onClick={setEditing}
            />

            {!!shareUrl && (
              <SharePageButton
                className={styles.ShareButton}
                shareUrl={shareUrl}
                title={t('COMPONENTS.PROJECT_HERO.SHARE_TITLE')}
                successMessage={t('COMPONENTS.PROJECT_HERO.SHARE_SUCCESS_MESSAGE')}
              />
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <form onSubmit={handleOnSubmit} className={classNames}>
      <div className={styles.InputWrapper}>
        <span className={styles.InputSpacer}>{content || ' '}</span>

        <input {...register('title')} type="text" className={styles.Input} />
      </div>

      <ButtonIcon
        isSubmit
        title="save title"
        backgroundColor="gray-light"
        className={styles.Button}
        color="primary"
        icon={faSave}
      />
    </form>
  );
};
