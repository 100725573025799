import { useMutation, useQueryClient } from 'react-query';

import { projectService } from '@Services/Project';
import type { RoomAddSolutionRequest } from '@Services/Room';
import { roomService } from '@Services/Room';
import { handleUnknownError } from '@Utils/ErrorUtils';

export const useRoomAddSolution = () => {
  const queryClient = useQueryClient();

  return useMutation(
    (params: RoomAddSolutionRequest) => {
      return roomService.addSolution(params);
    },
    {
      onError: handleUnknownError,
      onSuccess: () => {
        queryClient.invalidateQueries([projectService.getEndpoint()]);
        queryClient.invalidateQueries([roomService.getEndpoint()]);
      }
    }
  );
};
