import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';

import { Anchor, Button, Form, SpacingGroup } from '@Components/ui';
import { useAuthUpdatePassword } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';

import styles from './AuthUpdatePasswordForm.module.scss';
import type {
  AuthUpdatePasswordFormProps,
  AuthUpdatePasswordFormValues
} from './AuthUpdatePasswordForm.props';

export const AuthUpdatePasswordForm = ({
  onSubmit,
  className,
  initialValues
}: AuthUpdatePasswordFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.RECOVERY.RESET.FORM'
  });
  const authUpdatePassword = useAuthUpdatePassword();
  const appRoutes = useAppRoutes();
  const handleSubmit = useCallback(
    async (values: AuthUpdatePasswordFormValues) => {
      try {
        const status = await authUpdatePassword.mutateAsync(values);

        onSubmit?.(status === 200);
      } catch (e) {
        onSubmit?.(false);
      }
    },
    [authUpdatePassword, onSubmit]
  );

  const passwordValidationSchema = Yup.object().shape({
    password: Yup.string().password().required(),
    passwordConfirmation: Yup.string().password('password').required()
  });

  return (
    <Form<AuthUpdatePasswordFormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={passwordValidationSchema}
      className={className}
    >
      <Form.Input.Text
        name="password"
        label={t('LABELS.PASSWORD')}
        inputProps={{ placeholder: t('LABELS.PASSWORD'), type: 'password' }}
      />

      <Form.Input.Text
        name="passwordConfirmation"
        label={t('LABELS.PASSWORD_CONFIRMATION')}
        inputProps={{ placeholder: t('LABELS.PASSWORD_CONFIRMATION'), type: 'password' }}
      />

      <SpacingGroup spacing={2} className={styles.Buttons}>
        <Button isSubmit type="submit">
          {t('LABELS.SUBMIT')}
        </Button>

        <Anchor to={appRoutes.getLogin().to} className={styles.Anchor}>
          {t('LABELS.LOGIN')}
        </Anchor>
      </SpacingGroup>
    </Form>
  );
};
