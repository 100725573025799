import { faShare } from '@fortawesome/pro-solid-svg-icons';
import { toast } from 'react-toastify';

import { ButtonIcon } from '@Components/ui';

import type { SharePageButtonProps } from './SharePageButton.props';

export const SharePageButton = ({
  color = 'primary',
  backgroundColor = 'gray-light',
  shareUrl,
  successMessage,
  title,
  ...props
}: SharePageButtonProps) => {
  const handleClick = () => {
    navigator.clipboard.writeText(shareUrl);
    toast.success(successMessage);
  };

  return (
    <ButtonIcon
      title={title}
      backgroundColor={backgroundColor}
      color={color}
      icon={faShare}
      onClick={handleClick}
      {...props}
    />
  );
};
