import { isFuture } from 'date-fns';

import { StorageKey } from '@Constants/enums';
import type { AuthLoginResponse, AuthTokenResponse } from '@Services/AuthService';

export const setItem = <T>(key: StorageKey, data: T) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getItem = <T>(key: StorageKey): T | undefined => {
  const item = localStorage.getItem(key);

  if (!item) {
    return;
  }

  return JSON.parse(item);
};

export const removeItem = (key: StorageKey) => {
  localStorage.removeItem(key);
};

export const setAccessTokens = (accessTokens: AuthLoginResponse) => {
  setItem(StorageKey.ACCESS_TOKEN, accessTokens);
};

export const getAccessTokens = () => {
  return getItem<AuthTokenResponse>(StorageKey.ACCESS_TOKEN);
};

export const getIsRefreshStillValid = () => {
  const accessTokens = getAccessTokens();

  if (!accessTokens) {
    return false;
  }

  const refreshExpirationDate = new Date(accessTokens.refreshExpires);

  return isFuture(refreshExpirationDate);
};

export const removeAccessTokens = () => {
  removeItem(StorageKey.ACCESS_TOKEN);
};
