import type { ChartOptions } from 'chart.js';

import { getTheme } from '@Utils/ThemeUtils';
import { i18n } from '@Utils/TranslationUtils';

const { color } = getTheme();

export const Scales: ChartOptions<'line'>['scales'] = {
  x: {
    labels: ['125 Hz', '250 Hz', '500 Hz', '1 kHz', '2 kHz', '4 kHz'],
    grid: {
      display: false
    },
    ticks: {
      align: 'inner'
    },
    title: {
      text: i18n.t('COMPONENTS.FREQUENCY_CHART.FREQUENCY'),
      display: true,
      align: 'start',
      font: {
        size: 8
      },
      color: color.primary.default,
      padding: { top: 12 }
    }
  },

  y: {
    type: 'linear',
    ticks: {
      stepSize: 0.25,
      format: { minimumFractionDigits: 2, maximumFractionDigits: 2 }
    },
    suggestedMax: 1.5,
    suggestedMin: 0.25,
    grid: {
      drawBorder: false,
      tickWidth: 0,
      color: 'rgb(174, 174, 174, 0.5)'
    },
    title: {
      text: i18n.t('COMPONENTS.FREQUENCY_CHART.DURATION'),
      display: true,
      align: 'start',
      font: {
        size: 8
      },
      color: color.primary.default,
      padding: { bottom: 12 }
    }
  }
};
