import { Controller } from 'react-hook-form';

import { InputCheckbox } from '@Components/ui';
import { getFieldStateValidation } from '@Utils/FormUtils';

import { FormField } from '../FormField/FormField';
import type { FormCheckboxProps } from './FormCheckbox.props';

export const FormCheckbox = ({ inputProps, className, ...form }: FormCheckboxProps) => {
  return (
    <FormField {...form} className={className} hasController>
      <Controller
        name={form.name}
        render={({ field, fieldState }) => {
          const { isError } = getFieldStateValidation(fieldState);

          return <InputCheckbox isError={isError} {...inputProps} {...field} />;
        }}
      />
    </FormField>
  );
};
