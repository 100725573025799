import { useInfiniteQuery } from 'react-query';

import { PAGE_SIZE_MD } from '@Constants/configs';
import type { IBaseModel, ListRequest } from '@Constants/interfaces';
import type { ResourceService } from '@Services/Common';

export const useGetList = <T extends IBaseModel>(
  service: ResourceService<T>,
  params?: ListRequest
) => {
  return useInfiniteQuery(
    [service.getEndpoint(), params],
    ({ pageParam = 1 }) => {
      return service.getList({
        ...params,
        page: pageParam,
        perPage: params?.perPage ?? PAGE_SIZE_MD
      });
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.page + 1 <= lastPage.totalPages) {
          return lastPage.page + 1;
        }
      }
    }
  );
};
