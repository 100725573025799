import ACCOUNT from './ACCOUNT.json';
import AUTHENTICATION from './AUTHENTICATION.json';
import CUSTOMER from './CUSTOMER.json';
import PRODUCT from './PRODUCT.json';
import PROJECT from './PROJECT.json';
import ROOM from './ROOM.json';

export default {
  ACCOUNT,
  AUTHENTICATION,
  CUSTOMER,
  PRODUCT,
  PROJECT,
  ROOM
};
