import { InputText } from '@Components/ui';

import { FormField } from '../FormField/FormField';
import type { FormTextProps } from './FormText.props';

export const FormText = ({ inputProps, className, ...form }: FormTextProps) => {
  return (
    <FormField {...form} className={className}>
      <InputText {...inputProps} />
    </FormField>
  );
};
