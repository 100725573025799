import { useParams } from 'react-router-dom';

import { useAppRoutes } from '@Hooks/Common';

import { ProjectCard } from '../ProjectCard/ProjectCard';
import type { CustomerProjectCardProps } from './CustomerProjectCard.props';

export const CustomerProjectCard = (props: CustomerProjectCardProps) => {
  const { customerId } = useParams();

  const appRoutes = useAppRoutes();

  return (
    <ProjectCard
      {...props}
      to={appRoutes.getCustomerProjectDetail(customerId, props.project.id).to}
    />
  );
};
