import type { UpdateRoomFormValues } from '@Components/form';
import { UpdateRoomForm } from '@Components/form';
import { useRoomUpdate } from '@Hooks/Rooms';

import type { RoomDetailProps } from './RoomDetail.props';

export const RoomDetail = ({ room }: RoomDetailProps) => {
  const { mutateAsync: updateRoom, isLoading } = useRoomUpdate();

  const submitHandler = async (formData: UpdateRoomFormValues) => {
    await updateRoom(formData);
  };

  return <UpdateRoomForm initialValues={room} disabled={isLoading} onSubmit={submitHandler} />;
};
