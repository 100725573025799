import * as ProgressPrimitive from '@radix-ui/react-progress';
import cn from 'classnames';

import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './Progress.module.scss';
import type { ProgressProps } from './Progress.props';

export const Progress = ({
  className,
  children,
  value = 0,
  max = 1,
  color,
  isLarge,
  ...props
}: ProgressProps) => {
  const percentage = ((value ?? 0) / max) * 100;

  const progressVars = scopedCssVars({
    progress: `${percentage}%`,
    indicatorColor: !!color && color
  });

  const classNames = cn(styles.Progress, className, progressVars, {
    [styles.isLarge]: isLarge
  });

  return (
    <ProgressPrimitive.Root className={classNames} max={max} value={value} {...props}>
      <ProgressPrimitive.Indicator className={styles.Indicator} />
    </ProgressPrimitive.Root>
  );
};
