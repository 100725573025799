import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';

import { AdvisorPage } from '@Components/layout';
import { Locale } from '@Constants/enums';
import { useAuthGetMe } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import {
  AdvisorAccountScreen,
  AdvisorAccountUpdateScreen,
  AdvisorCreateCustomerScreen,
  AdvisorCreateProjectScreen,
  AdvisorCustomerDetailScreen,
  AdvisorHomeScreen,
  AdvisorProductDetailScreen,
  AdvisorProductsScreen,
  AdvisorProjectCreateRoomScreen,
  AdvisorProjectDetailScreen,
  AdvisorProjectQuotationScreen,
  AdvisorProjectRoomAddMeasurementScreen,
  AdvisorProjectRoomAddProductScreen,
  AdvisorProjectRoomEditProductScreen,
  AdvisorProjectsScreen,
  AdvisorUpdateCustomerScreen,
  AdvisorUpdateProjectContactDetailScreen
} from '@Screens/Advisor';
import { CustomerProjectDetailScreen } from '@Screens/Customer';
import { acousticClient } from '@Services/Clients';
import { Redirect } from '@Utils/RouteUtils';

export const AdvisorStack = () => {
  const appRoutes = useAppRoutes();
  const { i18n } = useTranslation();
  const user = useAuthGetMe();

  useEffect(() => {
    if (typeof user.data === 'object') {
      const newLang = user.data?.language ? user.data.language : Locale.NL;

      acousticClient.setLocale(`${newLang}`);
      i18n.changeLanguage(`${newLang}`);
    }
  }, [user?.data, i18n]);

  return (
    <Routes>
      <Route path={appRoutes.getAdvisorHome().path} element={<AdvisorPage />}>
        <Route path={appRoutes.getAdvisorHome().path} element={<AdvisorHomeScreen />}>
          <Route path={appRoutes.getAdvisorCustomers().path}>
            <Route index element={<Redirect home="advisor" />} />

            <Route
              path={appRoutes.getAdvisorCustomersCreate().path}
              element={<AdvisorCreateCustomerScreen />}
            />
          </Route>
        </Route>

        <Route path={appRoutes.getAdvisorAccount().path} element={<AdvisorAccountScreen />}>
          <Route
            path={appRoutes.getAdvisorAccountUpdate().path}
            element={<AdvisorAccountUpdateScreen />}
          />
        </Route>

        <Route
          path={appRoutes.getAdvisorCustomerDetail().path}
          element={<AdvisorCustomerDetailScreen />}
        >
          <Route
            path={appRoutes.getAdvisorCustomerUpdate().path}
            element={<AdvisorUpdateCustomerScreen />}
          />

          <Route
            path={appRoutes.getAdvisorProjectCreate().path}
            element={<AdvisorCreateProjectScreen />}
          />
        </Route>

        <Route path={appRoutes.getAdvisorProjects().path}>
          <Route index element={<AdvisorProjectsScreen />} />

          <Route
            path={appRoutes.getAdvisorProjectDetail().path}
            element={<AdvisorProjectDetailScreen />}
          >
            <Route
              path={appRoutes.getAdvisorProjectRoomCreate().path}
              element={<AdvisorProjectCreateRoomScreen />}
            />

            <Route
              path={appRoutes.getAdvisorProjectRoomAddMeasurements().path}
              element={<AdvisorProjectRoomAddMeasurementScreen />}
            />

            <Route
              path={appRoutes.getAdvisorProjectRoomAddProduct().path}
              element={<AdvisorProjectRoomAddProductScreen />}
            />

            <Route
              path={appRoutes.getAdvisorProjectQuotation().path}
              element={<AdvisorProjectQuotationScreen />}
            />

            <Route
              path={appRoutes.getAdvisorProjectRoomEditProduct().path}
              element={<AdvisorProjectRoomEditProductScreen />}
            />

            <Route
              path={appRoutes.getAdvisorProjectUpdate().path}
              element={<AdvisorUpdateProjectContactDetailScreen />}
            />
          </Route>
        </Route>

        <Route
          path={appRoutes.getCustomerProjectDetail().path}
          element={<CustomerProjectDetailScreen />}
        />

        <Route path={appRoutes.getAdvisorProducts().path} element={<AdvisorProductsScreen />}>
          <Route
            path={appRoutes.getAdvisorProductDetail().path}
            element={<AdvisorProductDetailScreen />}
          />
        </Route>
      </Route>

      <Route path="*" element={<Redirect home="advisor" />} />
    </Routes>
  );
};
