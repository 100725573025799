import { ListChecked, Text } from '@Components/ui';

import type { USPListProps } from './USPList.props';

export const USPList = ({ usps }: USPListProps) => {
  return (
    <ListChecked>
      {usps.map((usp, idx) => {
        return <Text.Normal key={idx}>{usp.content}</Text.Normal>;
      })}
    </ListChecked>
  );
};
