import cn from 'classnames';

import styles from './Box.module.scss';
import type { BoxProps } from './Box.props';
import { getSpacing } from './internal/utils/BoxUtils';

export const Box = ({
  alignItems,
  className,
  children,
  flexDirection,
  flexWrap,
  justifyContent,
  m,
  mt,
  mb,
  ml,
  mr,
  mx,
  my,
  p,
  pt,
  pb,
  pl,
  pr,
  px,
  py,
  style,
  ...props
}: BoxProps) => {
  const classNames = cn(styles.Box, className, {
    [styles.isFlex]: !!alignItems || !!flexDirection || !!flexWrap || !!justifyContent
  });

  const styling = {
    alignItems,
    flexDirection,
    flexWrap,
    justifyContent,
    ...(m && getSpacing(m, ['margin'])),
    ...(mt && getSpacing(mt, ['marginTop'])),
    ...(mb && getSpacing(mb, ['marginBottom'])),
    ...(ml && getSpacing(ml, ['marginLeft'])),
    ...(mr && getSpacing(mr, ['marginRight'])),
    ...(mx && getSpacing(mx, ['marginLeft', 'marginRight'])),
    ...(my && getSpacing(my, ['marginTop', 'marginBottom'])),
    ...(p && getSpacing(p, ['padding'])),
    ...(pt && getSpacing(pt, ['paddingTop'])),
    ...(pb && getSpacing(pb, ['paddingBottom'])),
    ...(pl && getSpacing(pl, ['paddingLeft'])),
    ...(pr && getSpacing(pr, ['paddingRight'])),
    ...(px && getSpacing(px, ['paddingLeft', 'paddingRight'])),
    ...(py && getSpacing(py, ['paddingTop', 'paddingBottom'])),
    ...style
  };

  return (
    <div className={classNames} style={styling} {...props}>
      {children}
    </div>
  );
};
