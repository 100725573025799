import cn from 'classnames';

import styles from './CardImage.module.scss';
import type { CardImageProps } from './CardImage.props';

export const CardImage = ({
  ratio,
  src,
  alt = '',
  className,
  children,
  ...props
}: CardImageProps) => {
  const classNames = cn(styles.CardImage, className);

  const viewBox = `0 0 ${ratio} 1`;

  return (
    <div className={classNames}>
      {!!ratio && <svg viewBox={viewBox} className={styles.Ratio} />}

      <div className={styles.Spacing} />

      {!!src && <img alt={alt} src={src} {...props} className={styles.Image} />}

      <div className={styles.ImageContents}>
        <div>{children}</div>
      </div>
    </div>
  );
};
