import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Anchor, Button, Form, SpacingGroup, Text } from '@Components/ui';
import { useAuthLogin } from '@Hooks/Auth';
import { useAppRoutes } from '@Hooks/Common';
import type { AuthTokenResponse } from '@Services/AuthService';

import styles from './AuthLoginForm.module.scss';
import type { AuthLoginFormProps, AuthLoginFormValues } from './AuthLoginForm.props';

export const AuthLoginForm = ({ onSucces, className }: AuthLoginFormProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.LOGIN.CREDENTIALS.FORM'
  });
  const authLogin = useAuthLogin();
  const [error, setError] = useState('');
  const appRoutes = useAppRoutes();

  const handleSubmit = useCallback(
    async ({ email, password }: AuthLoginFormValues) => {
      try {
        const response = await authLogin.mutateAsync({ email, password });

        if (response === false) {
          toast.error(t('ERRORS.NON_ADVISOR'));
          return;
        }

        onSucces?.(response as AuthTokenResponse);
      } catch (e) {
        setError(t('LABELS.ERROR'));
      }
    },
    [authLogin, onSucces, t]
  );

  const loginValidationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required()
  });

  return (
    <Form onSubmit={handleSubmit} validationSchema={loginValidationSchema} className={className}>
      <Form.Input.Text
        name="email"
        label={t('LABELS.EMAIL')}
        inputProps={{ placeholder: t('LABELS.EMAIL') }}
      />

      <Form.Input.Text
        name="password"
        label={t('LABELS.PASSWORD')}
        inputProps={{ type: 'password' }}
      />

      {!!error && <Text.Error className="error">{error}</Text.Error>}

      <SpacingGroup spacing={2} className={styles.Buttons}>
        <Button isSubmit type="submit">
          {t('LABELS.SUBMIT')}
        </Button>

        <Anchor to={appRoutes.getForgotPassword().to} className={styles.Anchor}>
          {t('LABELS.FORGOT_PASSWORD')}
        </Anchor>
      </SpacingGroup>
    </Form>
  );
};
