import { acousticClient } from '@Services/Clients';
import { BaseService } from '@Services/Common';

import type { AccountUpdateRequest, AccountUpdateResponse } from './AdvisorService.props';

export class AdvisorService extends BaseService {
  update = async (params: AccountUpdateRequest) => {
    const { data } = await this.put<AccountUpdateResponse>(`/${params.id}`, params);

    return data;
  };
}

export const advisorService = new AdvisorService(acousticClient.getAxiosInstance(), '/users');
