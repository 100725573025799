import cn from 'classnames';

import { scopedCssVars } from '@Utils/ThemeUtils';

import styles from './Chip.module.scss';
import type { ChipProps } from './Chip.props';

export const Chip = ({ className, children, color = 'white', ...props }: ChipProps) => {
  const chipVars = scopedCssVars({
    chipColor: `var(--color-${color})`
  });

  const classNames = cn(chipVars, styles.Chip, className);

  return (
    <div className={classNames} {...props}>
      {children}
    </div>
  );
};
