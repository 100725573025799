import { useCallback } from 'react';

import { LazyList } from '@Components/layout';
import { CustomerProjectCardList } from '@Components/list';
import { useInfiniteData } from '@Hooks/Common';
import { useProjectGetForCurrentCustomer } from '@Hooks/Project';

export const CustomerProjectsArchive = () => {
  const { data, fetchNextPage, hasNextPage, ...query } = useProjectGetForCurrentCustomer();
  const projects = useInfiniteData(data);

  const handleFetchNextPage = useCallback(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  return (
    <LazyList
      {...query}
      hasNextPage={hasNextPage}
      fetchNextPage={handleFetchNextPage}
      totalItems={data?.pages[0].total}
    >
      {!!projects && <CustomerProjectCardList projects={projects} />}
    </LazyList>
  );
};
