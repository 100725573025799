import cn from 'classnames';

import { Link } from '@Components/ui';

import styles from './Menu.module.scss';
import type { MenuProps } from './Menu.props';

export const Menu = ({ className, isMobile, items, ...props }: MenuProps) => {
  const classNames = cn(styles.Menu, className, { [styles.isMobile]: isMobile });
  const linkClassNames = cn(styles.Link, { [styles.isMobile]: isMobile });

  return (
    <nav className={classNames} {...props}>
      {!!items &&
        items.map((item, idx) => {
          return (
            <Link
              key={item.to as string}
              to={item.to}
              className={linkClassNames}
              data-testid={`MenuLink-${idx}`}
            >
              {item.label}
            </Link>
          );
        })}
    </nav>
  );
};
