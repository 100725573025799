import { useTranslation } from 'react-i18next';

import { Modal } from '@Components/layout';
import { Button, SpacingGroup } from '@Components/ui';
import { DASHBOARD_LINK } from '@Constants/configs';

export const AuthRegisterCompleteScreen = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'DOMAIN.AUTHENTICATION.REGISTER.SUCCESS'
  });

  const handleClick = () => {
    if (DASHBOARD_LINK) window.location.replace(DASHBOARD_LINK);
    return null;
  };

  return (
    <Modal isClosable={false} title={t('TITLE')}>
      <SpacingGroup spacing={2}>
        <Button onClick={handleClick}>{t('BUTTON')}</Button>
      </SpacingGroup>
    </Modal>
  );
};
