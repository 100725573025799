import { useCallback, useRef } from 'react';

export const useDebounce = (gap: number) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const debounce = useCallback(
    (action: () => void) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => action(), gap);
    },
    [gap]
  );

  return { debounce };
};
