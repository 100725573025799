import { faInfo } from '@fortawesome/pro-solid-svg-icons';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import cn from 'classnames';

import { Icon } from '@Components/ui/Icon/Icon';

import styles from './Tooltip.module.scss';
import type { TooltipProps } from './Tooltip.props';

export const Tooltip = ({ className, children, description, ...props }: TooltipProps) => {
  const classNames = cn(styles.Tooltip, className);

  return (
    <RadixTooltip.Root {...props}>
      <RadixTooltip.Trigger>
        {children ?? (
          <Icon icon={faInfo} hasCircle backgroundColor="primary" color="white" size={8} />
        )}
      </RadixTooltip.Trigger>

      <RadixTooltip.Content className={classNames}>{description}</RadixTooltip.Content>
    </RadixTooltip.Root>
  );
};
